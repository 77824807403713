import React from 'react';

const LoadingBtn = () => {
  return (
    <div className="wrapper-loading wrapper-loading-btn">
      <div className="overlay d-flex justify-content-center align-items-center">
          {/* <Spinner color="primary" /> */}
        <div className="lds-spinner">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  )
};

export default LoadingBtn;
