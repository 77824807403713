import React, { useEffect, useState } from 'react';
import { useHistory, Link, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import Header from 'components/Header';
import { translate } from 'react-i18next';
import Footer from 'components/Footer';
import { getRole } from 'helpers/helpers';
import './style.scss';
import 'assets/scss/page/TableClientMaster/styles.scss'
import { ORDER, PRODUCT_ENDPOINT } from 'constants/endpoints';
import { CSVLink } from 'react-csv';
import { pushToast } from 'components/Toast';
import Encoding from 'encoding-japanese';
import ListDataCsv from './ListDataCsv/index'
import { ROLE } from 'helpers/constants';
import { useSelector } from 'react-redux';
import Loading from 'components/Loading/Loading';
import _ from 'lodash';

const ReadAndImportCsv = (props) => {
    const [role, setRole] = React.useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [listProduct, setListProduct] = useState(null);
    const [fileUTF8, setFileUTF8] = useState(null);
    const [idProduct, setIdProduct] = useState(null);
    const [defaultCsv, setDefaultCsv] = useState([]);
    const [selectedName, setSelectedName] = useState('ファイルを選択');
    const [idRole, setIdRole] = React.useState(null);
    const [showCsv, setShowCsv] = useState('');
    const [showCsvShift, setshowCsvShift] = useState([]);
    const [disableReadForm, setDisableReadFormReadForm] = useState(false)
    const [fontSize] = useState('small');
    const colorScreen = useSelector((store) => store.utils.colorMe);
    const [updateSucces,setUpdateSucces] = useState(false)
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    useEffect(() => {
      const newRole = localStorage.getItem('role');
      setIdRole(newRole);
      if (newRole) {
        setRole(getRole({ idRole: newRole }));
      }
    }, []);

    const getAllProductByUser = async () => {
      try {
        const data = await PRODUCT_ENDPOINT.GET_PRODUCT_CSV();
        if (data) {
          setListProduct(data?.data?.body);
        }
      } catch (e) {
        console.log(e);
      }
    };

    const getDefaultCsv = async ({ productId = '' }) => {
      try {
        setLoading(true);
        const data = await ORDER.GET_DEFAULT_CSV({ productId: productId });
        if (data?.data) {
          setDefaultCsv(data?.data);
          setLoading(false);
        }
      } catch (e) {
        console.log(e);
        setLoading(false);
      }
    };

    const uploadCsv = async (body) => {
      try {
        setLoading(true);
        const data = await ORDER.IMPORT_CSV(body);
        if (data && data.code !== 0) {
          setLoading(false);
          if (data && data.message && data.message.extra) {
            pushToast('error', data?.message?.extra);
          } else if (data.message.errorMessage) {
            pushToast('error', data?.message?.errorMessage);
          } else {
            pushToast('error', data?.message);
          }
        } else {
          pushToast('success', 'Upload CSV success');
          setUpdateSucces(true);
          setLoading(false);
          history.push(`/list?p=receive_info`);
        }
      } catch (errorMessage) {
        console.log('errorMessage: ', errorMessage);
        setLoading(false);
      }
    };

    useEffect(() => {
      if (idProduct) {
        getDefaultCsv({ productId: idProduct });
      }
    }, [idProduct]);

    const onChangeHandler = (event) => {
      setSelectedFile(event?.target?.files[0]);
      setSelectedName(event?.target?.files[0]?.name);

      const file = event.target.files[0];
      try {
        setDisableReadFormReadForm(false)
        let reader = new FileReader();
        reader.onload = function (e) {
          let codes = new Uint8Array(e.target.result);
          let encoding = Encoding.detect(codes);
          let unicodeString = Encoding.convert(codes, {
            to: 'UNICODE',
            from: ['SJIS', 'UTF8'].includes(encoding) ? encoding : 'SJIS',
            type: "string"
          });
          const arr_show = unicodeString.split('\n');
          _.remove(arr_show, obj => _.isEmpty(obj) || obj === '"');
          const str_code = Encoding.stringToCode(unicodeString);
          const sjis_array = Encoding.convert(str_code, 'UTF8', 'UNICODE');
          const unit8Array = new Uint8Array(sjis_array);
          const file_affer = new File([unit8Array], { type: "text/csv;charset=utf-8" });
          setShowCsv(arr_show.shift());
          setFileUTF8(file_affer);
          setshowCsvShift(arr_show);
          setUpdateSucces(false)
        };
        reader.readAsArrayBuffer(file);
      } catch (err) {
        if (err) pushToast('error', 'Can not read file');
      }
    };

    const onClickHandler = () => {
      const data = new FormData();
      data.append('file', fileUTF8);
      data.append('productId', idProduct);
      uploadCsv(data);
    };

    const onClickReader = () => {
      Array.from(document.querySelectorAll('input')).forEach((input) => (input.value = ''));
      setDisableReadFormReadForm(true)
    }

    const handleChangeProduct = (e) => {
      setIdProduct(e.target.value);
    };

    useEffect(() => {
      getAllProductByUser();

      const newRole = localStorage.getItem('role');
      if (newRole) {
        setRole(getRole({ idRole: newRole }));
      }

    }, []);
    return (
      <>
        <div className='wrapper-read-import-csv'>
          <Loading loading={loading} />
          <div className="main">
          {(!props.HideHeader && (role === ROLE.CENTER || role === ROLE.PARTNERGROUP)) && <Header idRole={idRole} color={colorScreen?.code} colorText={colorScreen?.colorText} p = 'receive_info' />}
            <div className="body-wrapper">
              <select className="ml-3 form-control client-select" onChange={handleChangeProduct} defaultValue={''}>
                <option disabled={true} value={''}>---案件を選択して下さい---</option>
                {
                  listProduct?.length > 0 && listProduct.map((item, index) =>
                    <option key={index} value={item.productId}>{item.name}</option>
                  )
                }
              </select>
              <div className='d-flex'>
                <div className='label__custom--pd10 col-9 csv-import-wrapper'>
                  <CSVLink data={defaultCsv} filename='default-csv.csv'>
                    <button className='btn button-read-down-csv fz-14' style={{ backgroundColor: `${colorScreen?.code}`, color: `${colorScreen?.colorText}` }}>CSVフォーマットダウンロード</button>
                  </CSVLink>
                </div>
              </div>
              <div className='d-flex'>
                <div className='col-9'>
                  <label className='label__custom--pd10 btn btn-light fz-14  button-read-down-csv' htmlFor='file' style={{ backgroundColor: `${colorScreen?.code}`, color: `${colorScreen?.colorText}` }}>ファイル取込</label>
                  <input type="file" name="file" id='file' className='d-none' onChange={onChangeHandler} />
                  <input className='ml-5' type="text" placeholder={selectedName} disabled="disabled" />
                  <button className='ml-5 btn fz-14 ' style={{ backgroundColor: `${colorScreen?.code}`, color: `${colorScreen?.colorText}`, width: 150, height: 33 }} onClick={() => onClickReader()}
                    disabled={!(selectedFile && idProduct)}>インポート
                  </button>
                </div>
              </div>
              {
                (role === ROLE.CENTER || role === ROLE.PARTNERGROUP) && (
                  <div className='d-flex'>
                    <div className='col-9'>
                      <label className='label__custom--pd10 btn btn-light fz-14  button-read-down-csv' style={{ backgroundColor: `${colorScreen?.code}`, color: `${colorScreen?.colorText}` }}>物件再作成</label>
                      <Link to={"/recreate"} className='ml-5 btn fz-14 ' style={{ backgroundColor: `${colorScreen?.code}`, color: `${colorScreen?.colorText}`, width: 150, height: 33 }}
                        disabled={!(selectedFile && idProduct)}>再作成ページへ
                      </Link>
                    </div>
                  </div>
                )
              }
              <button className='ml-5 btn fz-14 button-upload' style={{ backgroundColor: `${colorScreen?.code}`, color: `${colorScreen?.colorText}`, width: 150, height: 33 }} onClick={() => onClickHandler()}
                disabled={!(selectedFile && idProduct) || updateSucces}>物件作成
                    </button>
              <div style={{ display: disableReadForm ? 'block' : 'none' }}>
                <ListDataCsv showCsv={showCsv} showCsvShift={showCsvShift} fontSize={fontSize} />
              </div>
            </div>
            {/* <Footer color={colorScreen?.code} colorText={colorScreen?.colorText} /> */}
          </div>
        </div>
      </>
    );
  };

export default compose(translate('translations'), withRouter)(ReadAndImportCsv);
