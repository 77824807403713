import React, { useEffect, useState } from 'react';
import { pushToast } from 'components/Toast';
import { DISPATCH, ORDER_MANAGER_SHIFT_STAFF } from 'constants/endpoints';
import { time, SCREEN_DISPATCH_SETTING } from 'helpers/constants';
import { Collapse } from "reactstrap";
import Loading from "components/Loading/Loading";
import _ from "lodash";
import { getDataHistoryMasterSetting } from 'actions/authentication';
import { useDispatch } from 'react-redux';

const TableDetailStaffSkill = ({
  t, color, staffId,
  colorText, listStaffServices = [],
  handleCollap, hideCollap = "", it, reload }) => {
  const dispatch = useDispatch();
  const [listStaffServiceEx, setListStaffServiceEx] = useState(listStaffServices);
  const newListStaffServices = (listStaffServiceEx[it.productId] || []).map((elm) => {
    return { ...elm, status: true }
  })
  const [listDetail, setListDetail] = useState([]);
  const [listData, setListData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState({});
  const [listStaffService, setListStaffService] = useState(newListStaffServices);

  useEffect(() => {
    if(JSON.stringify(listStaffServices) !== JSON.stringify(listStaffServiceEx))
    setListStaffServiceEx(listStaffServices)
  },[listStaffServices])

  useEffect(() => {
    if (hideCollap === it.productId) {
      getListDetail();
      reload();
      getOptionDispatch({ productId: it.productId });
      if (_.isEmpty(listStaffService)) {
        setListStaffService((listStaffServices[it.productId] || []).map((elm) => {
          return { ...elm, status: true };
        }));
      };
    }
  }, [hideCollap]);


  useEffect(() => {
    if(hideCollap === it.productId) {
      if(listData.length > 0) {
        const newList = listData.map(item => {
          const newStaff = newListStaffServices.find(i => i.productServiceId === item.productServiceId);
          if (newStaff?.productServiceId === item.productServiceId) {
            return { ...item, timeStaff: newStaff?.timeStaff, status: newStaff?.status }
          } else {
            return { ...item, timeStaff: item.workTime, status: false }
          }
        });
        setListDetail(newList);
      }

    }

  },[listData])

  const getListDetail = async () => {
    try {
      // setLoading(true);
      const data = await DISPATCH.GET_SERVICE_BY_PRODUCT({ productId: it.productId, staffId: staffId });
      if (data) {
        setListData(data?.data?.productServices)

        // setLoading(false);
      }
    } catch (e) {
      console.log(e);
      // setLoading(false);
    }
  };

  const handleChangeText = (e, serviceId, productServiceId) => {
    let value = e.target.value;
    let name = e.target.name;
    const arrTmp = listDetail.map((item) => {
      if (item.productServiceId === productServiceId) {
        return { ...item, [name]: value };
      }
      return item;
    });
    setListDetail(arrTmp);
  };

  const handleChangeCheck = (e, productServiceId) => {
    const { checked } = e.target;
    const arrTmp = listDetail?.map((item) => {
      if (item.productServiceId === productServiceId) {
        return { ...item, status: checked };
      }
      return item;
    });
    setListDetail(arrTmp);
  };

  const renderItem = (item, index) => {
    return (
      <tr key={index}>
        <td>{index + 1}</td>
        <td>{item?.serviceName}</td>
        <td>{item?.option1}</td>
        <td>{item?.option2}</td>
        <td>{item?.option3}</td>
        <td>{item?.option4}</td>
        <td>{item?.option5}</td>
        <td>{item?.workTime}</td>
        <td>
          <select
            className="form-input"
            value={item?.timeStaff}
            name="timeStaff"
            onChange={(e) => handleChangeText(e, item?.serviceId, item?.productServiceId)}
          >
            <option value="">---</option>
            {
              time.map((items, index) => (
                <option value={items} key={index}>
                  {items}
                </option>
              ))
            }
          </select>
        </td>
        <td className="text-center">
          <input
            type="checkbox"
            className="align-middle"
            onChange={(e) => handleChangeCheck(e, item?.productServiceId)}
            checked={item?.status}
          />
        </td>
      </tr>
    );
  };

  const insertOrUpdate = async ({ staffId, body }) => {
    try {
      const data = await ORDER_MANAGER_SHIFT_STAFF.UPDATE_STAFF_SERVICE({ body, staffId });
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
      } else {
        pushToast('success', 'Update success');
        const newListStaff = data?.data?.Attributes?.staffServices;
        setListStaffService((newListStaff[it.productId] || []).map((elm) => {
          return { ...elm, status: true };
        }));
        dispatch(getDataHistoryMasterSetting({ logId : '', screenName: SCREEN_DISPATCH_SETTING.workerInformation.label }));
      }
    } catch (errorMessage) {
      console.log('errorMessage: ', errorMessage);
    }
  };

  const onClickSubmit = () => {
    const arrTmp = listDetail
      .filter((itemFilter) => itemFilter.status === true)
      .map((itemMap) => {
        return { serviceId: itemMap.serviceId, timeStaff: itemMap.timeStaff, productServiceId: itemMap.productServiceId };
      });
    const body = { productId: it.productId, screenName: SCREEN_DISPATCH_SETTING.workerInformation.label, staffServices: arrTmp, updateTitle: `保存 ワーカースキル設定 ${it.name}` };
    insertOrUpdate({ staffId: staffId, body });
  };

  const toggleHide = (id) => {
    handleCollap(id);
  };

  const getOptionDispatch = async ({ productId }) => {
    try {
      setLoading(true);
      const data = await DISPATCH.GET_OPTION_DISPATCH({ productId });
      if (data) {
        const dataHandle = _.mapValues(data?.data, (value, key) => {
          return value.name
        })
        setOptions(dataHandle);
        setLoading(false);
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  return (
    <>
      <Loading loading={loading} />
      <div className="wrapper-btn-colappse" onClick={() => toggleHide(it.productId)}>
        <div
          className="mb-1 searchbar d-flex justify-content-between align-items-center "
          style={{ background: `${color}` }}
        >
          <div className="ml-2" style={{ color: `${colorText}`, fontSize: '16px'}}>
            {it.name}
          </div>
          <div className="mr-2 d-flex" style={{ color: `${colorText}` }}>
            {hideCollap === 1 ? (
              <i className="icon-up-open icon-searchbar" />
            ) : (
              <i className="icon-down-open icon-searchbar" />
            )}
          </div>
        </div>
      </div>
      {
        (hideCollap === it.productId) &&
        <Collapse isOpen={hideCollap === it.productId}>
          <div className="table-list-wrapper table-responsive">
            <div></div>
            <table className="table table-striped">
              <thead>
                <tr style={{ backgroundColor: `${color}` }}>
                  <th scope="col" style={{ color: `${colorText}` }}>
                    No
                </th>
                  <th style={{ color: `${colorText}` }}>サービス名</th>
                  <th style={{ color: `${colorText}` }}>{options?.option1}</th>
                  <th style={{ color: `${colorText}` }}>{options?.option2}</th>
                  <th style={{ color: `${colorText}` }}>{options?.option3}</th>
                  <th style={{ color: `${colorText}` }}>{options?.option4}</th>
                  <th style={{ color: `${colorText}` }}>{options?.option5}</th>
                  <th style={{ color: `${colorText}` }}>所要時間</th>
                  <th style={{ color: `${colorText}` }}>個別設定時間</th>
                  <th style={{ color: `${colorText}` }}>表示</th>
                </tr>
              </thead>
              <tbody>
                {listDetail &&
                  listDetail?.map((listData, index) =>
                    renderItem(listData, index)
                  )}
              </tbody>
            </table>
            <div className="form-update ">
              <button
                className={`btn form-btn mr-2`}
                style={{ background: `${color}`, color: `${colorText}` }}
                onClick={onClickSubmit}
              >
                保存
            </button>
            </div>
          </div>
        </Collapse>

      }

    </>
  );
};

export default TableDetailStaffSkill;
