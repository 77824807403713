import React, { useEffect, useRef, useState } from 'react';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { pushToast } from 'components/Toast';
import { MASTER_SETTING, STAFF_ENDPOINT } from 'constants/endpoints';
import { NAME_MASTER_SETTING, AREAS, DEFAULT_ADDRESS } from 'helpers/constants';
import './style.scss';
import _ from 'lodash';
import { Collapse } from 'reactstrap';
import { SCREEN_DISPATCH_SETTING } from '../../../../helpers/constants';
import AreaSelectionNew from 'components/AreaSelectionNew';
import { AREAS_PERMITTED } from 'helpers/areas';
import { getDataHistoryMasterSetting } from 'actions/authentication';
import { useDispatch } from 'react-redux';

const TableDetailListStaff = ({ t, color, colorText, handleCollap, hideCollap = '', item, listArea }) => {
  const dispatch = useDispatch();
  const [idStaff, setIdStaff] = useState();
  const [dataDetailStaff, setDataDetailStaff] = useState({});
  const [address, setAddress] = useState('');
  const [lat, setLat] = useState('');
  const [prefectures, setPrefectures] = useState('');
  const [lng, setLng] = useState('');
  const Area = useRef('');
  const Longitude = useRef('');
  const Latitude = useRef('');
  const [distance, setDistance] = useState('');
  const [movingDistance, setMovingDistance] = useState('60');
  const [activeArea, setActiveArea] = useState({});
  const [listAddress, setListAddress] = useState([]);
  const [render, setRender] = useState('');
  const [distanceRange, setDistanceRange] = useState('10');
  const [prefectureAreas, setPrefectureAreas] = useState({});
  const prefectureNameKanji = _.groupBy(AREAS_PERMITTED, 'prefectureNameKanji');

  useEffect(() => {
    if (idStaff) {
      getDetailStaff({
        idStaff: item?.staffId,
      });
    }
  }, [idStaff]);

  const getDetailStaff = async ({ idStaff = '' }) => {
    try {
      const data = await STAFF_ENDPOINT.GET_DETAIL_STAFF_INFO({ idStaff: idStaff });
      if (data) {
        setLat(data?.latitude);
        setLng(data?.longitude);
        if (data?.address) {
          setAddress(data?.address);
        }
        setActiveArea(data?.activeArea || {});
        const dataHandler = _.flatten(Object.values(data?.activeArea || {})).map(e => {
          return !_.isEmpty(data?.prefectureAreas[e]) ? {[e]: data?.prefectureAreas[e]} : {[e]: _.compact(_.map(prefectureNameKanji[e] || [], 'cityNameKanji'))}
        });
        const newPrefectureAreas = _.isEmpty(dataHandler) ? {} : Object.assign(...dataHandler);
        setPrefectureAreas(newPrefectureAreas || {});
        setDistance(data?.movableDistance?.distance);
        setMovingDistance(data?.movableDistance?.movingDistance);
        setPrefectures(data?.prefectures);
        setDataDetailStaff(data);
        setDistanceRange(data?.distanceRange);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (idStaff) {
      getListAddress({ name: NAME_MASTER_SETTING.ADDRESS_JAPAN });
    }
  }, [idStaff]);

  const getListAddress = async ({ name = '' }) => {
    try {
      const data = await MASTER_SETTING.GET_LIST_ADDRESS({ name: name });
      if (data) {
        setListAddress(data?.data?.values);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleChange = (address) => {
    setAddress(address);
  };

  const handleSelect = async (address) => {
    setAddress(address);
    await geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        setLat(latLng['lat']);
        setLng(latLng['lng']);
      })
      .catch((error) => console.error('Error', error));
  };

  const onSubmit = (e) => {
    e.preventDefault();
    let formData = {};
    formData = {
      prefectures: prefectures,
      address: address,
      longitude: Longitude.current.value,
      latitude: Latitude.current.value,
      movableDistance: {
        distance: distance?.toString(),
        movingDistance: (movingDistance || '60')?.toString(),
      },
      prefectureAreas,
      activeArea: activeArea,
      distanceRange: distanceRange || '10',
      screenName: SCREEN_DISPATCH_SETTING.siteInformation.label,
      updateTitle: `保存 ワーカーマスタ設定 ${item?.surName || ''} ${item?.name || ''}`
    };
    updateAddressStaff({ body: formData, id: idStaff });
  };

  const updateAddressStaff = async ({ body, id = '' }) => {
    try {
      const data = await STAFF_ENDPOINT.UPDATE_ADDRESS_STAFF({ body: body, id: id });
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
      } else {
        dispatch(getDataHistoryMasterSetting({ logId : '', screenName: SCREEN_DISPATCH_SETTING.siteInformation.label }));
        pushToast('success', 'Update order success');
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleChangeArea = (e) => {
    let value = e.target.value;
    setPrefectures(value);
  };

  const handleDistance = (e) => {
    let value = e.target.value;
    setDistance(value);
  };

  const handleDistanceRange = (e) => {
    let value = e.target.value;
    setDistanceRange(value);
  };

  const handleMovingDistance = (e) => {
    let value = e.target.value;
    setMovingDistance(value);
  };

  const handleActiveArea = (e, title) => {
    let target = e.target;
    let value = target.value;
    let arrTmp = activeArea[`${title}`] || [];
    if (target.checked) {
      arrTmp.push(value);
    } else {
      arrTmp.splice(arrTmp.indexOf(value), 1);
    }
    activeArea[`${title}`] = arrTmp;
    if (activeArea[`${title}`].length === 0) {
      delete activeArea[`${title}`];
    }
    setActiveArea(activeArea);
    setRender(target.checked + value + title);
  };

  const toggleHide = (id) => {
    handleCollap(id);
    setIdStaff(id);
  };

  const onChangePrefectureAreas = (data) => {
    setPrefectureAreas(data);
  };

  const onChangeAreas = (activeArea) => {
    const newData = activeArea;
    setActiveArea(newData);
    const values = _.flatten(Object.values(newData || {}));
    const dataHandler = values.map(e => {
      return Object.keys(prefectureAreas || {}).includes(e) ? {[e]: prefectureAreas[e]} : {[e]: _.compact(_.map(prefectureNameKanji[e] || [], 'cityNameKanji'))}
    })
    const newPrefectureAreas = _.isEmpty(dataHandler) ? {} : Object.assign(...dataHandler);
    setPrefectureAreas(newPrefectureAreas);
  };

  return (
    <div>
      <div className="wrapper-btn-colappse" onClick={() => toggleHide(item?.staffId)}>
        <div
          className="mb-1 searchbar d-flex justify-content-between align-items-center "
          style={{ background: `${color}` }}
        >
          <div className="ml-2" style={{ color: `${colorText}`, fontSize: '16px'}}>
            {item?.surName} {item?.name}
          </div>
          <div className="mr-2 d-flex" style={{ color: `${colorText}` }}>
            {hideCollap === item?.staffId ? (
              <i className="icon-up-open icon-searchbar" />
            ) : (
              <i className="icon-down-open icon-searchbar" />
            )}
          </div>
        </div>
      </div>
      <Collapse isOpen={hideCollap === item?.staffId}>
        <div className="table-list-wrapper table-responsive">
          <div className="block-staff-two">
            <div className="block-content-main">
              <table className="table table-map">
                <tbody>
                  <tr>
                    <td className="text-center p-3">作業員名</td>
                    <td className="align-middle pl-3 pr-3">
                      {dataDetailStaff.surName} {dataDetailStaff.name}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-center p-3">
                      作業員
                      <br />
                      登録情報
                    </td>
                    <td className="align-middle pl-3 pr-3">
                      TEL : {dataDetailStaff?.contact} <br />
                      Worker ID: {dataDetailStaff.loginId}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-center p-3">起点住所</td>
                    <td className="align-middle pl-3 pr-3">
                      <div className="form-group">
                        <label>都道府県</label>
                        <select
                          name="prefecture"
                          id="pref"
                          value={prefectures}
                          ref={Area}
                          onChange={handleChangeArea}
                          className="form-control w-50"
                        >
                          <option value="">---</option>
                          {DEFAULT_ADDRESS.map((item, index) => {
                            return (
                              <option value={item} key={index}>
                                {item}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      <div className="form-group">
                        <PlacesAutocomplete value={address} onChange={handleChange} onSelect={handleSelect}>
                          {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                            <div className="form-group">
                              <label>住所</label>
                              <input
                                {...getInputProps({
                                  placeholder: '',
                                  className: 'location-search-input form-control input-form',
                                })}
                              />
                              <div className="autocomplete-dropdown-container">
                                {loading && <div>Loading...</div>}
                                {suggestions.map((suggestion, index) => {
                                  const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';
                                  const style = suggestion.active
                                    ? {
                                        fontSize: '16px',
                                        backgroundColor: '#fafafa',
                                        cursor: 'pointer',
                                      }
                                    : {
                                        fontSize: '15px',
                                        backgroundColor: '#ffffff',
                                        cursor: 'pointer',
                                      };
                                  return (
                                    <div
                                      {...getSuggestionItemProps(suggestion, {
                                        className,
                                        style,
                                      })}
                                      key={index}
                                    >
                                      <span>{suggestion.description}</span>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          )}
                        </PlacesAutocomplete>
                      </div>
                      <div className="form-group">
                        <label>緯度</label>
                        <input ref={Latitude} type="text" value={lat} className="form-control" disabled />
                      </div>
                      <div className="form-group mb-1">
                        <label>経度</label>
                        <input ref={Longitude} type="text" value={lng} className="form-control" disabled />
                      </div>
                    </td>
                  </tr>
                  <tr style={{ backgroundColor: '#e7e7e7' }}>
                    <td className="text-center p-3">移動距離速度</td>
                    <td className="align-middle pl-3 pr-3 d-flex align-items-center">
                      {/* <select
                        className="form-control "
                        value={movingDistance}
                        onChange={handleMovingDistance}
                        style={{ width: 100 }}
                      >
                        <option value="60">1時間</option>
                      </select> */}
                      <div className="btn-1km">1時間</div>
                      <input type="text" className="form-control w-50" value={distance} onChange={handleDistance} />
                      <p className="font-weight-boid mb-0 ml-1">Km</p>
                    </td>
                  </tr>
                  <tr style={{ backgroundColor: '#e7e7e7' }}>
                    <td className="text-center p-3">移動距離範囲</td>
                    <td className="align-middle pl-3 pr-3 d-flex align-items-center">
                      <input
                        type="number"
                        className="form-control w-50"
                        value={distanceRange}
                        onChange={handleDistanceRange}
                      />
                      <p className="font-weight-boid mb-0 ml-1">Km</p>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-center p-3">対応エリア</td>
                    <td className="align-middle pl-3 pr-3">
                      <AreaSelectionNew
                        activeArea={activeArea}
                        prefectureAreas={prefectureAreas}
                        onChangePrefectureAreas={onChangePrefectureAreas}
                        onChange={(activeArea) => { onChangeAreas(activeArea) }}
                        listArea={listArea}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <button className="btn-submit" onClick={onSubmit}>
              保存
            </button>
            <div className="clear-block"></div>
          </div>
        </div>
      </Collapse>
    </div>
  );
};

export default TableDetailListStaff;
