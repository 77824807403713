import React, { useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import PaginationCustom from '../../../../components/PaginationCustom';
import { ROLE, DATETIME_JP_FORMAT } from '../../../../helpers/constants';
import './style.scss';
import ModalAddListGoodsMaster from './ModalAddListGoodsMaster';
import { Link } from 'react-router-dom';
import { IMPORT_GOODS_ENDPOINT, BRANCH_MASTER_ENDPOINT } from '../../../../constants/endpoints';
import UpdateGoodMaster from './ModalUpdateGoodMaster';
import { PRODUCT_ENDPOINT } from '../../../../constants/endpoints';
import Encoding from 'encoding-japanese';
import { pushToast } from '../../../../components/Toast';
import moment from 'moment';

const TableListGoodsMaster = ({ role = ROLE.PARTNER, color, roleDetail, t, p, idRole }) => {
  const [listImportGoods,setListImportGoods] = useState()
  const [activePage, setActivePage] = useState(1);
  const [modalAdd, setModalAdd] = useState(false);
  const [modalAdds, setModalAdds] = useState(false);
  const [onButton, setOnButton] = useState(true);
  const [idImportGoods,setIdImportGoods] = useState()
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedName, setSelectedName] = useState('ファイルを選択');
  const [fileUTF8, setFileUTF8] = useState(null);

  const getListStaff = async ({ page = 1, limit = 10}) => {
    try {
      const data = await IMPORT_GOODS_ENDPOINT.GET_LIST_IMPORT_GOODS_INFO({
        page: page,
        limit: limit,

      });
      if (data) {
        setListImportGoods(data?.data);
      }
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    getListStaff({ page: activePage });
  }, [activePage]);

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const renderItem = (item, index) => {
    return (
      <tr key={index}>
        <td>{index + 1}</td>
        <td>{item?.productName}</td>
        <td>{item?.managementCode}</td>
        <td>{item?.producer}</td>
        <td>{moment(item ?.orderDate).format(DATETIME_JP_FORMAT)}</td>
        <td>{moment(item?.expectedDate).format(DATETIME_JP_FORMAT)}</td>
        <td>{moment(item?.receiptDate).format(DATETIME_JP_FORMAT)}</td>
        <td>{item?.quantity}</td>
        <td>{item?.status}</td>
        <td>
          <button onClick={() => openModals(item?.importGoodsInfoId)}>編集</button>
        </td>
      </tr>
    );
  };

  const toggleAdd = () => {
    setModalAdd(!modalAdd);
  };
  const openModal = () => {
    setModalAdd(!modalAdd);
  };

  const toggleAdds = () => {
    setModalAdds(!modalAdds);
  };
  const openModals = (id) => {
    setIdImportGoods(id)
    setModalAdds(!modalAdds);
  };

  const onChangeHandler = (event) => {
    setSelectedFile(event?.target?.files[0]);
    setSelectedName(event?.target?.files[0]?.name);

    const file = event.target.files[0];

    let reader = new FileReader();
    reader.onload = function (e) {
      let codes = new Uint8Array(e.target.result);
      let encoding = Encoding.detect(codes);
      let unicodeString = Encoding.convert(codes, {
        to: 'UNICODE',
        from: ['SJIS', 'UTF8'].includes(encoding) ? encoding : 'SJIS',
        type: 'string',
      });
      const str_code = Encoding.stringToCode(unicodeString);
      const sjis_array = Encoding.convert(str_code, 'UTF8', 'UNICODE');
      const unit8Array = new Uint8Array(sjis_array);
      const file_affer = new File([unit8Array], { type: 'text/csv;charset=utf-8' });
      setFileUTF8(file_affer);
    };
    reader.readAsArrayBuffer(file);
  };

  const uploadCsv = async (body) => {
    try {
      const data = await IMPORT_GOODS_ENDPOINT.IMPORT_CSV(body);
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else if (data.message.errorMessage) {
          pushToast('error', data?.message?.errorMessage);
        } else {
          pushToast('error', data?.message);
        }
      } else {
        pushToast('success', 'Upload CSV success');
        window.location.href = '/equipment-management';
      }
    } catch (errorMessage) {
      console.log('errorMessage: ', errorMessage);
    }
  };

  const onClickHandler = () => {
    const data = new FormData();
    data.append('file', fileUTF8);
    uploadCsv(data);
  };

  const loadListStaff = () => {
    getListStaff({ page: activePage });
  }

  return (
    <div className="table-menu-wrapper-goods-master">
      <div className="block-create-goods-master">
        {/* <div className="block-top">検索</div> */}
        <button className="mr-3" onClick={openModal}>
          個別入荷
        </button>
        {onButton === true && <button onClick={() => setOnButton(!onButton)}>CSV入荷</button>}
        {onButton === false && (
          <div className="csv-table-individual-wrapper-custom">
            <div className="body-wrapper-import p-3">

              <p className="text-center mb-1 fz-10">一括登録</p>
              <div className="d-flex">
                <div className="col-3 csv-label-wrapper d-flex align-items-center justify-content-center">
                  <p className="mb-0">CSVインポート</p>
                </div>
                <div className="col-9 csv-import-wrapper">
                  <label className="btn btn-light fz-14 mb-0" htmlFor="file">
                    ファイルを選択
                  </label>
                  <input type="file" name="file" id="file" className="d-none" onChange={onChangeHandler} />
                  <input className="ml-2 fz-14" type="text" placeholder={selectedName} disabled="disabled" />
                  <button
                    className="ml-2 btn btn-light fz-14"
                    onClick={() => onClickHandler()}
                  >
                    インポート
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        {
          modalAdd &&
          <ModalAddListGoodsMaster modal={modalAdd} toggle={toggleAdd} loadListStaff={loadListStaff} />
        }
        {
          modalAdds &&
          <UpdateGoodMaster modal={modalAdds} idImportGoods={idImportGoods} toggle={toggleAdds} loadListStaff={loadListStaff}/>
        }
      </div>
      <div className="table-list-goods-master">
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>No</th>
              <th>品名</th>
              <th>管理コード</th>
              <th>発注元</th>
              <th>発注日</th>
              <th>入庫予定日</th>
              <th>入庫日</th>
              <th>数量</th>
              <th>ステータス</th>
              <th>編集</th>
            </tr>
          </thead>
          <tbody>{listImportGoods&&listImportGoods?.body.map((listData, index) => renderItem(listData, index, role))}</tbody>
        </table>
      </div>
      {
        listImportGoods ?.count > 10 ?
        <PaginationCustom
          activePage={activePage}
          itemsCountPerPage={10}
          totalItemsCount={listImportGoods?.count}
          pageRangeDisplayed={5}
          handlePageChange={handlePageChange}
        /> : ''
      }

    </div>
  );
};

export default TableListGoodsMaster;
