import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import LoginIcon from '../Login/loginIcon';
import React from 'react';

const VerifyComplete = () => {
  return (
    <div className="loginContainer">
      <img src="/img/login.png" alt="Description of image" className="img-fluid mt-3" />
      <div className={`row justify-content-center loginFormContainer`}>
        <div className="col-lg-12 p-0">
          <div className="inputField">
            <div className="loginLogo">
              <LoginIcon width={220} height={28} />
            </div>
            <span style={{fontSize: 16}}>パスワードが設定されました</span>
            <span style={{fontSize: 16}}>
              こちらから<a href="/login">ログイン</a>してください
            </span>
          </div>
        </div>
      </div>
      <div className="row justify-content-center absolute bottom-0">
        <div className="col col-md-6 col-lg-12 flex justify-center items-center">
          <span className="copyRightTextSymbol">©︎</span>
          <span className={`ml-2 copyRightText login_copyRightText`}>NEOMARS CO.,LTD.All Rights Reserved.</span>
        </div>
      </div>
    </div>
  );
};

export default compose(translate('translations'), withRouter, connect(null))(VerifyComplete);
