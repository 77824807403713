import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { compose } from 'redux';
import { translate } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Loading from '../../../components/Loading/Loading';
import Toast from 'components/Toast';
import { pushToast } from '../../../components/Toast';
import { USER_ENDPOINT } from 'constants/endpoints';
import _ from 'lodash';
import 'assets/scss/page/Login/style.scss';
import { MASTER_SETTING } from '../../../constants/endpoints';
import LoadingBtn from '../Login/LoadingBtn';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { IconButton, InputAdornment, OutlinedInput } from '@material-ui/core';
import LoginIcon from '../Login/loginIcon';

const ForgotPassword = (props) => {
  const { history } = props;
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordBlur, setPasswordBlur] = useState(false);
  const [confirmPasswordBlur, setConfirmPasswordBlur] = useState(false);
  const [isSecurityPassed, setIsSecurityPassed] = useState(true);
  const [isPwMatch, setIsPwMatch] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const email = _.compact(
    _.compact(window.location.search.split(/&|\?/))?.map((e) => {
      if (e?.split(/=/)?.[0] === 'email') return e?.split(/=/)?.[1];
    })
  )?.[0];
  const loginId = (process.browser && new URL(window.location.href).searchParams.get('loginId')) || '';
  const code = process.browser && new URL(window.location.href).searchParams.get('code');

  const [htmlString, setHtmlString] = useState('');

  useEffect(() => {
    setIsSecurityPassed(true);
    if (confirmPasswordBlur && passwordBlur) {
      setIsPwMatch(password === confirmPassword);
    }
  }, [password, confirmPassword, confirmPasswordBlur, passwordBlur]);

  const handleSubmit = () => {
    handleLogin({ password, confirmPassword });
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPressForgot, false);
    window.myfunc = handleSubmit;
    return () => {
      document.removeEventListener('keydown', handleKeyPressForgot, false);
      window.myfunc = null;
      document.getElementById('username').value = loginId || email;
      document.getElementById('username').disabled = true;
    };
  }, [htmlString]);

  const handleLogin = ({ password, confirmPassword }) => {
    const emailModify = email ? handleEmail(email) : '';
    if (password && confirmPassword) {
      setconfirmPassword({
        body: { loginId, email: emailModify, newPassword: password, confirmPassword, confirmationCode: code },
      });
    }
  };

  const handleKeyPressForgot = (e) => {
    if (e.key === 'Enter') {
      handleLogin({ password, confirmPassword });
      e.preventDefault();
    }
  };

  const getUiLogin = async () => {
    setLoading(true);
    try {
      const data = await MASTER_SETTING.GET_UI_LOGIN();
      if (data) {
        setHtmlString(data?.data?.loginHtml);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  useEffect(() => {
    getUiLogin();
    // getConfirmCode({ email: emailModify, codeConfirm: code });
  }, []);

  const handleEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase()) ? email : `${email}@dummyemail.com`;
  };

  const setconfirmPassword = async ({ body }) => {
    try {
      setLoading(true);
      const data = await USER_ENDPOINT.USER_FORGOT_PASSWORD({ body });
      if (data?.data) {
        pushToast('success', 'Set Password Success');
        setLoading(false);
        document.cookie = `STELLA.ACCESS_TOKEN=`;
        document.cookie = `STELLA.REFRESH_TOKEN=`;
        localStorage.removeItem('IS_LOGIN');
        history.push(`/confirm-complete`);
      } else {
        setIsSecurityPassed(false);
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      setIsSecurityPassed(false);
      console.log(e);
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (confirmPassword && password) {
      handleLogin({ password, confirmPassword });
    }
  };

  return (
    <div>
      <Loading loading={loading} />
      <Toast />
      {!_.isEmpty(htmlString) ? (
        <div dangerouslySetInnerHTML={{ __html: htmlString }} />
      ) : (
        <div className="loginContainer">
          <img src="/img/login.png" alt="Description of image" className="img-fluid mt-3" />
          <div className={`row justify-content-center loginFormContainer`}>
            <form className="w-full" onSubmit={onSubmit}>
              <div className="col-lg-12 p-0">
                <div className="inputField">
                  <div className="loginLogo">
                    <LoginIcon width={220} height={28} />
                  </div>
                  <p className="text-center" style={{ fontSize: 16 }}>
                    パスワードを設定してください
                  </p>
                  <div>
                    <label className="mb-2" htmlFor="email">
                      利用者ID
                    </label>
                    <TextField
                      variant="outlined"
                      id="username"
                      placeholder="ユーザーIDを入力"
                      className="w-full input-username"
                      value={loginId}
                      disabled
                    />
                  </div>
                  <div className="relative">
                    <label className="mb-2" htmlFor="password">
                      設定用パスワード
                    </label>
                    <OutlinedInput
                      id="outlined-adornment-password"
                      error={!isSecurityPassed}
                      type={showPassword ? 'text' : 'password'}
                      placeholder="パスワードを入力"
                      className="w-full input-password"
                      onChange={(e) => setPassword(e.target.value)}
                      onBlur={() => setPasswordBlur(true)}
                      endAdornment={
                        <InputAdornment position="start">
                          <IconButton
                            onClick={() => setShowPassword((cur) => !cur)}
                            onMouseDown={(e) => e.preventDefault()}
                            edge="start"
                            className="show-hide-pass"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    {!isSecurityPassed && <span className="errorMessage">パスワード設定が条件を満たしていません</span>}
                  </div>
                  <div className="relative">
                    <label className="mb-2" htmlFor="password">
                      設定用パスワード(確認)
                    </label>
                    <OutlinedInput
                      id="outlined-adornment-password"
                      error={!isPwMatch || !isSecurityPassed}
                      type={showConfirmPassword ? 'text' : 'password'}
                      placeholder="パスワードを入力"
                      className="w-full input-password"
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      onBlur={() => setConfirmPasswordBlur(true)}
                      endAdornment={
                        <InputAdornment position="start">
                          <IconButton
                            onClick={() => setShowConfirmPassword((cur) => !cur)}
                            onMouseDown={(e) => e.preventDefault()}
                            edge="start"
                            className="show-hide-pass"
                          >
                            {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    {!isPwMatch && (
                      <span className="errorMessage">
                        パスワードが正しくありません
                        <br />
                      </span>
                    )}
                    {!isSecurityPassed && <span className="errorMessage">パスワード設定が条件を満たしていません</span>}
                  </div>
                  {loading && (
                    <div className="flex justify-center">
                      <LoadingBtn />
                    </div>
                  )}
                  {!loading ? (
                    <button
                      type="submit"
                      className={` loginSubmitBtn justify-center`}
                      disabled={!password || !isPwMatch}
                    >
                      設定
                    </button>
                  ) : null}
                </div>
              </div>
            </form>
          </div>
          <div className="row justify-content-center absolute bottom-0">
            <div className="col col-md-6 col-lg-12 flex justify-center items-center">
              <span className="copyRightTextSymbol">©︎</span>
              <span className={`ml-2 copyRightText login_copyRightText`}>NEOMARS CO.,LTD.All Rights Reserved.</span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default compose(translate('translations'), withRouter, connect(null))(ForgotPassword);
