import React, { useState, useEffect } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { translate } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Collapse } from 'reactstrap';
import { compose } from 'redux';
import '../../../../assets/scss/page/CsvMaster/index.scss';
import PaginationCustom from '../../../../components/PaginationCustom';
import { CONTRACT_ENDPOINTS } from 'constants/endpoints';
import Loading from 'components/Loading/Loading';
import { CREATE_RECRUIMENT_CONTRACT } from 'helpers/constants';
import _ from 'lodash';
import { getRole } from '../../../../helpers/helpers';
import { ROLE } from '../../../../helpers/constants';

const CollapItem = ({ colors, item, handleHide, hideshow, colorText, roleId = '', getListContract, activePages }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [dataContracts, setDataContracts] = useState(item);
  const [loading, setLoading] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const checkRole = getRole({ idRole: localStorage.getItem('role') });
  const idProvider = localStorage.getItem('idProvide');

  const toggle = () => {
    setIsOpen(!isOpen);
    handleHide(dataContracts.productId);
  };

  useEffect(() => {
    setDataContracts(item);
  }, [item]);

  useEffect(() => {
    setActivePage(1)
  },[activePages])

  const handleChangePageContract = (pageNumber) => {
    setActivePage(pageNumber);
    getListContract({ page: activePages, pageContract: pageNumber, sortProductId: dataContracts.productId });
  };

  const stepContract = (number) => {
    switch (number) {
      case 1:
        return 'nda';
      case 2:
        return 'estimate';
      case 3:
        return 'contract_status';
      default:
        break;
    }
  };

  const handleMessage = ({ contractEstimate }) => {
    let message = [];
    ['contractStatus', 'estimate', 'nda'].map(e => {
      if (contractEstimate?.[e]) {
        if (contractEstimate?.[e]?.addMessagePartner && !message.includes(contractEstimate?.[e]?.addMessagePartner)) {
          message.push(contractEstimate?.[e]?.addMessagePartner);
        }

        if (contractEstimate?.[e]?.addMessageERP && !message.includes(contractEstimate?.[e]?.addMessageERP)) {
          message.push(contractEstimate?.[e]?.addMessageERP);
        }
      }
    });
    return checkMessage({ message: message })
  };

  const checkMessage = ({ message }) => {
    let text = ''
    switch(message.length) {
      case 2: {
        text = '返却中・確認待ち';
        break;
      }

      case 1: {
        text = message[0] === '（返却中）' ? '返却中' : '確認待ち';
        break;
      }

      default:
        text = '';
    }

    return text;
  }

  return (
    <div className="setting-master-menu-wrapper">
      <Loading loading={loading} />
      <div className="wrapper-btn-colappse">
        <div
          onClick={toggle}
          className="mb-1 searchbar d-flex justify-content-between align-items-center"
          style={{ background: `${colors}` }}
        >
          <div className="ml-2" style={{ color: `${colorText}` , fontSize: '16px' }}>
            <span>
              <Link
                to={
                  `${!_.isEmpty(dataContracts?.recruitmentId)
                    ? `/contract/recruitment?productId=${dataContracts?.productId}&id=${dataContracts?.recruitmentId}&organizationId=${dataContracts?.organizationId}`
                    : `/contract/recruitment?productId=${dataContracts?.productId}&organizationId=${dataContracts?.organizationId}`
                  }`
                }
              >
                {`${
                  !_.isEmpty(dataContracts.projectName)
                    ? `${dataContracts?.clientName}-${dataContracts.productName} - ${dataContracts.projectName} `
                    : `${dataContracts?.clientName}-${dataContracts.productName} `
                }`}
              </Link>
            </span>
          </div>
          <div className="d-flex mr-2">
            <div className="mr-2">
              {checkRole !== ROLE.PARTNER && (
                <Link
                  to={
                    `${!_.isEmpty(dataContracts?.recruitmentId)
                      ? `/contract/contract-record?id=${dataContracts?.recruitmentId}&productId=${dataContracts?.productId}&nameTitle=${`${dataContracts.productName||''}-${dataContracts.projectName||''}&organizationId=${dataContracts?.organizationId}`}`
                      : `/contract/contract-record?productId=${dataContracts?.productId}&nameTitle=${`${dataContracts.productName||''}-${dataContracts.projectName||''}&organizationId=${dataContracts?.organizationId}`}`
                      }`
                  }
                  className="btn-export-csv btn-color-csv"
                >
                  契約レコード追加
                </Link>
              )}
            </div>
            <div className="mr-2" style={{ color: `${colorText}` }}>
              {isOpen ? <i className="icon-up-open icon-searchbar" /> : <i className="icon-down-open icon-searchbar" />}
            </div>
          </div>
        </div>
      </div>
      <div>
        <Collapse isOpen={hideshow === dataContracts?.productId}>
          <div className="table-list-contract">
            <div className="title-table">
              <span>エリア：{dataContracts?.scope} 資格（条件含む）：{dataContracts?.businessContent} {dataContracts?.money}</span>
            </div>
            <table className="table table-bordered">
              <tbody>
                <tr>
                  <td>応募会社</td>
                  <td>協力会社</td>
                  <td>有資格作業員</td>
                  <td>見積金額</td>
                  <td>契約状態</td>
                  <td className="w-15">操作</td>
                </tr>
                {dataContracts?.contractEstimates?.data?.map((itemIn, index) => {
                  const message = handleMessage({ contractEstimate: itemIn });
                  return (
                    <tr key={index}>
                      <td>{itemIn?.abbreviationName}</td>
                      <td>{itemIn?.totalPartnerByOrganization}</td>
                      <td>{itemIn?.staffsByOrganization}</td>
                      <td>{itemIn?.estimate?.estimate}</td>
                      <td>{itemIn?.contractStatus?.state}</td>
                      <td className="td-check-table">
                        <Link
                          to={`/contract/registration?recruitmentContractId=${itemIn.recruitmentId}&recruitmentEstimateId=${itemIn.contractEstimateId}&organizationId=${itemIn.organizationId}&onback=`}
                          className="btn-edit"
                        >
                          編集
                        </Link>
                        {
                          (message === '確認待ち') ? ( <button className="btn-edit-blue btn-edit">{message}</button>) : (message && message !== '確認待ち') ? (
                            <button className="btn-edit-red btn-edit btn-edit-width">{message}</button>
                          ) : ('')
                        }
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {!_.isEmpty(dataContracts?.contractEstimates?.data) && dataContracts?.contractEstimates?.count > 10 && (
              <PaginationCustom
                activePage={activePage}
                itemsCountPerPage={10}
                totalItemsCount={dataContracts?.contractEstimates?.count}
                pageRangeDisplayed={5}
                handlePageChange={handleChangePageContract}
              />
            )}
          </div>
        </Collapse>
      </div>
    </div>
  );
};

export default compose(translate('translations'))(CollapItem);
