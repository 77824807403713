import React, { useState, useEffect } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { translate } from 'react-i18next';
import { Collapse } from 'reactstrap';
import { compose } from 'redux';
// import '../../../../assets/scss/page/CsvMaster/index.scss';
import _ from 'lodash';
import PaginationCustom from '../../../../../components/PaginationCustom';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { DATE_JP_FORMAT } from 'helpers/constants';

const CollapItem = ({
  colors,
  item,
  key,
  handleHide,
  hideshow,
  colorText,
  roleId = '',
  getListContract,
  activePage,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [dataContracts, setDataContracts] = useState(item);
  const [activePageContract, setActivePageContract] = useState(1);

  const toggle = () => {
    setIsOpen(!isOpen);
    handleHide(dataContracts?.partnerId);
  };

  useEffect(() => {
    setDataContracts(item);
  }, [item]);

  useEffect(() => {
    setActivePageContract(1);
  }, [activePage]);

  const handleChangePageContract = (pageNumber) => {
    setActivePageContract(pageNumber);
    getListContract({ pageMonitor: pageNumber, page: activePage, sortRecruitment: dataContracts?.partnerId });
  };

  return (
    <div className="setting-master-menu-wrapper">
      <div className="wrapper-btn-colappse">
        <div
          onClick={toggle}
          className="mb-1 searchbar d-flex justify-content-between align-items-center"
          style={{ background: `${colors}` }}
        >
          <div className="ml-2" style={{ color: `${colorText}`, fontSize: '16px'}}>
            {dataContracts.name}
          </div>
          <div className="mr-2" style={{ color: `${colorText}` }}>
            {isOpen ? <i className="icon-up-open icon-searchbar" /> : <i className="icon-down-open icon-searchbar" />}
          </div>
        </div>
      </div>
      <div>
        <Collapse isOpen={hideshow === dataContracts?.partnerId}>
          <div className="table-list-contract">

            <table className="table table-bordered">
              <tbody>
                <tr>
                  <td>No</td>
                  <td>会社名</td>
                  <td>Product</td>
                  <td>Project</td>
                  <td className='w-20'>Service</td>
                  <td>種別</td>
                  <td>契約開始</td>
                  <td>契約終了</td>
                  <td>契約状況</td>
                  <td  className='w-10'>アラート</td>
                  <td>操作</td>
                </tr>
                {dataContracts?.data?.map((itemIn, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{itemIn?.clientName}</td>
                      <td>{itemIn?.productName}</td>
                      <td>{itemIn?.projectName}</td>
                      <td>
                        {!_.isEmpty(itemIn?.services) ? itemIn?.services.map((ele) => {
                          return <button className="px-2 m-1 d-inline-block btn-services">{ele}</button>;
                        }) : ''}
                      </td>
                      <td>{itemIn?.contractStatus?.contractType}</td>
                      <td>{itemIn?.contractStatus?.contractPeriodStart ? moment(new Date(itemIn?.contractStatus?.contractPeriodStart)).format(DATE_JP_FORMAT) : ''}</td>
                      <td>{itemIn?.contractStatus?.contractPeriodEnd ? moment(new Date(itemIn?.contractStatus?.contractPeriodEnd)).format(DATE_JP_FORMAT) : ''}</td>
                      <td>{itemIn?.contractStatus?.state}</td>
                      <td>
                        <div className='status-contract'>
                          {itemIn?.alertContract}
                        </div>
                      </td>
                      <td className="justify-content-center">
                        {
                          <Link
                            to={`/contract/registration?recruitmentContractId=${itemIn.recruitmentId}&recruitmentEstimateId=${itemIn.contractEstimateId}&onBack=`}
                            className="btn-edit"
                          >
                            編集
                          </Link>
                        }
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {!_.isEmpty(dataContracts?.data) && dataContracts?.count > 10 && (
              <PaginationCustom
                activePage={activePageContract}
                itemsCountPerPage={10}
                totalItemsCount={dataContracts?.count}
                pageRangeDisplayed={5}
                handlePageChange={handleChangePageContract}
              />
            )}
          </div>
        </Collapse>
      </div>
    </div>
  );
};

export default compose(translate('translations'))(CollapItem);
