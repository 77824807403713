import React, { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { pushToast } from '../../../../../components/Toast';
import { DEVICE_ENDPOINT } from '../../../../../constants/endpoints';
import { SCREEN_NAME } from '../../../../../helpers/constants';

const ModalUpdateDevice = (props) => {
  const { toggle = () => {}, modal = false, id, loadListDevice = () => {}} = props;
  const [listProductForOrder, setListProductForOrder] = useState({});
  const [dataDetail, setDataDetail] = useState('');
  const [defaultCsv, setDefaultCsv] = useState([]);

  const getListProductForOrder = async ({}) => {
    try {
      const data = await DEVICE_ENDPOINT.GET_LIST_PRODUCT_BY_PERMISSION({});
      if (data) {
        setListProductForOrder(data?.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getDetailDevice = async ({ id = '' }) => {
    try {
      const data = await DEVICE_ENDPOINT.GET_DETAIL_DEVICE({ id: id });
      if (data) {
        setDataDetail(data?.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getListProductForOrder({});
  }, []);

  useEffect(() => {
    if (id) {
      getDetailDevice({ id });
      getDefaultCsv({ id });
    }
  },[modal, id]);

  const getDefaultCsv = async ({ id = '' }) => {
    try {
      const data = await DEVICE_ENDPOINT.GET_DEFAULT_CSV({ id: id });
      if (data) {
        setDefaultCsv(data?.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onClickSubmit = () => {
    const newData = { ...dataDetail , screenName : SCREEN_NAME.ITEM_MANAGEMENT };
    delete newData.createdAt;
    delete newData.createdBy;
    delete newData.deviceId;
    delete newData.japaneseFields;
    delete newData.updatedAt;
    delete newData.updatedBy;
    UpdateDevice({ body: newData, id: id });
  };

  const UpdateDevice = async ({ body, id }) => {
    try {
      const data = await DEVICE_ENDPOINT.UPDATE_DEVICE({ body: body, id: id });
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
      } else {
        pushToast('success', 'update data success');
        toggle(toggle);
        loadListDevice()
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleChangeText = (e) => {
    let name = e.target.name;
    let newData = { ...dataDetail, [name]: e.target.value };
    setDataDetail(newData);
  };

  const handleChangeCheck = (e) => {
    let arrTmp = dataDetail.productSchema;
    let target = e.target;
    let value = target.value;
    if (target.checked) {
      arrTmp.push(value);
    } else {
      arrTmp.splice(arrTmp.indexOf(value), 1);
    }
    let newData = { ...dataDetail, productSchema: arrTmp };
    setDataDetail(newData);
  };

  return (
    <Modal className="form-add-goods-master" isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle}></ModalHeader>
      <ModalBody>
        <div className="create-equipment">
          <table className="table table-create table-bordered">
            <tbody>
              <tr>
                <td className="set-width-col">撤去端末情報</td>
                <td>
                  <CSVLink data={defaultCsv} filename="default-csv.csv">
                    <button className="btn button-down-csv fz-14">
                      <i className="fa fa-download" />
                      現在のCSVフォーマットDownload
                    </button>
                  </CSVLink>
                </td>
              </tr>
              {Object.keys(dataDetail && dataDetail.japaneseFields).map(function (item, index) {
                return (
                  <tr>
                    <td className="set-width-col">{dataDetail && dataDetail.japaneseFields[item]}</td>
                    <td>
                      {item === 'productSchema' ? (
                        listProductForOrder &&
                        listProductForOrder.body &&
                        listProductForOrder.body.map((item, index) => {
                          return (
                            <span key={index}>
                              <input
                                type="checkbox"
                                onChange={handleChangeCheck}
                                value={item.productId}
                                checked={dataDetail.productSchema.includes(item.productId)}
                              />
                              {item.name}
                            </span>
                          );
                        })
                      ) : (
                        <input
                          type="text"
                          name={item}
                          className="form-control"
                          value={dataDetail && dataDetail[item] ? dataDetail[item]: ''}
                          onChange={handleChangeText}
                        />
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button className="btn btn-success" onClick={onClickSubmit}>
          更新
        </Button>
        <Button color="secondary" onClick={toggle}>
          キャンセル
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalUpdateDevice;
