import React, { useState, useEffect } from 'react';
import Header from 'components/Header';
import { useSelector } from 'react-redux';
import Loading from 'components/Loading/Loading';
import { PRODUCT_ENDPOINT, ORDER_PARTNER } from '../../../constants/endpoints';
import { CSVLink } from 'react-csv';
import _ from 'lodash';
import moment from 'moment';
import { DATETIME_EXPORT_JP_FORMAT } from 'helpers/constants';
import { Collapse } from 'reactstrap';
import { translate } from 'react-i18next';
import PaginationCustom from 'components/PaginationCustom';
import TableOrderGroup from './TableOrderGroup';
import Footer from '../../../components/Footer';

const INIT_AFFILIATE_PAGINATION = {
  page: 1,
  limit: 10,
};

const OrderGroup = ({ t }) => {
  const colorScreen = useSelector((store) => store.utils.colorMe);
  const paramSearch = useSelector((store) => store.utils.paramSearch);
  const [idRole, setIdRole] = useState(null);
  const [loading, setLoading] = useState(false);
  const [listProduct, setListProduct] = useState([]);
  const [product, setProduct] = useState('');
  const [partnerId, setPartnerId] = useState('');
  const [sortData, setSortData] = useState({});
  const [productId, setProductId] = useState('');
  const [activePageSearch, setActivePageSearch] = useState(1);
  const [defaultCsv, setDefaultCsv] = useState([]);
  const [statusShowAll, setStatusShowAll] = useState(false);
  const [statusShowData, setStatusShowData] = useState(true);
  const [activeMenu, setActiveMenu] = useState('');
  const [activePageTable, setActivePageTable] = useState(1);
  const [hideshow, setHideshow] = useState('');

  const showAll = (status, active = '') => {
    setStatusShowAll(status);
  };

  const showData = (status) => {
    setStatusShowData(status);
    setActiveMenu(status ? '稼働調整' : '稼働不可一覧');
  };

  const responApi = (productId, data, countCheck) => {
    if (productId !== '') {
      const tmp1 = listProduct;
      const tmp2 = tmp1.map((item) => {
        if (item.productId === data?.data?.products[0]?.productId) {
          return { ...item, body: data?.data?.products[0]?.body, countSummary: data?.data?.products[0]?.countSummary };
        }
        return item;
      });
      setListProduct(tmp2);
    } else {
      setListProduct(data?.data?.products || []);
      setPartnerId(data?.data?.partnerId);
    }
    const count = !data?.data?.count ? 0 : countCheck ? product : data?.data?.count
    setProduct(count);
    setLoading(false);
  };

  const formApi = async ({
    page = INIT_AFFILIATE_PAGINATION.page,
    limit = INIT_AFFILIATE_PAGINATION.limit,
    pageProduct = INIT_AFFILIATE_PAGINATION.page,
    limitProduct = INIT_AFFILIATE_PAGINATION.limit,
    productId = '',
    API,
    sortBy,
    sortProductId,
    sortWith,
    totalScreen = '',
    caseName = '',
    managementCode = '',
    serviceName = '',
    requestedDateTo = '',
    requestedDateFrom = '',
    operationAdjustmentDateTo = '',
    operationAdjustmentDateFrom = '',
    applicantCompanyName = '',
    resultsPreparation = '',
    visitDateTo = '',
    visitDateFrom = '',
    partnerName = '',
    visitedBaseName = '',
    reportReceiptDate = '',
    reportDeliveryDate = '',
    contactName = '',
    installationSiteName = '',
    timeAddPartnerTo = '',
    timeAddPartnerFrom = '',
    operationAdjustmentInfo = '',
    reportReceiptDateFrom = '',
    reportReceiptDateTo = '',
    timestampReportUploadInfoFrom = '',
    timestampReportUploadInfoTo = '',
    checkVisitDate = '',
    checkTimeAddPartner = '',
    checkReportReceiptDate = '',
    isDefault = false,
    countCheck = false,
  }) => {
    setLoading(true);
    try {
      const data = !isDefault
        ? await API({
            page,
            limit,
            pageProduct: statusShowAll ? 0 : pageProduct,
            limitProduct,
            sortBy,
            sortProductId,
            sortWith,
            caseName: paramSearch.caseName || caseName,
            managementCode: paramSearch.managementCode || managementCode,
            serviceName: paramSearch.serviceName || serviceName,
            requestedDateTo: paramSearch.requestedDateTo || requestedDateTo,
            requestedDateFrom: paramSearch.requestedDateFrom || requestedDateFrom,
            operationAdjustmentDateTo: paramSearch.operationAdjustmentDateTo || operationAdjustmentDateTo,
            operationAdjustmentDateFrom: paramSearch.operationAdjustmentDateFrom || operationAdjustmentDateFrom,
            applicantCompanyName: paramSearch.applicantCompanyName || applicantCompanyName,
            resultsPreparation: paramSearch.resultsPreparation || resultsPreparation,
            visitDateTo: paramSearch.visitDateTo || visitDateTo,
            visitDateFrom: paramSearch.visitDateFrom || visitDateFrom,
            partnerName: paramSearch.partnerName || partnerName,
            visitedBaseName: paramSearch.visitedBaseName || visitedBaseName,
            prefecturesVisited: paramSearch.prefecturesVisited,
            reportReceiptDate: paramSearch.reportReceiptDate || reportReceiptDate,
            reportDeliveryDate: paramSearch.reportDeliveryDate || reportDeliveryDate,
            installationSiteName: paramSearch.installationSiteName || installationSiteName,
            timeAddPartnerFrom: paramSearch.timeAddPartnerFrom || timeAddPartnerFrom,
            timeAddPartnerTo: paramSearch.timeAddPartnerTo || timeAddPartnerTo,
            reportReceiptDateFrom: paramSearch.reportReceiptDateFrom || reportReceiptDateFrom,
            reportReceiptDateTo: paramSearch.reportReceiptDateTo || reportReceiptDateTo,
            timestampReportUploadInfoFrom: paramSearch.timestampReportUploadInfoFrom || timestampReportUploadInfoFrom,
            timestampReportUploadInfoTo: paramSearch.timestampReportUploadInfoTo || timestampReportUploadInfoTo,
            operationAdjustmentInfo: paramSearch.operationAdjustmentInfo || operationAdjustmentInfo,
            contactName: paramSearch.contactName || contactName,
            checkVisitDate: paramSearch.checkVisitDate || checkVisitDate,
            checkTimeAddPartner: paramSearch.checkTimeAddPartner || checkTimeAddPartner,
            checkReportReceiptDate: paramSearch.checkReportReceiptDate || checkReportReceiptDate,
            productId,
            totalScreen,
            permissionIdGroup : localStorage.getItem('permissionIdGroup')
          })
        : {};
      responApi(productId, data , countCheck);
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  const getCSVDownLoad = async ({
    screenName: screenName,
    productId: productId,
    caseName = '',
    managementCode = '',
    serviceName = '',
    requestedDateTo = '',
    requestedDateFrom = '',
    operationAdjustmentDateTo = '',
    operationAdjustmentDateFrom = '',
    applicantCompanyName = '',
    resultsPreparation = '',
    visitDateTo = '',
    visitDateFrom = '',
    partnerName = '',
    visitedBaseName = '',
    reportReceiptDate = '',
    reportDeliveryDate = '',
    contactName = '',
    installationSiteName = '',
    timeAddPartnerTo = '',
    timeAddPartnerFrom = '',
    operationAdjustmentInfo = '',
    reportReceiptDateFrom = '',
    reportReceiptDateTo = '',
    timestampReportUploadInfoFrom = '',
    timestampReportUploadInfoTo = '',
    checkVisitDate = '',
    checkTimeAddPartner = '',
    checkReportReceiptDate = '',
    organizationId,
  }) => {
    try {
      setLoading(true);
      const data = await PRODUCT_ENDPOINT.DOWNLOAD_CSV_PRODUCT_SETTING({
        screenName: screenName,
        productId: productId,
        caseName: paramSearch.caseName || caseName,
        managementCode: paramSearch.managementCode || managementCode,
        serviceName: paramSearch.serviceName || serviceName,
        requestedDateTo: paramSearch.requestedDateTo || requestedDateTo,
        requestedDateFrom: paramSearch.requestedDateFrom || requestedDateFrom,
        operationAdjustmentDateTo: paramSearch.operationAdjustmentDateTo || operationAdjustmentDateTo,
        operationAdjustmentDateFrom: paramSearch.operationAdjustmentDateFrom || operationAdjustmentDateFrom,
        applicantCompanyName: paramSearch.applicantCompanyName || applicantCompanyName,
        resultsPreparation: paramSearch.resultsPreparation || resultsPreparation,
        visitDateTo: paramSearch.visitDateTo || visitDateTo,
        visitDateFrom: paramSearch.visitDateFrom || visitDateFrom,
        partnerName: paramSearch.partnerName || partnerName,
        visitedBaseName: paramSearch.visitedBaseName || visitedBaseName,
        prefecturesVisited: paramSearch.prefecturesVisited,
        reportReceiptDate: paramSearch.reportReceiptDate || reportReceiptDate,
        reportDeliveryDate: paramSearch.reportDeliveryDate || reportDeliveryDate,
        installationSiteName: paramSearch.installationSiteName || installationSiteName,
        timeAddPartnerFrom: paramSearch.timeAddPartnerFrom || timeAddPartnerFrom,
        timeAddPartnerTo: paramSearch.timeAddPartnerTo || timeAddPartnerTo,
        reportReceiptDateFrom: paramSearch.reportReceiptDateFrom || reportReceiptDateFrom,
        reportReceiptDateTo: paramSearch.reportReceiptDateTo || reportReceiptDateTo,
        timestampReportUploadInfoFrom: paramSearch.timestampReportUploadInfoFrom || timestampReportUploadInfoFrom,
        timestampReportUploadInfoTo: paramSearch.timestampReportUploadInfoTo || timestampReportUploadInfoTo,
        operationAdjustmentInfo: paramSearch.operationAdjustmentInfo || operationAdjustmentInfo,
        contactName: paramSearch.contactName || contactName,
        checkVisitDate: paramSearch.checkVisitDate || checkVisitDate,
        checkTimeAddPartner: paramSearch.checkTimeAddPartner || checkTimeAddPartner,
        checkReportReceiptDate: paramSearch.checkReportReceiptDate || checkReportReceiptDate,
        permissionIdGroup : localStorage.getItem('permissionIdGroup'),
        organizationId
      })
      if (data?.data) {
        setDefaultCsv(data?.data);
        document.getElementById(`download-csv-${productId}`).click();
      }
      setLoading(false);
    } catch (errorMessage) {
      console.log('errorMessage: ', errorMessage);
      setLoading(false);
    }
  };

  useEffect(() => {
    const newRole = localStorage.getItem('role');
    setIdRole(newRole);
  }, []);

  const getListReceiveConfirm = ({ dataSearch }) => {
    dataSearch = { ...dataSearch, ...sortData }
    const API = ORDER_PARTNER.GET_LIST_RECEIVE_CONFIRM;
    formApi({ ...dataSearch, API });
  };

  const getPartnerUnoperationList = ({ dataSearch }) => {
    dataSearch = { ...dataSearch, ...sortData }
    const API = ORDER_PARTNER.GET_PARTNER_UNOPERATION_LIST;
    formApi({ ...dataSearch, API });
  };

  const sortOrderBy = (sortBody) => {
    setSortData(sortBody);
  };

  useEffect(() => {
    const dataSearch = {
      page: 1,
      limit: 10,
      pageProduct: activePageSearch,
      limitProduct: 10,
      productId,
    };
    setActivePageTable(1)
    statusShowData ? getListReceiveConfirm({ dataSearch }) : getPartnerUnoperationList({ dataSearch });
  }, [statusShowAll, statusShowData, sortData , activePageSearch]);

  const handlePageChangeSearch = (pageNumber) => {
    setActivePageSearch(pageNumber);
  };

  const handleHide = (id) => {
    if (hideshow === id) {
      setHideshow('');
    } else {
      setHideshow(id);
    }
  };

  const toggleHide = (e, id) => {
    handleHide(id);
    e.stopPropagation();
  };

  const onDownload = (e) => {
    e.stopPropagation();
  };

  const onDownLoadCSV = (e, productId, organizationId = '') => {
    getCSVDownLoad({ screenName: statusShowData ? 'receive_all' : 'unoperation_list', productId, organizationId });
    e.stopPropagation();
  };

  useEffect(() => {
    const newRole = localStorage.getItem('role');
    setIdRole(newRole);
  }, []);

  return (
    <div className="main">
      <Header
        p="orderGroup"
        idRole={idRole}
        color={colorScreen?.code}
        colorText={colorScreen?.colorText}
        showAll={showAll}
        showData={showData}
        roleDetail={'PARTNERGROUP'}
      />
      <Loading loading={loading} />
      <div className="body-wrapper">
        <div className="table-menu-wrapper">
          {listProduct &&
            listProduct.map((item, index) => {
              return (
                <>
                  <div className="wrapper-btn-colappse" onClick={(e) => toggleHide(e, item.productId)}>
                    <div
                      data-id={index}
                      className="mb-1 searchbar d-flex justify-content-between align-items-center "
                      style={{ background: `${colorScreen?.code}` }}
                    >
                      <div className="ml-2" style={{ color: `${colorScreen?.colorText}` , fontSize: '16px'}}>
                        {item.caseName}
                        {item?.exportSetting?.isDisplay && (
                          <span className="btn-export-csv" onClick={(e) => onDownLoadCSV(e, item?.productId, item?.organizationId)}>
                            {t('menu_list.button_export_csv')}
                          </span>
                        )}
                        <CSVLink
                          className="text-decoration-none"
                          data={defaultCsv}
                          filename={`${activeMenu}_${moment().format(DATETIME_EXPORT_JP_FORMAT)}.csv`}
                        >
                          <span onClick={onDownload} id={`download-csv-${item.productId}`}></span>
                        </CSVLink>
                      </div>
                      <div className="mr-2 d-flex" style={{ color: `${colorScreen?.colorText}` }}>
                        <div style={{ fontSize: 14 }} className="mt-2 d-flex">
                          {_.map(item?.countSummary, (val, key) => {
                            return (
                              <div className="mx-2 d-flex" key={key}>
                                {t(`project_title.${key}`)}
                                <p className="font-weight-boid px-1" style={{ color: 'red' }}>
                                  {val}
                                </p>{' '}
                                件
                              </div>
                            );
                          })}
                        </div>
                        {hideshow === item.productId ? (
                          <i className="icon-up-open icon-searchbar" />
                        ) : (
                          <i className="icon-down-open icon-searchbar" />
                        )}
                      </div>
                    </div>
                  </div>
                  <Collapse isOpen={hideshow === item.productId ? true : false}>
                    <TableOrderGroup
                      getListProduct={statusShowData ? getListReceiveConfirm : getPartnerUnoperationList}
                      sortOrderBy={sortOrderBy}
                      item={item}
                      idProduct={item?.productId}
                      activePageTable={activePageTable}
                      activePageSearch={activePageSearch}
                      colorText={colorScreen?.colorText}
                      color={colorScreen?.code}
                      partnerId={partnerId}
                      statusShowData={statusShowData}
                      idRole={idRole}
                    />
                  </Collapse>
                </>
              );
            })}
          {product > 10 && (
            <PaginationCustom
              activePage={activePageSearch}
              itemsCountPerPage={10}
              totalItemsCount={product}
              pageRangeDisplayed={5}
              handlePageChange={handlePageChangeSearch}
            />
          )}
        </div>
      </div>
      <Footer color={colorScreen?.code} colorText={colorScreen?.colorText} />
    </div>
  );
};

export default translate('translations')(OrderGroup);
