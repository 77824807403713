import React, { useEffect, useState, Fragment } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { Collapse } from 'reactstrap';
import { pushToast } from '../../../../components/Toast';
import { MASTER_SETTING } from '../../../../constants/endpoints';
import { SCREEN_NAME, PERMITTED_SECURITY, PERMITTED_SECURITY_CREATE } from '../../../../helpers/constants';
import ModalBlockIp from './ModalBlockIp';
import Loading from 'components/Loading/Loading';
import _ from 'lodash';
import { getDataHistoryMasterSetting } from 'actions/authentication';
import { useDispatch } from 'react-redux';

const CollaSecurity = ({ colors, idProvide ,colorText, idRole, permissionId = '' }) => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [securitySettings, setSecuritySettings] = useState({});
  const toggle = () => setIsOpen(!isOpen);
  const [modalOption, setModalOption] = useState(false);
  const [loading, setLoading] = useState(false);
  const [securityRole, setSecurityRole] = useState('');

  useEffect(() => {
    getSecuritySettings();
  }, []);

  const toggleModalOption = (item) => {
    setModalOption(!modalOption);
    setSecurityRole(item)
  }

  const getSecuritySettings = async () => {
    setLoading(true);
    try {
      const data = await MASTER_SETTING.GET_SECURITY_SETTINGS({ idProvide });
      if (data) {
        const organizationId = data?.data?.organizationId || idProvide;
        setSecuritySettings({...data?.data, screenName: SCREEN_NAME.MY_CORP, organizationId});
        setLoading(false);
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const onSubmit = () => {
    UpdateSecurityList({ body: securitySettings?.securitySettingId ? _.pick(securitySettings, PERMITTED_SECURITY) : _.pick(securitySettings, PERMITTED_SECURITY_CREATE) });
  };

  const UpdateSecurityList = async ({ body }) => {
    setLoading(true);
    try {
      const data = await (
        securitySettings?.securitySettingId ? MASTER_SETTING.UPDATE_SECURITY_SETTINGS({ body :{...body, updateTitle : '保存　セキュリティ設定'}, id: securitySettings?.securitySettingId, idProvide }) : MASTER_SETTING.CREATE_SECURITY_SETTINGS({ body :{...body, updateTitle : '保存　セキュリティ設定'}, idProvide })
      )
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
      } else {
        pushToast('success', 'Update Security Settings Success');
        getSecuritySettings();
        dispatch(getDataHistoryMasterSetting({ logId: '', screenName: SCREEN_NAME.MY_CORP, idProvide }));
      }
      setLoading(false);
    } catch (errorMessage) {
      console.log('errorMessage: ', errorMessage);
      setLoading(false);
    }
  };

  const onChange = (e) => {
    const { value, name } = e.target
    if (name === 'securityBatchSetting'){
      const newData = {
        ...securitySettings,
        [name]: parseInt(value),
        'accountValidityPeriod': parseInt(value),
        'passwordSettingValue': parseInt(value),
        'timeOut': parseInt(value)
      };
      setSecuritySettings(newData);
    } else {
      const newData = {...securitySettings, [name]: parseInt(value)};
      setSecuritySettings(newData);
    }
  };

  const onChangePass = (e) => {
    const { value, name } = e.target
    const newData = {...securitySettings, [name]: value};
    setSecuritySettings(newData);
  }

  const onChangeSpecial = (e, parent, child) => {
    const newData = {...securitySettings, [parent]: {
        ...securitySettings?.[parent],
        [child]: {...securitySettings?.[parent]?.[child], [e.target.name]: parseInt(e.target.value) }
      }
    };
    setSecuritySettings(newData);
  };

  const onChangeSpecialBoolean = (e, parent) => {
    const child = e.target.name
    const newData = {...securitySettings, [parent]: {
        ...securitySettings?.[parent],
        [child]: {...securitySettings?.[parent]?.[child], use: e.target.value === 'true'}
      }
    };
    setSecuritySettings(newData);
  };

  const dispatchSecurity = () => {
    switch (idRole) {
      case '1':
      case '2':
      case '5':
        return [{name: 'ERP設定', key: 'erpSettings'}];
      case '8':
        return [{name: 'Management設定', key: 'managementSettings'}];
      default:
        return [{name: 'Monitoring設定', key: 'monitoringSettings'}];
    }
  }

  const fieldUpdate = () => {
    switch (securityRole) {
      case 'ERP設定':
        return 'erpSettings';
      case 'Management設定':
        return 'managementSettings';
      default:
        return 'monitoringSettings';
    }
  }

  return (
    <div className="setting-master-menu-wrapper">
      <Loading loading={loading} />
      <div className="wrapper-btn-colappse">
        <div
          onClick={toggle}
          className="mb-1 searchbar d-flex justify-content-between align-items-center"
          style={{ background: `${colors}` }}
        >
          <div className="ml-2" style={{ color: `${colorText}`, fontSize: '16px'}}>セキュリティ設定</div>
          <div className="mr-2" style={{ color: `${colorText}` }}>
            {isOpen ? <i className="icon-up-open icon-searchbar" /> : <i className="icon-down-open icon-searchbar" />}
          </div>
        </div>
      </div>
      <div>
        <Collapse isOpen={isOpen}>
          <div className="content-view">
            <div className="row custom-row">
              <div className="col-12">
                <div className="col">
                  <label>1．セキュリティ一括設定</label>
                  <div className="radio-button--mg25">
                    <span>
                      <input type="radio"
                        value={0}
                        name="securityBatchSetting"
                        checked={securitySettings?.securityBatchSetting === 0}
                        onChange={onChange}
                      />
                      <div className="radio-button">高</div>
                    </span>
                  </div>
                  <div className="radio-button--mg25">
                    <span>
                      <input type="radio"
                        value={1}
                        name="securityBatchSetting"
                        checked={securitySettings?.securityBatchSetting === 1}
                        onChange={onChange}
                      />
                      <div className="radio-button">中</div>
                    </span>
                  </div>
                  <div className="radio-button--mg25">
                    <span>
                      <input type="radio"
                        value={2}
                        name="securityBatchSetting"
                        checked={securitySettings?.securityBatchSetting === 2}
                        onChange={onChange}
                      />
                      <div className="radio-button">低</div>
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="col">
                  <label>2．アカウント有効期間</label>
                  <div className="radio-button--mg25">
                    <span>
                      <input type="radio"
                        value={0}
                        name="accountValidityPeriod"
                        checked={securitySettings?.accountValidityPeriod === 0}
                        onChange={onChange}
                      />
                      <div className="radio-button">高：1カ月</div>
                    </span>
                  </div>
                  <div className="radio-button--mg25">
                    <span>
                      <input type="radio"
                        value={1}
                        name="accountValidityPeriod"
                        checked={securitySettings?.accountValidityPeriod === 1}
                        onChange={onChange}
                      />
                      <div className="radio-button">中：3カ月</div>
                    </span>
                  </div>
                  <div className="radio-button--mg25">
                    <span>
                      <input type="radio"
                        value={2}
                        name="accountValidityPeriod"
                        checked={securitySettings?.accountValidityPeriod === 2}
                        onChange={onChange}
                      />
                      <div className="radio-button">低：6カ月</div>
                    </span>
                  </div>
                </div>
              </div>
              {/* <div className="col-12">
                <div className="col">
                  <label>3 ．パスワード世代管理</label>
                  <div className="radio-button--mg25">
                    <span>
                      <input type="radio"
                        value={0}
                        name="passwordGenerationManagement"
                        checked={securitySettings?.passwordGenerationManagement === 0}
                        onChange={onChange}
                      />
                      <div className="radio-button">高：過去5世代まで同じパスワードの利用不可</div>
                    </span>
                  </div>
                  <div className="radio-button--mg25">
                    <span>
                      <input type="radio"
                        value={1}
                        name="passwordGenerationManagement"
                        checked={securitySettings?.passwordGenerationManagement === 1}
                        onChange={onChange}
                      />
                      <div className="radio-button">中：過去3世代まで同じパスワードの利用不可</div>
                    </span>
                  </div>
                  <div className="radio-button--mg25">
                    <span>
                      <input type="radio"
                        value={2}
                        name="passwordGenerationManagement"
                        checked={securitySettings?.passwordGenerationManagement === 2}
                        onChange={onChange}
                      />
                      <div className="radio-button">低：なし</div>
                    </span>
                  </div>
                </div>
              </div> */}
              <div className="col-12">
                <div className="col">
                  <label>4 ．パスワード設定値</label>
                  <div className="radio-button--mg25">
                    <span>
                      <input type="radio"
                        value={0}
                        name="passwordSettingValue"
                        checked={securitySettings?.passwordSettingValue === 0}
                        onChange={onChange}
                      />
                      <div className="radio-button">高：8文字以上（数字、英語大文字、英語小文字、記号の組合せ）</div>
                    </span>
                  </div>
                  <div className="radio-button--mg25">
                    <span>
                      <input type="radio"
                        value={1}
                        name="passwordSettingValue"
                        checked={securitySettings?.passwordSettingValue === 1}
                        onChange={onChange}
                      />
                      <div className="radio-button">中：8文字以上（数字、英語大文字、英語小文字の組合せ）</div>
                    </span>
                  </div>
                  <div className="radio-button--mg25">
                    <span>
                      <input type="radio"
                        value={2}
                        name="passwordSettingValue"
                        onChange={onChange}
                        checked={securitySettings?.passwordSettingValue === 2}
                      />
                      <div className="radio-button">低：6文字以上（数字、英語大文字、英語小文字の組合せ）</div>
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="col-12">
                  <label>5．ログイン方法</label>
                  {/* <div className="col-12 row">
                    <div className="radio-button--mg25 col-12">二段階認証</div>
                    <div className="radio-button--mg50 col-5">
                      <span>
                        <input type="radio"
                          value={true}
                          name="twoStepVerification"
                          checked={securitySettings?.howToLogIn?.twoStepVerification?.use}
                          onChange={e => onChangeSpecialBoolean(e, 'howToLogIn')}
                        />
                        <div className="radio-button">利用</div>
                      </span>
                    </div>
                    <div className="radio-button--mg50 col-5">
                      <span>
                        <input type="radio"
                          value={false}
                          name="twoStepVerification"
                          checked={!securitySettings?.howToLogIn?.twoStepVerification?.use}
                          onChange={e => onChangeSpecialBoolean(e, 'howToLogIn')}
                        />
                        <div className="radio-button">未使用</div>
                      </span>
                    </div>
                    <div className="radio-button--mg50 col-5">
                      <span>
                        <input type="radio"
                          value={0}
                          name="verify"
                          checked={securitySettings?.howToLogIn?.twoStepVerification?.verify === 0}
                          onChange={e => onChangeSpecial(e, 'howToLogIn', 'twoStepVerification')}
                        />
                        <div className="radio-button">SMS（携帯電話番号）</div>
                      </span>
                    </div>
                    <div className="radio-button--mg50 col-5">
                      <span>
                        <input type="radio"
                          value={1}
                          name="verify"
                          checked={securitySettings?.howToLogIn?.twoStepVerification?.verify === 1}
                          onChange={e => onChangeSpecial(e, 'howToLogIn', 'twoStepVerification')}
                        />
                        <div className="radio-button">Google Authenticato‪r</div>
                      </span>
                    </div>
                  </div> */}
                  <div className="col-12 row grid--center">
                    <div className="radio-button--mg25 col-12">IPアドレスを設定</div>
                    {/* <div className="radio-button--mg50 col-2">
                      <span>
                        <input type="radio"
                          value={true}
                          name="setIpAddress"
                          checked={securitySettings?.howToLogIn?.setIpAddress?.use}
                          onChange={e => onChangeSpecialBoolean(e, 'howToLogIn')}
                        />
                        <div className="radio-button">設定</div>
                      </span>
                    </div>
                    <div className="radio-button--mg50 col-2">
                      <span>
                        <input type="radio"
                          value={false}
                          name="setIpAddress"
                          checked={!securitySettings?.howToLogIn?.setIpAddress?.use}
                          onChange={e => onChangeSpecialBoolean(e, 'howToLogIn')}
                        />
                        <div className="radio-button">未設定</div>
                      </span>
                    </div> */}
                    <div className="col-6 row">
                      {
                        dispatchSecurity()?.map((item, index) => {
                          return (
                          <Fragment key={index}>
                            <button className="col-5 form__info-company--btn-submit form__info-company--w100 form__info-company--disabled" disabled={!securitySettings?.securitySettingId} onClick={() => toggleModalOption(item.name)}>{idRole === '5' && item.name === 'ERP設定' ? 'ManagementGroup設定' : item.name}</button>
                            <div className="col-6" style={{margin: 'auto'}}>
                              {!permissionId ? (_.isEmpty(securitySettings?.howToLogIn?.setIpAddress?.[item.key]) ? '未設定' : '設定中') : (_.isEmpty(securitySettings?.howToLogIn?.setIpAddress?.[item.key]?.find(e => e.permissionId === permissionId)) ? '未設定' : '設定中')}
                            </div>
                          </Fragment>
                          )
                        })
                      }
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="col">
                  <label>6．タイムアウト</label>
                  <div className="radio-button--mg25">
                    <span>
                      <input type="radio"
                        value={0}
                        name="timeOut"
                        checked={securitySettings?.timeOut === 0}
                        onChange={onChange}
                      />
                      <div className="radio-button">高：15分間操作しないとログアウト</div>
                    </span>
                  </div>
                  <div className="radio-button--mg25">
                    <span>
                      <input type="radio"
                        value={1}
                        name="timeOut"
                        checked={securitySettings?.timeOut === 1}
                        onChange={onChange}
                      />
                      <div className="radio-button">中：30分間操作しないとログアウト</div>
                    </span>
                  </div>
                  <div className="radio-button--mg25">
                    <span>
                      <input type="radio"
                        value={2}
                        name="timeOut"
                        checked={securitySettings?.timeOut === 2}
                        onChange={onChange}
                      />
                      <div className="radio-button">低：60分間操作しないとログアウト</div>
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="col">
                  <label>7．PDF出力パスワード</label>
                  <div className="radio-button--mg25">
                    <input name="pdfPassword" onChange={onChangePass} value={securitySettings?.pdfPassword} />
                  </div>
                </div>
              </div>
              <button className="btn btn-submit" onClick={onSubmit}>
                保存
              </button>
            </div>
          </div>
        </Collapse>
      </div>
      <ModalBlockIp
        modal={modalOption}
        toggle={toggleModalOption}
        color={colors}
        securityRole={securityRole}
        securitySettings={securitySettings}
        idProvide={idProvide}
        getSecuritySettings={getSecuritySettings}
        permissionId={permissionId}
      />
    </div>
  );
};

export default CollaSecurity;
