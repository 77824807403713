import React, { useEffect, useState } from 'react';
import { Collapse } from 'reactstrap';
import { PRODUCT_ENDPOINT } from '../../../../constants/endpoints';
import { withRouter, Link } from 'react-router-dom';
import 'assets/scss/page/ImportCsvPrimary/TreeViewPriSco.scss';
import { getRole } from '../../../../helpers/helpers';
import ModalAddRole from '../../AnkenMasterNew/ModalAddRole';
import ModalAddRoleNext from '../../AnkenMasterNew/ModalAddRoleNext';
import ModalSettingOrder from '../../AnkenMasterNew/ModalSettingOrder';
import ModalSettingOrderDetail from '../../AnkenMasterNew/ModalSettingOrderDetail';
import Loading from 'components/Loading/Loading';
import _ from 'lodash';

const TreeViewPriSco = ({ colors, history, colorText, statusBeforeConfirm }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [modalAddRole, setModalAddRole] = useState(false);
  const [modalAddRoleNext, setModalAddRoleNext] = useState(false);
  const [modalSettingOrder, setModalSettingOrder] = useState(false);
  const [modalSettingOrderDetail, setModalSettingOrderDetail] = useState(false);
  const [listViewTree, setListViewTree] = useState([]);
  const [namePermission, setNamePermission] = useState('');
  const [routeList, setRouteList] = useState('');
  const [productAccess, setProductAccess] = useState('');
  const [idProvide, setIdProvide] = useState(localStorage.getItem('idProvide'));
  const idRole = localStorage.getItem('role');
  const [arrRole, setArrRole] = useState([]);
  const [nameScreen, setNameScreen] = useState('');
  const [detailId, setDetailId] = useState(null);
  const [idClientShow, setIdClientShow] = useState();
  const [loading, setLoading] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  const toggleAddRole = ({ productId, valueRole }) => {
    const roleName = getRole({ idRole: valueRole })?.toLowerCase();
    setNamePermission(roleName);
    setModalAddRole(!modalAddRole);
    setRouteList(valueRole);
    setProductAccess(productId);
  };

  const toggleCloseModal = () => {
    setModalAddRole(false);
    setModalAddRoleNext(false);
  };

  const toggleSettingOrder = (arrRole, name) => {
    setModalAddRole(false);
    setModalSettingOrder(!modalSettingOrder);
    setArrRole(arrRole);
    setNameScreen(name);
  };

  const toggleSettingOrderDetail = (arrRole, name, detailId) => {
    setDetailId(detailId);
    setModalAddRole(false);
    setModalSettingOrderDetail(!modalSettingOrderDetail);
    setArrRole(arrRole);
    setNameScreen(name);
  };

  const toggleAddRoleNext = (arrRole, name) => {
    setArrRole(arrRole);
    setModalAddRole(false);
    setModalAddRoleNext(!modalAddRoleNext);
    setNameScreen(name);
  };

  const OpenClient = (id) => {
    if (idClientShow.includes(id)) {
      setIdClientShow(idClientShow?.filter((ele) => ele !== id));
    } else {
      setIdClientShow([...idClientShow, id]);
    }
  };

  useEffect(() => {
    getListViewTree();
  }, []);

  const getListViewTree = async () => {
    setLoading(true);
    try {
      const data = await PRODUCT_ENDPOINT.GET_LIST_PRODUCT_TREE();
      if (data) {
        let dataClientId = data?.data?.map((ele) => {
          return ele?.clientId;
        });
        setIdClientShow(dataClientId);
        setListViewTree(data?.data);
      }
      setLoading(false)
    } catch (e) {
      setLoading(false)
      console.log(e);
    }
  };

  return (
    <div className="setting-tree-view-pri-sco">
      <Loading loading={loading} />
      <div className="wrapper-btn-colappse">
        <div
          onClick={toggle}
          className="mb-1 searchbar d-flex justify-content-between align-items-center "
          style={{ background: `${colors}` }}
        >
          <div className="ml-2" style={{ color: '#555555', fontSize: '16px'}}>工事名一覧</div>
          <div className="mr-2" style={{ color: `${colorText}` }}>
            {isOpen ? <i className="icon-up-open icon-searchbar" /> : <i className="icon-down-open icon-searchbar" />}
          </div>
        </div>
      </div>
      <div>
        <Collapse isOpen={isOpen}>
          <div className="content-view">
            {listViewTree &&
              listViewTree.map((client, i) => {
                return (
                  <div className="form-tree form-tree--left" key={i}>
                    <div className="d-flex flex-direction-row form-tree--top">
                      <p className="title-project">{client?.abbreviationName}</p>
                    </div>
                    <div className="form-tree--bottom">
                      <div className="form-tree--show">
                        <div className="form-tree--on cursor-pointer" onClick={() => OpenClient(client?.clientId)}>
                          {idClientShow?.includes(client?.clientId) ? '-' : '+'}
                        </div>
                        {idClientShow?.includes(client?.clientId) && (
                          <ul>
                            {client &&
                              client?.projects.map((item, index) => {
                                return (
                                  <>
                                    {item?.product?.map((ele) => {
                                      let dataSs = [];
                                      let dataS = [];
                                      for (const schema in ele?.productSchema) {
                                        if (ele?.productSchema[schema]) {
                                          dataSs.push(schema);
                                          dataS.push(ele?.productSchema[schema]);
                                        }
                                      }
                                      return (
                                        <li key={index}>
                                          <div className="d-flex">
                                            <p>
                                              {ele?.name && item.projectName ? `${ele?.name}-${item.projectName}` : ele?.name || item.projectName}
                                            </p>
                                            <div className="form-btn-csv-pri-sco">
                                              <div className="mb-3">
                                                <Link
                                                  to={`/ImportCsv-pri-sco/primary-csv?productId=${ele.productId}&schemaId=${dataSs[0]}&productName=${ele?.name && item.projectName ? `${ele?.name}-${item.projectName}` : ele?.name || item.projectName}`}
                                                  style={{ textDecoration: 'none' }}
                                                >
                                                  <span
                                                    className={`${
                                                      dataS?.length > 0 && dataS?.[0]?.fieldMaster?.length > 0
                                                        ? 'import-csv-export'
                                                        : 'import-csv'
                                                    }`}
                                                  >
                                                    CSV基本項目
                                                  </span>
                                                </Link>
                                              </div>
                                              <div className="mb-3">
                                                <Link
                                                  to={`/ImportCsv-pri-sco/secondar-csv?productId=${ele.productId}&schemaId=${dataSs[0]}&productName=${ele?.name && item.projectName ? `${ele?.name}-${item.projectName}` : ele?.name || item.projectName}`}
                                                  style={{ textDecoration: 'none' }}
                                                >
                                                  <span
                                                    className={`${
                                                      dataS?.length > 0 && dataS?.[0]?.masterSecondary?.length > 0
                                                        ? 'import-csv-export'
                                                        : 'import-csv'
                                                    }`}
                                                  >
                                                    CSV追加項目
                                                  </span>
                                                </Link>
                                              </div>
                                            </div>
                                          </div>

                                          {/* {ele?.service?.length > 0 ? itemTrees(ele?.service, ele.productId) : ''} */}
                                        </li>
                                      );
                                    })}
                                  </>
                                );
                              })}
                            {client &&
                              client?.nonProjects?.map((ele, elex) => {
                                let dataSs = [];
                                let dataS = [];
                                for (const schema in ele?.productSchema) {
                                  if (ele?.productSchema[schema]) {
                                    dataSs.push(schema);
                                    dataS.push(ele?.productSchema[schema]);
                                  }
                                }
                                return (
                                  <li key={elex}>
                                    <div className="d-flex">
                                      <p>{ele?.name}</p>
                                      <div className="form-btn-csv-pri-sco">
                                        <div className="mb-3">
                                          <Link
                                            to={`/ImportCsv-pri-sco/primary-csv?productId=${ele.productId}&schemaId=${dataSs[0]}&productName=${ele?.name}`}
                                            style={{ textDecoration: 'none' }}
                                          >
                                            <span
                                              className={`${
                                                dataS?.length > 0 && dataS?.[0]?.fieldMaster?.length > 0
                                                  ? 'import-csv-export'
                                                  : 'import-csv'
                                              }`}
                                            >
                                              CSV基本項目
                                            </span>
                                          </Link>
                                        </div>
                                        <div className="mb-3">
                                          <Link
                                            to={`/ImportCsv-pri-sco/secondar-csv?productId=${ele.productId}&schemaId=${dataSs[0]}&productName=${ele?.name}`}
                                            style={{ textDecoration: 'none' }}
                                          >
                                            <span
                                              className={`${
                                                dataS?.length > 0 && dataS?.[0]?.masterSecondary?.length > 0
                                                  ? 'import-csv-export'
                                                  : 'import-csv'
                                              }`}
                                            >
                                              CSV追加項目
                                            </span>
                                          </Link>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                );
                              })}
                          </ul>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </Collapse>
      </div>
      {modalAddRole ? (
        <ModalAddRole
          routeList={routeList}
          modal={modalAddRole}
          toggle={toggleCloseModal}
          ModalAddRoleNext={toggleAddRoleNext}
          namePermission={namePermission}
          idProduct={productAccess}
          idProvide={idProvide}
          statusBeforeConfirm={statusBeforeConfirm}
          ModalDetailSetting={toggleSettingOrder}
        />
      ) : (
        ''
      )}

      {modalAddRoleNext ? (
        <ModalAddRoleNext
          arrRole={arrRole}
          nameScreen={nameScreen}
          modalNext={modalAddRoleNext}
          toggle={toggleCloseModal}
          routeList={routeList}
          idProvide={idProvide}
          idProduct={productAccess}
          namePermission={namePermission}
          onBack={toggleAddRole}
        />
      ) : (
        ''
      )}

      {modalSettingOrder ? (
        <ModalSettingOrder
          arrRole={arrRole}
          nameScreen={nameScreen}
          modalNext={modalSettingOrder}
          toggle={toggleSettingOrder}
          routeList={routeList}
          idProvide={idProvide}
          idProduct={productAccess}
          onBack={toggleAddRole}
          onDetail={toggleSettingOrderDetail}
        />
      ) : (
        ''
      )}

      {modalSettingOrderDetail ? (
        <ModalSettingOrderDetail
          arrRole={getRole({ idRole: arrRole })?.toLowerCase()}
          nameScreen={nameScreen}
          modalNext={modalSettingOrderDetail}
          onBack={toggleSettingOrder}
          toggle={toggleSettingOrderDetail}
          routeList={routeList}
          idProvide={idProvide}
          idProduct={productAccess}
          detailId={detailId}
        />
      ) : (
        ''
      )}
    </div>
  );
};

export default withRouter(TreeViewPriSco);
