import React, { useState, useEffect } from 'react';
import AutocompleteAddress from 'components/AutocompleteAddress';
import 'assets/scss/page/EditStaffMaster/style.scss';
import { STAFF_ENDPOINT, BRANCH_MASTER_ENDPOINT, USER_ENDPOINT } from '../../../constants/endpoints';
import moment from 'moment';
import _ from 'lodash';
import BorderGroupForm from 'components/BorderGroupForm';
import DateTimePicker from 'components/DateTimePicker';
import AreaSelection from 'components/AreaSelection';
import { pushToast } from '../../../components/Toast';
import HeaderMasterSetting from '../../../components/HeaderMasterSetting';
import { useSelector } from 'react-redux';
import { DEFAULT_ADDRESS, SCREEN_NAME, PARAMS_REQUIRED_STAFF } from '../../../helpers/constants';
import { convertFileToBase64 } from 'helpers/helpers';
import Loading from 'components/Loading/Loading';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { withNamespaces } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { validateRequried } from 'helpers/helpers';
import ModalConfirmUpdateStatusUserAndStaff from 'components/ModalConfirmUpdateStatusUserAndStaff';
import { AREAS_PERMITTED } from 'helpers/areas';
import QualificationList from '../../../components/QualificationList/index.jsx';
import { filterBlankItem } from '../../../helpers/helpers';
import FooterMaintenance from "components/FooterMaintenance"

const getQualifiedMock = {
  grade: "",
  licenseNumber: "",
  qualifications: "",
  validityPeriodFrom: "",
  validityPeriodTo: ""
}

const mockData = {
  branchId: '',
  surNameKana: '',
  nameKana: '',
  surName: '',
  name: '',
  contact: '',
  email: '',
  activeArea: {},
  getQualified: [{...getQualifiedMock}]
};

const EditStaffMaster = () => {
  let id = (process.browser && new URL(window.location.href).searchParams.get('sid')) || null;
  const colorScreen = useSelector((store) => store.utils.colorMe);
  const [staffData, setStaffData] = useState(mockData);
  const [data, setData] = useState([]);
  const [listBranchMaster, setListBranchMaster] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isShowModalUploadStaffImg, setIsShowModalUploadStaffImg] = useState(false);
  const [staffImgLink, setStaffImgLink] = useState('');
  const [isShowModalUpdateStatusStaff, setIsShowModalUpdateStatusStaff] = useState(false);
  const [isDisable, setIsDisable] = useState(true);
  const [prefectureAreas, setPrefectureAreas] = useState({});
  const history = useHistory();
  const prefectureNameKanji = _.groupBy(AREAS_PERMITTED, 'prefectureNameKanji');
  const [fileName, setFileName] = useState('【stellaCloud】システム利用開始のご案内');
  const [dataConfirm, setDataConfirm] = useState({});

  const getStaff = async ({ id }) => {
    try {
      setLoading(true);
      const data = await STAFF_ENDPOINT.GET_DETAIL({ id });
      if (data) {
        setData(data.data);
        const dataHandler = _.flatten(Object.values(data?.data?.activeArea || {})).map(e => {
          return !_.isEmpty(data?.data?.prefectureAreas[e]) ? {[e]: data?.data?.prefectureAreas[e]} : {[e]: _.compact(_.map(prefectureNameKanji[e] || [], 'cityNameKanji'))}
        });
        const newPrefectureAreas = _.isEmpty(dataHandler) ? {} : Object.assign(...dataHandler);
        setPrefectureAreas(newPrefectureAreas || {});
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  const onExportPdf = () => {
    const body = {
      abbreviationName: dataConfirm?.abbreviationName,
      codeConfirm: dataConfirm?.codeConfirm,
      email: dataConfirm?.email || data?.email || data?.emailTemp,
      isActive: dataConfirm?.isActive,
      isStaff: true,
      role: 'Management',
      permissionId: localStorage.getItem('permissionId'),
      idRole: data?.primaryRole?.idRole,
      userDynamo: {
        firstName: data?.surName,
        surName: data?.surName,
        lastName: data?.name,
        name: data?.name,
        loginId: data?.loginId,
        organizationId: data?.organizationId,
      }
    };
    exportPdfUser({ body });
  }

  const exportPdfUser = async ({ body = {} }) => {
    try {
      const data = await USER_ENDPOINT.EXPORT_USER_PDF({ body, fileName });
      if (data?.data) {
        setLoading(false);
      }else {
        setLoading(false);
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!_.isEmpty(dataConfirm)) {
      onExportPdf();
    }
  }, [dataConfirm]);

  const getConfirmCodeInfo = async ({ userId = '', staffId = '', loginId = '' }) => {
    try {
      setLoading(true);
      const data = await USER_ENDPOINT.USER_PDF({ userId, staffId });
      if (data?.data) {
        setFileName(`【stellaCloud】システム利用開始のご案内_${data?.data?.abbreviationName || ''}_${loginId}.pdf`);
        setDataConfirm(data?.data || {});
      }else {
        setLoading(false);
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const getListBranchMaster = async () => {
    try {
      setLoading(true);
      const data = await BRANCH_MASTER_ENDPOINT.GET_LIST_BRANCH_MASTER({
        page: 0,
      });
      if (data) {
        setListBranchMaster(data?.data?.body);
      }
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const resendConfirmationCode = async ({ body }) => {
    try {
      setLoading(true);
      const data = await USER_ENDPOINT.REGISTER_STAFF_COGNITO({ body, staffId: id });
      if (!data.data) {
        pushToast('error', data?.message?.errorMessage || data?.message);
        setLoading(false);
      } else {
        pushToast('success', 'Resend confirmation success');
        setLoading(false);
      }
    } catch (errorMessage) {
      console.log('errorMessage: ', errorMessage);
      setLoading(false);
    }
  };

  useEffect(() => {
    getListBranchMaster();
  }, []);

  useEffect(() => {
    if(staffData) {
      validateRequried({data: staffData, arrField: PARAMS_REQUIRED_STAFF, onDisabled: onDisabled});
    }
  }, [staffData]);

  const onDisabled = ({status}) => {
    if([true, '', null].includes(staffData?.haveToEmail) && !staffData?.email) {
      document.getElementById("email").classList.add('isRequired');
      setIsDisable(true);
    } else {
      document.getElementById("email").classList.remove('isRequired');
      setIsDisable(status);
    }
  };

  useEffect(() => {
    if (data) {
      setStaffData({
        ...staffData,
        branchId: data?.branchId,
        surNameKana: data?.surNameKana,
        nameKana: data?.nameKana,
        surName: data?.surName,
        name: data?.name,
        email: data?.email,
        loginId: data?.loginId,
        passwordExpirationDate: data?.passwordExpirationDate,
        contact: data?.contact,

        movableDistance: (data?.movableDistanceTemp) ? data?.movableDistanceTemp : data?.movableDistance,
        distanceRange: (data?.distanceRangeTemp) ? data?.distanceRangeTemp : data?.distanceRange,
        address: (data?.addressTemp) ? data?.addressTemp : data?.address,
        activeArea: (data?.activeAreaTemp) ? data?.activeAreaTemp || {} : data?.activeArea || {},
        area: (data?.prefecturesTemp) ? data?.prefecturesTemp : data?.area,
        addressKana: (data?.addressKanaTemp) ? data?.addressKanaTemp : data?.addressKana,
        latitude: (data?.latitudeTemp) ? data?.latitudeTemp : data?.latitude,
        longitude: (data?.longitudeTemp) ? data?.longitudeTemp : data?.longitude,
        postalCode: (data?.postalCodeTemp) ? data?.postalCodeTemp : data?.postalCode,
        dateOfBirth: data?.dateOfBirth,
        bloodType: data?.bloodType,
        isLock: false,
        haveToEmail: data?.haveToEmail === false ? false : true,
        getQualified: data?.getQualified || [{ ...getQualifiedMock }, { ...getQualifiedMock }, {...getQualifiedMock}]
      });

      setStaffImgLink(data?.facePhoto?.url)
    }
  }, [data]);

  useEffect(() => {
    if (id) {
      getStaff({ id: id });
    }
  }, [id]);

  const updateStaffMaster = async ({ body, id = '' }) => {
    try {
      setLoading(true);
      const data = await STAFF_ENDPOINT.UPDATE_STAFF({ body, id });
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
        setLoading(false);
      } else {
        pushToast('success', 'Update info success');
        getStaff({ id });
        setLoading(false);
      }
    } catch (errorMessage) {
      console.log('errorMessage: ', errorMessage);
      setLoading(false);
    }
  };

  const uploadStaffImg = async () => {
    try {
      setLoading(true);
      const body = { facePhoto: staffData.facePhoto }
      const data = await STAFF_ENDPOINT.UPDATE_STAFF({ body, id });
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
        setLoading(false);
      } else {
        pushToast('success', 'Update staff photo success');
        getStaff({ id });
        setLoading(false);
      }
    } catch (errorMessage) {
      console.log('errorMessage: ', errorMessage);
      setLoading(false);
    }
  };

  const handleUpdateStatusStaff = async ({ onUpdateSuccess, status }) => {
    try {
      setLoading(true);
      const data = await STAFF_ENDPOINT.UPDATE_STATUS_OF_STAFF({
        body: { statusStaff: status, screenName: SCREEN_NAME.STAFF_MASTER, updateTitle: `利用停止 ${staffData?.loginId || ''}`},
        id
      });
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
        setLoading(false);
      } else {
        if (status === 2) {
          return history.push(`/staff_master`)
        }
        pushToast('success', 'Update status of staff success');
        getStaff({ id });
        onUpdateSuccess();
        setLoading(false);
      }
    } catch (errorMessage) {
      console.log('errorMessage: ', errorMessage);
      setLoading(false);
    }
  };

  const handleUpdate = () => {
    let getQualified = [...staffData?.getQualified]
    getQualified = getQualified.filter((item, index) => filterBlankItem(item) == false)

    if (staffData && id) {
      const body = { ...staffData, getQualified, prefectureAreas, primaryKeyType: 'Worker', screenName: SCREEN_NAME.STAFF_MASTER, updateTitle: `更新 Staffマスタ ${staffData?.loginId || ''}` };
      updateStaffMaster({ body: _.omit(body, ['loginId', 'isLock']), id });
    }
  };

  const handleRemote = (index) => {
    const getQualified = [...staffData?.getQualified];
    getQualified.splice(index, 1);
    setStaffData({ ...staffData, getQualified });
  };

  const addNewItem = () => {
    let getQualified = [...staffData?.getQualified, { ...getQualifiedMock }];
    setStaffData({ ...staffData, getQualified });
  };


  const onResend = () => {
    resendConfirmationCode({ body: { screenName: SCREEN_NAME.STAFF_MASTER } });
  };

  const handleOnChangeStaffImg = async (e) => {
    const file = e.target?.files[0]
    if(file) {
      const imgBase64 = await convertFileToBase64(file)
      setStaffImgLink(imgBase64)
      setStaffData({
        ...staffData,
        facePhoto: {
          fileName: file.name,
          content: imgBase64
        }
      })
    }
  }

  const onChangeText = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const newData = {...staffData, [name]: value};
    setStaffData(newData);
  };

  const handleChange = (e) => {
    const name = e.target.name;
    if(e.target.checked) {
      setStaffData({ ...staffData, [name]: !e.target.checked, email: data?.email ? staffData?.email : ''});
    } else {
      setStaffData({ ...staffData, [name]: !e.target.checked});
    };
  }

  const handleChangeQualificationText = ({ e, item, index, name, value }) => {
    const fieldName = (e && e.target.name) || name
    item[fieldName] = (e && e.target.value) || value
    const getQualified = staffData?.getQualified
    getQualified[index] = item
    setStaffData({ ...staffData, getQualified })
  };

  const handleRemoveStaffImg = () => {
    setStaffImgLink('/img/default-user.png')
    setStaffData({
      ...staffData,
      facePhoto: null
    })
  };

  const handleChangeCheckBox = (e) => {
    const name = e.target.name;
    setStaffData({ ...staffData, [name]: e.target.checked});
  };

  const renderModalUploadStaffImg = () => {
    return (
      <Modal
        isOpen={isShowModalUploadStaffImg}
        toggle={() => setIsShowModalUploadStaffImg(!isShowModalUploadStaffImg)}
        className="modal-upload-staff-img"
      >
        <ModalHeader toggle={() => setIsShowModalUploadStaffImg(!isShowModalUploadStaffImg)}>写真</ModalHeader>
        <ModalBody>
          <label
            className="btn"
            htmlFor="id-upload-face-photo"
          >
            写真選択
          </label>
          <input
            type="file" id="id-upload-face-photo"
            onChange={(e) => handleOnChangeStaffImg(e)}
          />
          <img className='staff-img' src={staffImgLink || '/img/default-user.png'} />
        </ModalBody>
        <ModalFooter>
          <button className="btn" onClick={() => handleRemoveStaffImg()} disabled={!staffImgLink} >写真削除</button>
          <button className="btn" onClick={() => uploadStaffImg()}>保存</button>
        </ModalFooter>
      </Modal>
    )
  }

  const onChangePrefectureAreas = (data) => {
    setPrefectureAreas(data);
  }

  const onChangeAreas = (activeArea) => {
    const newData = { ...staffData, activeArea };
    setStaffData(newData);
    const values = _.flatten(Object.values(newData?.activeArea || {}));
    const dataHandler = values.map(e => {
      return Object.keys(prefectureAreas || {}).includes(e) ? {[e]: prefectureAreas[e]} : {[e]: _.compact(_.map(prefectureNameKanji[e] || [], 'cityNameKanji'))}
    })
    const newPrefectureAreas = _.isEmpty(dataHandler) ? {} : Object.assign(...dataHandler);
    setPrefectureAreas(newPrefectureAreas);
  }

  return (
    <div className="main-branch-master staff-master-form-update-screen">
      <Loading loading={loading} />
      <HeaderMasterSetting checkName={'/staff_master'} color={colorScreen?.code} colorText={colorScreen?.colorText} />
      <BorderGroupForm
        color={colorScreen?.code}
      >
        <div className="body-wrapper mb-1">
          <div align="center" className="search">
            <div>
              <table className="staff-master-table">
                <tbody>
                <tr>
                  <th colSpan="4" className="header-title">Management</th>
                </tr>
                <tr>
                  <th>利用者ID</th>
                  <td>
                    <input name="loginId" value={staffData.loginId} disabled />
                  </td>
                  <th>パスワード有効期限日</th>
                  <td>
                    <DateTimePicker
                      date={staffData?.passwordExpirationDate ? new Date(staffData?.passwordExpirationDate) : null}
                      name="passwordExpirationDate"
                      id="passwordExpirationDate"
                      isRequired={true}
                      onChangeDate={(date) => {
                        setStaffData({
                          ...staffData,
                          passwordExpirationDate: date ? moment(date).format('YYYY/MM/DD') : ''
                        })
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <th>拠点選択</th>
                  <td>
                    <select
                      name="branchId"
                      id="branchId"
                      onChange={onChangeText}
                      value={staffData?.branchId}
                    >
                      <option value="" selected>----</option>
                      {
                        listBranchMaster ?.map((item, index) => {
                          return (
                            <option value={item.branchId} key={index}>{item.branchName}</option>
                          )
                        })
                      }
                    </select>
                  </td>
                  <td colSpan="2"/>
                </tr>
                <tr>
                  <th>利用者名</th>
                  <td className="inline">
                    <label>姓</label>
                    <input name="surName" value={staffData?.surName} onChange={onChangeText} id="surName" />
                    <label>名</label>
                    <input name="name" value={staffData?.name} onChange={onChangeText} id="name" />
                  </td>
                  <th>フリガナ</th>
                  <td className="inline">
                    <label>セイ</label>
                    <input name="surNameKana" id="surNameKana" value={staffData?.surNameKana} onChange={onChangeText} />
                    <label>メイ</label>
                    <input name="nameKana" id="nameKana" value={staffData?.nameKana} onChange={onChangeText} />
                  </td>
                </tr>
                <tr>
                  <th>携帯電話番号</th>
                  <td>
                    <input name="contact" value={staffData?.contact} onChange={onChangeText} />
                  </td>
                  <td colSpan="2"></td>
                </tr>
                <tr>
                  <th>メールアドレス</th>
                  <td colSpan="3">
                    <div className="group-email-input">
                      <div className="input__box">
                        {
                          data?.email ? (
                            <>
                              <label className="email-label input-email-checkbox--isLock">メールアドレス編集</label>
                              <input checked={staffData?.isLock} name="isLock" className="input-email-checkbox input-email-checkbox--isLock" type="checkbox" onChange={handleChangeCheckBox} />
                            </>
                          ) : (
                            <>
                              <label className="email-label">不所持</label>
                              <input disabled={data?.haveToEmail && staffData?.email} checked={!staffData?.haveToEmail} name="haveToEmail" className="input-email-checkbox" type="checkbox" onChange={handleChange} />
                            </>
                          )
                        }
                      </div>
                      <input disabled={data?.email ? !staffData?.isLock : (data?.haveToEmail && staffData?.email)} name="email" id="email" value={staffData?.email} onChange={onChangeText} className="input__email--w90" />
                      <button className="btn btn-resend-confirm-code" onClick={onResend} disabled={!data?.haveToEmail}>送信</button>
                      <button className="btn btn-resend-confirm-code btn-pdf-125" onClick={() => getConfirmCodeInfo({ staffId: id, loginId: data?.loginId })} >PDF出力</button>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>郵便番号</th>
                  <td className="inline">
                    <input name="postalCode" value={staffData?.postalCode} onChange={onChangeText} />
                  </td>
                  <th>都道府県</th>
                  <td>
                    <select
                      name="area"
                      value={staffData?.area}
                      onChange={onChangeText}
                    >
                      {['', ...DEFAULT_ADDRESS].map((item, index) => {
                        return (
                          <option value={item} key={index}>
                            {item}
                          </option>
                        );
                      })}
                    </select>
                  </td>
                </tr>
                <tr>
                  <th>住所</th>
                  <td colSpan="3">
                    <AutocompleteAddress
                      name="address"
                      address={staffData?.address}
                      onChange={(address) => { setStaffData({ ...staffData, address }) }}
                      onSelectAddress={({ address, latitude, longitude }) => { setStaffData({ ...staffData, address, latitude, longitude }) }}
                    />
                  </td>
                </tr>
                <tr>
                  <th>フリガナ</th>
                  <td colSpan="3">
                    <input name="addressKana" value={staffData?.addressKana} onChange={onChangeText} />
                  </td>
                </tr>
                <tr>
                  <th>緯度</th>
                  <td>
                    <input
                      value={staffData?.latitude}
                      onChange={onChangeText}
                      name="latitude"
                      id="latitude"
                    />
                  </td>
                  <th>経度</th>
                  <td>
                    <input
                      value={staffData?.longitude}
                      onChange={onChangeText}
                      name="longitude"
                      id="longitude"
                    />
                  </td>
                </tr>
                <tr>
                  <th>移動速度</th>
                  <td className="inline">
                    <label>1時間</label>
                    <input
                      value={staffData?.movableDistance?.distance}
                      onChange={(e) => {
                        const distance = e.target.value?.toString();
                        setStaffData({
                          ...staffData,
                          movableDistance: {
                            distance,
                            movingDistance: '60'
                          }
                        })
                      }}
                      type="number"
                    />
                    <label>Km</label>
                  </td>
                  <th>移動距離範囲</th>
                  <td className="inline">
                    <input name="distanceRange" id="distanceRange" value={staffData?.distanceRange} onChange={onChangeText} type="number" />
                    <label>Km</label>
                  </td>
                </tr>
                <tr>
                  <th>対応エリア設定</th>
                  <td colSpan="3">
                    <AreaSelection
                      activeArea={{ ...staffData?.activeArea }}
                      prefectureAreas={prefectureAreas}
                      onChangePrefectureAreas={onChangePrefectureAreas}
                      onChange={(activeArea) => {
                        onChangeAreas(activeArea) }}
                    />
                  </td>
                </tr>
                <tr>
                  <th>顔写真</th>
                  <td>
                    <button
                      className="btn btn-upload-staff-img"
                      onClick={() => { setIsShowModalUploadStaffImg(!isShowModalUploadStaffImg)}}
                    >
                      アップロード済
                    </button>
                    {renderModalUploadStaffImg()}
                  </td>
                  <th>生年月日・血液型</th>
                  <td className="inline group-birthday-input">
                    <DateTimePicker
                      date={staffData?.dateOfBirth ? new Date(staffData?.dateOfBirth) : null}
                      onChangeDate={(date) => {
                        setStaffData({
                          ...staffData,
                          dateOfBirth: date ? moment(date).format('YYYY/MM/DD') : ''
                        })
                      }}
                    />
                    <input name="bloodType" value={staffData?.bloodType} onChange={onChangeText} className="bloodtype-input" />
                    <label>型</label>
                  </td>
                </tr>
                <tr>
                  <th>取得資格</th>
                  <td colSpan="3">
                    <QualificationList
                      userData={staffData}
                      handleChangeQualificationText={handleChangeQualificationText}
                      handleRemote={handleRemote}
                      addNewItem={addNewItem}
                    />
                  </td>
                </tr>
                </tbody>
              </table>

              { data?.userId &&
              <button
                className="btn btn-submit-staff"
                onClick={() => { history.push(`/center_staff_master/select-table-organization?userId=${data?.userId}`) }}
              >
                参照権限設定
              </button>
              }
              <button
                className="btn btn-submit-staff"
                onClick={handleUpdate}
                disabled={isDisable}
              >
                更新
              </button>
              <div>
                <ModalConfirmUpdateStatusUserAndStaff
                  isOpen={isShowModalUpdateStatusStaff}
                  handleUpdateStatus={handleUpdateStatusStaff}
                  status={data?.status}
                  toggle={() => setIsShowModalUpdateStatusStaff(false)}
                />
                <button
                  className="btn btn-submit-staff"
                  onClick={() => setIsShowModalUpdateStatusStaff(true)}
                >
                  停止／再開／削除
                </button>
              </div>
            </div>
          </div>
        </div>
      </BorderGroupForm>
      <FooterMaintenance/>
    </div>
  );
};

export default withNamespaces('translations')(EditStaffMaster);
