import React, { useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { Collapse } from 'reactstrap';
import Loading from 'components/Loading/Loading';
import _ from 'lodash';
import { MASTER_SETTING } from '../../../../constants/endpoints';
import { pushToast } from '../../../../components/Toast';

const CollaAddFileHtml = ({ colors, colorText, idProvide }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const [loading, setLoading] = useState(false);
  const [dataDomain, setDataDomain] = useState();
  const [dataUpload, setDataUpload] = useState({});

  const getDetailSetting = async ({ organizationId }) => {
    setLoading(true);
    try {
      const data = await MASTER_SETTING.GET_DETAIL_SETTING({ organizationId });
      if (data) {
        setDataDomain(data?.data);
        setDataUpload({ ...dataUpload, fileName: data?.data?.fileName });
        setLoading(false);
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const uploadFile = async ({ body = {} }) => {
    try {
      const data = await MASTER_SETTING.UPLOAD_BACKGROUD({ body});
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else if (data.message.errorMessage) {
          pushToast('error', data?.message?.errorMessage);
        } else {
          pushToast('error', data?.message);
        }
      } else {
        pushToast('success', 'Upload success');
        setDataDomain({ ...dataDomain, ...data?.data });
      }
    } catch (errorMessage) {
      console.log('errorMessage: ', errorMessage);
    }
  };

  const hanleUploadFile = () => {
    if(idProvide) {
      const body = {
        ...dataUpload,
        organizationId: idProvide,
      }
      uploadFile({ body });
    }
  }

  const onChangeHandler = async (e) => {
    const files = e?.target?.files[0];
    const name = e?.target?.files[0]?.name;
    const content = files ?  await toBase64(files) : ''
    setDataUpload({
      content,
      fileName: name,
    });

  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const CreateSettingDomain = async ({ body = {} }) => {
    try {
      const data = await MASTER_SETTING.CREATE_SETTING_DOMAIN({ body });
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
      } else {
        pushToast('success', 'Update success');
      }
    } catch (errorMessage) {
      console.log('errorMessage: ', errorMessage);
    }
  };

  useEffect(() => {
    if (idProvide) {
      getDetailSetting({ organizationId: idProvide });
    }
  }, [idProvide]);

  const handleChangeText = (e) => {
    setDataDomain({ ...dataDomain, [e?.target?.name]: e?.target?.value });
  };

  return (
    <div className="setting-master-menu-wrapper">
      <Loading loading={loading} />
      <div className="wrapper-btn-colappse">
        <div
          onClick={toggle}
          className="mb-1 searchbar d-flex justify-content-between align-items-center"
          style={{ background: `${colors}` }}
        >
          <div className="ml-2" style={{ color: `${colorText}`, fontSize: '16px' }}>
            ドメイン設定
          </div>
          <div className="mr-2" style={{ color: `${colorText}` }}>
            {isOpen ? <i className="icon-up-open icon-searchbar" /> : <i className="icon-down-open icon-searchbar" />}
          </div>
        </div>
      </div>
      <div>
        <Collapse isOpen={isOpen}>
          <div className="col-12 row form__info-company--none-space">
            <table className="w-100">
              <tr>
                <th className="form__info-company--with-mix form__info-company--label w-100">domain</th>
                <th>
                  <input
                    className="form__info-company--with-mix form__info-company--label w-100 text-left"
                    name="domain"
                    value={dataDomain?.domain}
                    onChange={handleChangeText}
                  />
                </th>
              </tr>
              <tr>
                <td className="form__info-company--with-mix form__info-company--label w-100">login HTML</td>
                <td>
                  <textarea
                  name="loginHtml"
                  value={dataDomain?.loginHtml}
                  onChange={handleChangeText}
                  className="form__info-company--with-mix form__info-company--label w-100 text-left"
                /></td>
              </tr>
              <tr>
              <td className="form__info-company--with-mix form__info-company--label w-100">Upload background</td>
              <td>
                <div className="col-9">
                  <div className="d-flex">
                    <div>
                      <label
                        htmlFor="upload-backgound"
                        className={`btn-modal`}
                      >
                        ファイル選択
                      </label>
                      <input
                        type="file"
                        name="file"
                        id="upload-backgound"
                        className="d-none"
                        onChange={onChangeHandler}
                      />
                    </div>
                    <div className="col-6">
                      <input
                        type="text"
                        className={`w-100`}
                        placeholder={dataUpload?.fileName}
                      />
                    </div>
                    <div>
                      <label className="btn-modal" onClick={() => hanleUploadFile()}>
                        アップロード
                      </label>
                    </div>
                  </div>
              </div>
              </td>
              </tr>
              <tr>
                <td className="form__info-company--with-mix form__info-company--label w-100">URL</td>
                <td>
                  <input
                  name="loginHtml"
                  value={dataDomain?.url}
                  disabled
                  className="form__info-company--with-mix form__info-company--label w-100 text-left"
                /></td>
              </tr>
            </table>
            <button
              className="form__info-company--btn-submit"
              onClick={() => CreateSettingDomain({ body: { ..._.pick(dataDomain, ['domain', 'loginHtml']), organizationId: idProvide } })}
            >
              保存
            </button>
          </div>
        </Collapse>
      </div>
    </div>
  );
};

export default CollaAddFileHtml;
