import React, { useState, useEffect } from 'react';
import { Collapse } from 'reactstrap';
import 'react-datepicker/dist/react-datepicker.css';
import '../../../../assets/scss/page/center/Users/style.scss';
import { ORDER } from '../../../../constants/endpoints';
import PaginationCustom from '../../../../components/PaginationCustom';
import Order from '../Order';
import _ from 'lodash';

const Orders = ({colors ,colorText, item = [], isShow, handleCollap, changeLoading }) => {
  const [dataOrders, setDataOrders] = useState(item);
  const toggle = () => handleCollap(item.productId);
  const [activePage, setActivePage] = useState(1);

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
    getOrders({page: pageNumber});
  };

  const getOrders = async ({
      page = 1, limit = 10, pageProduct = 1, limitProduct = 10, productId = item.productId
    }) => {
    try {
      changeLoading(true);
      const data = await ORDER.GET_ORDERS_ACCEPTANCE({
        page, limit,pageProduct, limitProduct, productId
      });
      if (data) {
        changeLoading();
        setDataOrders(data?.data?.products?.[0] || []);
      }
    } catch (e) {
      changeLoading();
      console.log(e);
    }
  };

  useEffect(() => {
    setDataOrders(item);
  }, [item]);

  return (
    <div className="setting-master-menu-wrapper">
      <div className="wrapper-btn-colappse">
        <div onClick={toggle} className="mb-1 searchbar d-flex justify-content-between align-items-center" style = {{background :`${colors}`}}>
          <div className="ml-2" style={{ color: `${colorText}`, fontSize: '16px'}}>{dataOrders?.caseName}</div>
          <div className="mr-2" style={{ color: `${colorText}` }}>
            {isShow === item.productId ? <i className="icon-up-open icon-searchbar" /> : <i className="icon-down-open icon-searchbar" />}
          </div>
        </div>
      </div>
      <div>
        <Collapse isOpen={isShow === item.productId}>
          <div className="form__content">
            <Order orders={dataOrders} colors={colors} activePage={activePage} productId={dataOrders?.productId} />
            {!_.isEmpty(dataOrders?.body) && dataOrders?.count > 10 && (
              <PaginationCustom
                activePage={activePage}
                itemsCountPerPage={10}
                totalItemsCount={dataOrders && dataOrders?.count}
                pageRangeDisplayed={5}
                handlePageChange={handlePageChange}
              />
            )}
          </div>
        </Collapse>
      </div>
    </div>
  );
};

export default Orders;
