import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';

import 'assets/scss/page/AnkenMasterNew/TableModal.scss';

const TableModalService = ({
  handleClickBtn,
  dataTableService
}) => {

  const onClickButon = ({ serviceId, state }) => {
    handleClickBtn({ serviceId, state })
  };

  return (
    <div className="wrapper-table-process">
      <table className="table-model-service">
        <tbody>
        {dataTableService &&
        dataTableService.map((item, index) => (
          <tr key={index}>
            <th>
              <div className="p-2 d-flex table-process-col">
                <div className="wrapper-id">{index + 1}</div>
                {item?.name}
              </div>
            </th>
            <td>
              <div className="p-2 d-flex table-process-right col-6">
                <div className="group-button-wrapper">
                  <div className="btn-group btn-group-toggle" data-toggle="buttons">
                    <label className={`fz-14 btn process-button ${item.state ? 'btn-primary' : 'btn-light'}`} onClick={() => onClickButon({serviceId: item.serviceId, state: item.state})}>
                      必要
                    </label>
                    <label className={`fz-14 btn process-button ${item.state ? 'btn-light' : 'btn-primary'}`} onClick={() => onClickButon({serviceId: item.serviceId, state: item.state})}>
                      不要
                    </label>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        ))}
        </tbody>
      </table>
    </div>
  );
};

export default compose(withRouter)(TableModalService);
