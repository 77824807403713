import React, { useState, useEffect } from 'react';
import { getNoOrder } from 'helpers/helpers';
import { DATE_JP_FORMAT } from 'helpers/constants';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Collapse } from 'reactstrap';
import { ROLE_TYPE_ORGANIZATION, ROLE_ENUM } from 'helpers/constants';
import PaginationCustom from 'components/PaginationCustom';
import { USER_ENDPOINT } from 'constants/endpoints';
import Loading from 'components/Loading/Loading';
import _ from "lodash";

const data = {
  header: [
    '利用者ID',
    '利用者名',
    'フリガナ',
    '連絡先',
    'email',
    '作成日',
    '更新日',
    '利用者権限',
    '利用状況',
    '利用停止日',
    '操作',
  ],
};

const DetailUsers = ({ listUser = {}, colors, k, colorText, handleCollapChild, isShowChild, prePage = 1 }) => {
  const [loading, setLoading] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [dataUsers, setDataUsers] = useState(listUser);
  const [search, setSearch] = useState('');

  const handleSearchData = async () => {
    const searchSuccess = await getDataTables({page: prePage, pageUser: 1, type: k, permissionId: dataUsers?.permissionId, name: search});
    if (searchSuccess) {
      setActivePage(1)
    }
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSearchData()
    }
  }

  useEffect(() => {
    if(!_.isEmpty(listUser)){
      setDataUsers(listUser);
      setActivePage(1);
    }
  }, [listUser])

  const capitalizeFirstLetter = (str="") => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  const renderItem = (item, index) => {
    return (
      <tr key={index}>
        <td className="no-wrapper">
          <div>{getNoOrder({ index: index, limit: 10, activePage: activePage })}</div>
        </td>
        <td>{item?.loginId}</td>
        <td>{item?.firstName + ' ' + item?.lastName}</td>
        <td>{item?.firstNameKana + ' ' + item?.lastNameKana}</td>
        <td>{item?.phoneNumber}</td>
        <td>{item?.email}</td>
        <td>{item?.createdAt ? moment(new Date(item?.createdAt)).format(DATE_JP_FORMAT) : ""}</td>
        <td>{item?.updatedAt ? moment(new Date(Array.isArray(item?.updatedAt) ? item?.updatedAt?.[0] : item?.updatedAt)).format(DATE_JP_FORMAT) : ""}</td>
        <td>{ROLE_ENUM[item?.primaryRole?.idRole]}</td>
        <td>{item?.userSub && item?.usageStatus !== 3 ? (item?.usageStatus === 1 ? '利用停止' : '利用中') : ''}</td>
        <td>{item?.usageStatus === 1 ? item?.statusUpdatedAt : ''}</td>
        {item?.editableFlag != 1 ? <td></td> :
          <td className='button--center button--wrap'>
            <Link to={`/users/edit-user?sid=${item?.userId}&role=${capitalizeFirstLetter(k)}&permissionId=${dataUsers?.permissionId}`}>
              <div className="button__edit">編集</div>
            </Link>
          </td>
        }
      </tr>
    );
  };

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
    getDataTables({page: prePage, pageUser: pageNumber, type: k, permissionId: dataUsers?.permissionId});
  };

  const getDataTables = async ({
      page = 1, limit = 10, pageUser = 1, limitUser = 10, type = '', permissionId = '', name = search
    }) => {
    try {
      setLoading(true);
      const data = await USER_ENDPOINT.GET_LIST_USER_PARTNER({
        page, limit, pageUser, limitUser, type, permissionId, name
      });
      if (data) {
        setLoading(false);
        setDataUsers(data?.data?.[k]?.[0])
        return true;
      }
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  const handleRoleApi = () => {
    return ROLE_TYPE_ORGANIZATION.find((i) => i.type === k);
  }

  const handleUriQuery = () => {
    const {name, permission} = handleRoleApi();
    return _.isEmpty(permission) ? `role=${name}` : `role=${name}&${permission}=${dataUsers?.permissionId}`
  }

  const changeSearch = (e) => {
    let value = e.target.value;
    setSearch(value);
  }

  return (
    <>
      <div className="wrapper-btn-colappse collap__child">
        <Loading loading={loading} />
        {
          (!['ERP', 'managementGroup'].includes(k)) && (
            <div onClick={() => handleCollapChild(dataUsers?.permissionId)} className="mb-1 searchbar d-flex justify-content-between align-items-center" style = {{background :`${colors}`}}>
              <div className="ml-2" style={{ color: `${colorText}`, fontSize: '16px' }}>{dataUsers?.officialName}</div>
              <div className="mr-2" style={{ color: `${colorText}` }}>
                {isShowChild === dataUsers?.permissionId ? <i className="icon-up-open icon-searchbar" /> : <i className="icon-down-open icon-searchbar" />}
              </div>
            </div>
          )
        }
        <Collapse isOpen={isShowChild === dataUsers?.permissionId || ['ERP', 'managementGroup'].includes(k)} className="collap__child collap__child--border">
          <div className="form__flex form__flex--mgt-10">
            <div className="form-search">
              <div className="form__group-input form__group-input--border-none">
                <div className="title">検索条件</div>
                <input type="text" onChange={(e) => changeSearch(e)} onKeyDown={(e) => handleKeyDown(e)} />
              </div>
              <button className="btn-tree" onClick = {handleSearchData} >検索</button>
            </div>
            <Link to={`/users/register-user?${handleUriQuery()}`} className="button__btn-load button__btn-load--modify">新規利用者登録</Link>
          </div>
          <div className="table-list-wrapper table-responsive">
            <table className={`table table-striped`}>
              <thead>
                <tr style={{ backgroundColor: `${colors}` }}>
                  <th scope="col">No</th>
                  {data.header.map((item, index) => (
                    <th scope="col" key={index}>
                      {item}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>{(dataUsers?.users || []).map((listData, index) => renderItem(listData, index))}</tbody>
            </table>
          </div>
          {
            (dataUsers?.count || 0) > 10 && (
              <PaginationCustom
                activePage={activePage}
                itemsCountPerPage={10}
                totalItemsCount={dataUsers?.count}
                pageRangeDisplayed={5}
                handlePageChange={handlePageChange} />
            )
          }
        </Collapse>
      </div>
    </>
  );
};

export default DetailUsers;
