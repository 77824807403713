import React, { useEffect, useState } from 'react';
import { Collapse } from 'reactstrap';
import { ANKEN_MASTER_NEW } from 'constants/endpoints';
import ModalCreateProject from '../ModalCreateProject';
import ModalCreateProduct from '../ModalCreateProduct';
import ModalCreateService from '../ModalCreateService';
import ModalAddProductInProject from '../ModalAddProductInProject';
import ModalAddServiceInProduct from '../ModalAddServiceInProduct';
import ModalDocumentSettings from '../ModalDocumentSettings';
import _ from 'lodash';
import 'assets/scss/page/AnkenMasterNew/CollaMasterViewTree.scss'
import ModalResultSettings from '../ModalResultSettings';
import ModalCancelSettings from '../ModalCancelSettings';
import ModalAlertSettings from '../ModalAlertSettings';
import ModalAccessUsers from '../ModalAccessUsers';
import ModalAddRole from '../ModalAddRole';
import ModalAddRoleNext from '../ModalAddRoleNext';
import ModalSettingOrder from '../ModalSettingOrder';
import ModalSettingOrderDetail from '../ModalSettingOrderDetail';
import Loading from 'components/Loading/Loading';
import { getRole } from 'helpers/helpers';
import { getDataHistoryMasterSetting } from 'actions/authentication';
import { useDispatch } from 'react-redux';
import { SCREEN_NAME } from '../../../../helpers/constants';

const CollaMasterViewTree = ({ colors, colorText, statusBeforeConfirm}) => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [modalCreateProject, setModalCreateProject] = useState(false);
  const [modalCreateProduct, setModalCreateProduct] = useState(false);
  const [modalCreateService, setModalCreateService] = useState(false);
  const [modalAddProductInProject, setModalAddProductInProject] = useState(false);
  const [modalAddServiceInProduct, setModalAddServiceInProduct] = useState(false);
  const [modalSettingOrder,setModalSettingOrder] = useState(false);
  const [modalSettingOrderDetail,setModalSettingOrderDetail] = useState(false);
  const [modalResultSettings, setModalResultSettings] = useState(false);
  const [modalCancelSettings, setModalCancelSettings] = useState(false);
  const [modalDocumentSettings, setModalDocumentSettings] = useState(false);
  const [modalAddRole, setModalAddRole] = useState(false);
  const [modalAddRoleNext, setModalAddRoleNext] = useState(false);
  const [idProjectChoose, setIdProjectChoose] = useState('');
  const [projectName, setProjectName] = useState('');
  const [listTreeNone, setListTreeNone] = useState();
  const [listTreeProject, setListTreeProject] = useState([]);
  const [infoProductModal, setInfoProductModal] = useState([]);
  const [search, setSearch] = useState('');
  const [dataInit, setDataInit] = useState([]);
  const [serviceResult, setServiceResult] = useState(null);
  const [serviceCancel, setServiceCancel] = useState(null);
  const [productResult, setProductResult] = useState(null);
  const [productCancel, setProductCancel] = useState(null);
  const [serviceAlert, setServiceAlert] = useState(null);
  const [productAlert, setProductAlert] = useState(null);
  const [productAccess, setProductAccess] = useState(null);
  const [modalAlertSettings, setModalAlertSettings] = useState(null);
  const [modalAccessUsers, setModalAccessUsers] = useState(null);
  const [dataDocumentSetting, setDataDocumentSetting] = useState({});
  const [dataTreeOption, setDataTreeOption] = useState({});
  const toggle = () => setIsOpen(!isOpen);
  const [loading, setLoading] = useState(false);
  const [idProvide] = useState(localStorage.getItem('idProvide'));
  const [routeList, setRouteList] = useState(null);
  const [namePermission, setNamePermission] = useState('');
  const [nameScreen, setNameScreen] = useState('');
  const [arrRole, setArrRole] = useState([]);
  const [detailId, setDetailId] = useState(null);
  const idRole = localStorage.getItem('role');
  const [idClientShow , setIdClientShow] = useState([]);
  const [idClientModal,setIdClientModal] = useState('');
  const [listDataProject, setListDataProject] = useState([]);
  const [nameTitle, setNameTitle] = useState('')

  const toggleAddRoleNext = (arrRole, name) => {
    setArrRole(arrRole);
    setModalAddRole(false);
    setModalAddRoleNext(!modalAddRoleNext);
    setNameScreen(name);
  };

  const toggleSettingOrder = (arrRole, name) => {
    setModalAddRole(false);
    setModalSettingOrder(!modalSettingOrder);
    setArrRole(arrRole);
    setNameScreen(name);
  };

  const toggleSettingOrderDetail = (arrRole, name, detailId) => {
    setDetailId(detailId);
    setModalAddRole(false);
    setModalSettingOrderDetail(!modalSettingOrderDetail);
    setArrRole(arrRole);
    setNameScreen(name);
  };

  const toggleAddRole = ({productId , valueRole, nameTitles}) => {
    const roleName = getRole({ idRole: valueRole })?.toLowerCase();
    setNamePermission(roleName);
    setModalAddRole(!modalAddRole);
    setRouteList(valueRole);
    setProductAccess(productId);
    setNameTitle(nameTitles);
  };

  const toggleOpenCreateProject = ({clientId ,nameTitles}) => {
    setModalCreateProject(!modalCreateProject);
    setIdClientModal(clientId)
    setNameTitle(nameTitles);
  };

  const toggleOpenCreateProduct = (id = '' ,projects ,nameTitles) => {
    setModalCreateProduct(!modalCreateProduct);
    setIdClientModal(id);
    setListDataProject(projects);
    setNameTitle(nameTitles);
  };

  const toggleOpenCreateService = () => {
    setModalCreateService(!modalCreateService);
  };

  const toggleOpenAddProductInProject = (id, name) => {
    setModalAddProductInProject(!modalAddProductInProject);
    setIdProjectChoose(id);
    setProjectName(name);
  };

  const toggleOpenAddServiceInProduct = ({projectName, productId, productName, serviceSchema, service, nameTitles}) => {
    setModalAddServiceInProduct(!modalAddServiceInProduct);
    setInfoProductModal({projectName, productId, productName, serviceSchema, service});
    setNameTitle(nameTitles);
  };

  const toggleOpenResultSettings = ({ item, productId, nameTitles }) => {
    setServiceResult(item.serviceId)
    setProductResult(productId)
    setModalResultSettings(!modalResultSettings);
    setNameTitle(nameTitles);
  };

  const toggleOpenCancelSettings = ({ item, productId, nameTitles }) => {
    setServiceCancel(item.serviceId)
    setProductCancel(productId)
    setModalCancelSettings(!modalCancelSettings);
    setNameTitle(nameTitles);
  };

  const toggleOpenAlertSettings = ({ item, productId, nameTitles }) => {
    setServiceAlert(item.serviceId)
    setProductAlert(productId)
    setModalAlertSettings(!modalAlertSettings);
    setNameTitle(nameTitles);
  };

  const toggleOpenAccessUsers = ({ productId, nameTitles}) => {
    setProductAccess(productId)
    setModalAccessUsers(!modalAccessUsers);
    setNameTitle(nameTitles);
  };

  const toggleOpenDocumentSettings = ({dataInfo, dataTree, nameTitles}) => {
    setModalDocumentSettings(!modalDocumentSettings);
    setDataDocumentSetting(dataInfo);
    setDataTreeOption(dataTree);
    setNameTitle(nameTitles);
  };

  const toggleCloseModal = () => {
    setModalCreateProject(false);
    setModalCreateProduct(false);
    setModalCreateService(false);
    setModalAddProductInProject(false);
    setModalAddServiceInProduct(false);
    setModalResultSettings(false);
    setModalCancelSettings(false);
    setModalAlertSettings(false);
    setModalAccessUsers(false);
    setModalDocumentSettings(false);
    setModalAddRole(false);
    setModalAddRoleNext(false);
  };

  useEffect(() => {
    getTree();
  }, []);

  const createSuccess = () => {
    getTree();
    setModalCreateProject(false);
    setModalCreateProduct(false);
    setModalAddProductInProject(false);
    setIdProjectChoose('');
    dispatch(getDataHistoryMasterSetting({ logId: '', screenName: SCREEN_NAME.CASE_MASTA }));
  };

  const getTree = async () => {
    try {
      setLoading(true);
      const data = await ANKEN_MASTER_NEW.GET_TREE_BY_PROJECT();
      if (data) {
        setListTreeProject(data?.data?.clients);
        let dataClientId = data?.data?.clients?.map((ele) => {
          return ele?.clientId;
        });
        setIdClientShow(_.compact(dataClientId));
        setDataInit(data?.data);
        setLoading(false);
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const searchData = () => {
    const format = /[ *()+\-=\[\]{}\\|\/?]/;
    if(_.isEmpty(search)){
      setListTreeProject(dataInit?.clients);
    }else {
      if(format.test(search)) {
        return false
      } else {
        const resultClient = _.filter(dataInit?.clients, function(elm) { return elm.name.search(new RegExp(`${search}`, "i")) !== -1 });
        const resultProject = _.compact(dataInit?.clients.map((item) => {return searchProject(item)}));
        const resultProduct = _.compact(dataInit?.clients.map((item) => {return searchProductProject(item)}));
        const resultServices = _.compact(dataInit?.clients.map((item) => {return searchServiceProductProject(item)}));
        setListTreeProject(_.merge(resultServices, _.merge(resultProduct, _.merge(resultProject, resultClient))));
      }
    }
  }

  const searchServiceProducts = (elm) => {
    const data = {...elm};
    const products = _.compact(data.product?.map((item) => {return searchServices(item)}));
    data.product = products
    return _.isEmpty(products) ? null : data
  }

  const searchServices = (elm) => {
    const data = {...elm};
    const services = _.filter(data.service, function(e) {return e.name.search(new RegExp(`${search}`, "i")) !== -1});
    data.service = services
    return _.isEmpty(services) ? null : data
  }

  const searchProducts = (elm) => {
    const data = {...elm};
    const products = _.filter(data.product, function(e) {return e.name.search(new RegExp(`${search}`, "i")) !== -1});
    data.product = products
    return _.isEmpty(products) ? null : data
  }

  const searchProject = (elm) => {
    const data = {...elm};
    const projects = _.filter(data.projects, function(e) {return e.projectName.search(new RegExp(`${search}`, "i")) !== -1});
    const nonProjects = _.filter(data?.nonProjects, function(e) {return e.name.search(new RegExp(`${search}`, "i")) !== -1});
    data.projects = projects
    data.nonProjects = nonProjects
    return !_.isEmpty(nonProjects) || !_.isEmpty(projects) ? data : null
  }

  const searchProductProject = (elm) => {
    const data = {...elm};
    const projects = _.compact(data.projects?.map((item) => {return searchProducts(item)}));
    data.projects = projects
    return _.isEmpty(projects) ? null : data
  }

  const searchServiceProductProject = (elm) => {
    const data = {...elm};
    const projects = _.compact(data.projects?.map((item) => {return searchServiceProducts(item)}));
    data.projects = projects
    return _.isEmpty(projects) ? null : data
  }

  const changeSearch = (e) => {
    let value = e.target.value;
    setSearch(value);
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      searchData();
    }
  }

  const OpenClient = (id) => {
    if(idClientShow.includes(id)) {
      setIdClientShow(idClientShow?.filter((ele) => ele !== id ))
    } else {
      setIdClientShow([...idClientShow,id])
    }
  }

  const renderTreeProject = (client, i) => {
    return (
      <div className="form-tree form-tree--left" key={i}>
        <div className="d-flex flex-direction-row form-tree--top">
          <p className="title-project">
            {client?.abbreviationName}
            <button  onClick={() => toggleOpenCreateProduct(client?.clientId , client?.projects , client?.abbreviationName)}>
              工事名追加登録
             </button>
            <button className="btn-tree" onClick={() => toggleOpenCreateProject({clientId: client?.clientId , nameTitles : client?.abbreviationName})}>
              プロジェクト追加登録
            </button>
          </p>
        </div>
        <div className="form-tree--bottom">
          <div className="form-tree--show">
            <div className="form-tree--on cursor-pointer" onClick={() => OpenClient(client?.clientId)}>
              {idClientShow?.includes(client?.clientId) ? '-' : '+'}
            </div>
            {idClientShow?.includes(client?.clientId) && (
              <ul>
              {client &&
                client?.projects.map((item, index) => {
                  return (
                    <>
                      {item?.product?.map((ele, i) => {
                        return (
                          <li key={i}>
                            <p>
                              {ele?.name && item.projectName ? `${ele?.name}-${item.projectName}` : ele?.name || item.projectName}
                              <button
                                onClick={() =>
                                  toggleOpenDocumentSettings({
                                    dataInfo: { projectId: item.projectCode, productId: ele?.productId },
                                    dataTree: item,
                                    nameTitles : ele?.name && item.projectName ? `${ele?.name}-${item.projectName}` : ele?.name || item.projectName,
                                  })
                                }
                              >
                                ドキュメント設定
                              </button>
                              <button
                                onClick={() =>
                                  toggleOpenAddServiceInProduct({
                                    projectName: item.projectName,
                                    productId: ele.productId,
                                    productName: ele.name,
                                    serviceSchema: ele.serviceSchema,
                                    service: ele.service,
                                    nameTitles : ele?.name && item.projectName ? `${ele?.name}-${item.projectName}` : ele?.name || item.projectName,
                                  })
                                }
                              >
                                工程設定
                              </button>
                              <button
                                onClick={() =>
                                  toggleOpenAccessUsers({
                                    projectName: item.projectName,
                                    productId: ele.productId,
                                    productName: ele.name,
                                    serviceSchema: ele.serviceSchema,
                                    service: ele.service,
                                    nameTitles : ele?.name && item.projectName ? `${ele?.name}-${item.projectName}` : ele?.name || item.projectName,
                                  })
                                }
                              >
                                参照権限設定
                              </button>
                              <button
                                onClick={() =>
                                  toggleAddRole({
                                    valueRole: '2',
                                    projectName: item.projectName,
                                    productId: ele.productId,
                                    productName: ele.name,
                                    serviceSchema: ele.serviceSchema,
                                    service: ele.service,
                                    nameTitles : ele?.name && item.projectName ? `${ele?.name}-${item.projectName}` : ele?.name || item.projectName,
                                  })
                                }
                              >
                                {[5, 6, 7, '5', '6', '7'].includes(idRole) ? 'ManagementGroup' : 'ERP表示設定'}
                              </button>
                              <button
                                onClick={() =>
                                  toggleAddRole({
                                    valueRole: '8',
                                    projectName: item.projectName,
                                    productId: ele.productId,
                                    productName: ele.name,
                                    serviceSchema: ele.serviceSchema,
                                    service: ele.service,
                                    nameTitles : ele?.name && item.projectName ? `${ele?.name}-${item.projectName}` : ele?.name || item.projectName,
                                  })
                                }
                              >
                                Partner表示設定
                              </button>
                              <button
                                onClick={() =>
                                  toggleAddRole({
                                    valueRole: '12',
                                    projectName: item.projectName,
                                    productId: ele.productId,
                                    productName: ele.name,
                                    serviceSchema: ele.serviceSchema,
                                    service: ele.service,
                                    nameTitles : ele?.name && item.projectName ? `${ele?.name}-${item.projectName}` : ele?.name || item.projectName,
                                  })
                                }
                              >
                                Client表示設定
                              </button>
                            </p>
                            {ele?.service?.length > 0 ? itemTrees({ array: ele?.service, productId: ele.productId, nameTitleService: ele?.name && item.projectName ? `${ele?.name}-${item.projectName}` : ele?.name || item.projectName }) : ''}
                          </li>
                        );
                      })}
                    </>
                  );
                })}
              {client &&
                client?.nonProjects?.map((ele, elex) => {
                  return (
                    <li key={elex}>
                      <p>
                        {ele?.name}
                        <button
                          onClick={() =>
                            toggleOpenDocumentSettings({
                              dataInfo: { projectId: '', productId: ele?.productId },
                              dataTree: ele,
                              nameTitles : ele?.name ,
                            })
                          }
                        >
                          ドキュメント設定
                        </button>
                        <button
                          onClick={() =>
                            toggleOpenAddServiceInProduct({
                              projectName: '',
                              productId: ele.productId,
                              productName: ele.name,
                              serviceSchema: ele.serviceSchema,
                              service: ele.service,
                              nameTitles : ele?.name,
                            })
                          }
                        >
                          工程設定
                        </button>
                        <button
                          onClick={() =>
                            toggleOpenAccessUsers({
                              projectName: '',
                              productId: ele.productId,
                              productName: ele.name,
                              serviceSchema: ele.serviceSchema,
                              service: ele.service,
                              nameTitles : ele?.name,
                            })
                          }
                        >
                          参照権限設定
                        </button>
                        <button
                          onClick={() =>
                            toggleAddRole({
                              valueRole: '2',
                              projectName: '',
                              productId: ele.productId,
                              productName: ele.name,
                              serviceSchema: ele.serviceSchema,
                              service: ele.service,
                              nameTitles : ele?.name,
                            })
                          }
                        >
                          {[5, 6, 7, '5', '6', '7'].includes(idRole) ? 'ManagementGroup' : 'ERP表示設定'}
                        </button>
                        <button
                          onClick={() =>
                            toggleAddRole({
                              valueRole: '8',
                              projectName: '',
                              productId: ele.productId,
                              productName: ele.name,
                              serviceSchema: ele.serviceSchema,
                              service: ele.service,
                              nameTitles : ele?.name,
                            })
                          }
                        >
                          Partner表示設定
                        </button>
                        <button
                          onClick={() =>
                            toggleAddRole({
                              valueRole: '12',
                              projectName: '',
                              productId: ele.productId,
                              productName: ele.name,
                              serviceSchema: ele.serviceSchema,
                              service: ele.service,
                              nameTitles : ele?.name,
                            })
                          }
                        >
                          Client表示設定
                        </button>
                      </p>
                      {ele?.service?.length > 0 ? itemTrees({ array: ele?.service, productId: ele.productId, nameTitleService : ele?.name }) : ''}
                    </li>
                  );
                })}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};

  const itemTrees = ({ array, productId, nameTitleService }) => {
    return (
      <ul>
        {array &&
          array.map((item, index) => {
            return (
              <li key={index}>
                <p>
                  {item.name}
                  <button onClick={() => toggleOpenResultSettings({ item, productId, nameTitles: `${nameTitleService||''}-${item.name||''}` })}>作業結果設定</button>
                  <button onClick={() => toggleOpenCancelSettings({ item, productId, nameTitles: `${nameTitleService||''}-${item.name||''}` })}>キャンセル結果設定</button>
                  <button onClick={() => toggleOpenAlertSettings({ item, productId, nameTitles: `${nameTitleService||''}-${item.name||''}` })}>アラート設定</button>
                </p>
              </li>
            );
          })}
      </ul>
    );
  };

  return (
    <div className="setting-master-menu-wrapper">
      <Loading loading={loading} />
      <div className="wrapper-btn-colappse">
        <div onClick={toggle} className="mb-1 searchbar d-flex justify-content-between align-items-center " style={{background :`${colors}` }}>
          <div className="ml-2">ワークフロー</div>
          <div className="mr-2" style={{ color: `${colorText}`, fontSize: '16px'}}>
            {isOpen ? <i className="icon-up-open icon-searchbar" /> : <i className="icon-down-open icon-searchbar" />}
          </div>
        </div>
      </div>
      <div>
        <Collapse isOpen={isOpen}>
          <div className="content-view">
            <div className="form-btn-modal">
              <button className="btn-tree"
                onClick={() => toggleOpenCreateProduct()}
              >工事名新規登録</button>
              <button className="btn-tree"
                onClick={() => toggleOpenCreateService('service')}
              >工程管理</button>
            </div>

            <div className="form-search">
              <div className="group-input">
                <div className="title">検索条件</div>
                <input type="text" onChange = {(e) => changeSearch(e)} onKeyDown={(e) => handleKeyDown(e)} />
              </div>
              <button className="btn-tree" onClick = {searchData}>検索</button>
            </div>
            {/* {renderTreeNone(listTreeNone)} */}
            {listTreeProject && listTreeProject.map((item, key) => renderTreeProject(item, key))}
          </div>
        </Collapse>
      </div>
      {modalCreateProject ? <ModalCreateProject modal={modalCreateProject} toggle={toggleCloseModal} createSuccess={createSuccess} idClientModal={idClientModal} nameTitle={nameTitle}/> : ''}
      {modalCreateProduct ? <ModalCreateProduct modal={modalCreateProduct} toggle={toggleCloseModal} createSuccess={createSuccess} idClientModal={idClientModal} listDataProject={listDataProject} nameTitle={nameTitle}/> : ''}
      {modalCreateService ? <ModalCreateService modal={modalCreateService} toggle={toggleCloseModal} createSuccess={createSuccess} /> : ''}
      {modalDocumentSettings ?
        <ModalDocumentSettings
          modal={modalDocumentSettings}
          toggle={toggleCloseModal}
          dataDocumentSetting={dataDocumentSetting}
          dataTreeOption={dataTreeOption}
          statusBeforeConfirm={statusBeforeConfirm}
          color={colors}
          nameTitle={nameTitle}
          /> : ''}
      {modalResultSettings ? <ModalResultSettings modal={modalResultSettings} toggle={toggleCloseModal} serviceResult={serviceResult} productResult={productResult} color={colors} nameTitle={nameTitle} /> : ''}
      {modalCancelSettings ? <ModalCancelSettings modal={modalCancelSettings} toggle={toggleCloseModal} serviceCancel={serviceCancel} productCancel={productCancel} color={colors} nameTitle={nameTitle} /> : ''}
      {modalAlertSettings ? <ModalAlertSettings modal={modalAlertSettings} toggle={toggleCloseModal} serviceAlert={serviceAlert} productAlert={productAlert} color={colors} idRole={idRole} nameTitle={nameTitle} /> : ''}
      {modalAccessUsers ? <ModalAccessUsers modal={modalAccessUsers} toggle={toggleCloseModal} productAccess={productAccess} color={colors} nameTitle={nameTitle}/> : ''}
      {modalAddProductInProject ? <ModalAddProductInProject
        modal={modalAddProductInProject}
        toggle={toggleCloseModal}
        createSuccess={createSuccess}
        listTreeNone={listTreeNone}
        projectName={projectName}
        idProjectChoose={idProjectChoose}
        idClientModal={idClientModal}
        /> : ''}

      {modalAddServiceInProduct ? <ModalAddServiceInProduct
        modal={modalAddServiceInProduct}
        toggle={toggleCloseModal}
        createSuccess={createSuccess}
        infoProductModal={infoProductModal}
        nameTitle={nameTitle}
      /> : ''}

      {modalAddRole ?
        <ModalAddRole routeList={routeList} modal={modalAddRole} toggle={toggleCloseModal}
          ModalAddRoleNext={toggleAddRoleNext} namePermission={namePermission} idProduct={productAccess}
          idProvide={idProvide} statusBeforeConfirm={statusBeforeConfirm} ModalDetailSetting={toggleSettingOrder} nameTitle={nameTitle}/>
        : ''
      }

      {modalAddRoleNext ?
        <ModalAddRoleNext arrRole={arrRole} nameScreen={nameScreen} modalNext={modalAddRoleNext} namePermission={namePermission} onBack={toggleAddRole}
          toggle={toggleCloseModal} routeList={routeList} idProvide={idProvide} idProduct={productAccess} nameTitle={nameTitle}/> : ''
      }

      {modalSettingOrder ?
        <ModalSettingOrder arrRole={arrRole} nameScreen={nameScreen} modalNext={modalSettingOrder} onBack={toggleAddRole} onDetail={toggleSettingOrderDetail}
        toggle={toggleSettingOrder} routeList={routeList} idProvide={idProvide} idProduct={productAccess} nameTitle={nameTitle}/> : ''
      }

      {modalSettingOrderDetail ?
        <ModalSettingOrderDetail arrRole={getRole({ idRole: arrRole })?.toLowerCase()} nameScreen={nameScreen} modalNext={modalSettingOrderDetail} onBack={toggleSettingOrder}
        toggle={toggleSettingOrderDetail} routeList={routeList} idProvide={idProvide} idProduct={productAccess} detailId={detailId} nameTitle={nameTitle}/> : ''
      }

    </div>
  );
};

export default CollaMasterViewTree;
