import React, { useState, useEffect } from 'react';
import DateTimePicker from 'components/DateTimePicker';
import { time, DATE_JP_FORMAT } from 'helpers/constants';
import moment from 'moment';
// import ButtonRequest from '../ButtonRequest';
import _ from 'lodash';
import { formatInputSelectDate, formatInputSelectTime } from 'helpers/helpers';
import ModalSelect from '../../../ModalSelect';
import { MASTER_SETTING, ORDER } from '../../../../constants/endpoints';
import Loading from 'components/Loading/Loading';
import { SCREEN_NAME } from '../../../../helpers/constants';
import { pushToast } from '../../../Toast';
import { getDataHistory } from 'actions/authentication';
import { useDispatch } from 'react-redux';

const BeforeConfirmVisit = ({
  data = {},
  requestConfirm,
  screenName,
  changeData,
  changeDate,
  role,
  getOrderDetail,
}) => {
  const dispatch = useDispatch();
  const [dataDetail, setDataDetail] = useState(data);
  const [visitDateTo, setVisitDateTo] = useState('');
  const [isOpenSelect, setIsOpenSelect] = useState(false);
  const [dataPartner, setDataPartner] = useState();
  const [loading, setLoading] = useState(false);

  const ChangeDateVisitDate = (date) => {
    setVisitDateTo(date ? moment(new Date(date)).format(DATE_JP_FORMAT) : '');
    changeData({
      name: 'visitDateTo',
      value: date
        ? `${moment(new Date(date)).format(DATE_JP_FORMAT)} ${formatInputSelectTime(data?.visitDateTo) || '00:00'}`
        : '',
    });
  };

  useEffect(() => {
    setDataDetail(data);
    setVisitDateTo(
      moment(`${formatInputSelectDate(data?.visitDateTo)}`, `${DATE_JP_FORMAT}`).isValid()
        ? data?.visitDateTo?.slice(-16, -6) || ''
        : ''
    );
  }, [data]);

  const confirmStatus = (key, status) => {
    if (status) {
      requestConfirm({
        orderId: dataDetail?.orderId,
        body: { screenName, statusBeforeConfirm: status, namePlugin: key },
      });
    }
  };

  const changeDateVisit = (date) => {
    let newData = { visitDate: date ? moment(new Date(date)).format(DATE_JP_FORMAT) : null };
    setVisitDateTo(date ? moment(new Date(date)).format(DATE_JP_FORMAT) : '');
    newData = {
      ...newData,
      visitDateTo: date
        ? `${moment(new Date(date)).format(DATE_JP_FORMAT)} ${formatInputSelectTime(data?.visitDateTo) || '00:00'}`
        : '',
    };
    changeDate({ data: newData });
  };

  const onChangeTime = (e) => {
    let name = e.target.name;
    if (name === 'visitDateTo') {
      changeData({ name, value: !_.isEmpty(visitDateTo) ? `${visitDateTo} ${e.target.value}` : '' });
    } else {
      changeData({ name, value: e.target.value });
    }
  };

  const getPartnerListOr = async ({ orderId = '', organizationId = '', partnerId = '' }) => {
    setLoading(true);
    try {
      const data = await MASTER_SETTING.GET_STAFF_BY_ORDER({
        orderId,
        organizationId,
        partnerId: partnerId,
      });
      if (data?.data) {
        setDataPartner(data?.data);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  useEffect(() => {
    if (data?.partnerId) {
      getPartnerListOr({ orderId: data?.orderId, organizationId: data?.organizationId, partnerId: data?.partnerId });
    }
  }, [data?.partnerId]);

  const handleModalSelect = () => {
    setIsOpenSelect(!isOpenSelect);
  };

  const toggleModalSelect = () => {
    setIsOpenSelect(!isOpenSelect);
  };

  const updateConfirmCondition = async ({ id_order = '', body = '' }) => {
    setLoading(true);
    try {
      const data = await ORDER.UPDATE_WORKER_NAME({ body, id_order, organizationId: dataDetail?.organizationId });
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
      } else {
        dispatch(getDataHistory({ orderId: id_order, screenName, organizationId: dataDetail?.organizationId }));
        getOrderDetail({ orderId: dataDetail?.orderId, organizationId: dataDetail?.organizationId });
        pushToast('success', 'Add worker success');

      }
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const updateConfirm = async (updateStaff, leader) => {
    changeData({ name: 'staffId', value: updateStaff });
    await updateConfirmCondition({
      id_order: data?.orderId,
      body: { staffId: updateStaff, screenName: SCREEN_NAME.TOMORROW_INFO, updateTitle: '作業員選択', companyName: localStorage.getItem('companyName'), leader },
    });
  };

  return (
    <div className="wrapper-comfirm-status">
      <Loading loading={loading} />
      <div className="content__wrapper">
        <div className="title-absolute">訪問日時・作業員</div>
        <div className="row col-12">
          <div className="row col-8">
            <label className="col-2 content__label">訪問日時</label>
            <div className="row col-10">
              <div className="col-4">
                {/* <DateTimePicker
                  date={dataDetail?.visitDate ? new Date(dataDetail?.visitDate) : null}
                  onChangeDate={changeDateVisit}
                /> */}
                <div className="content__date--modify">
                  {dataDetail?.visitDate ? moment(new Date(dataDetail?.visitDate)).format(DATE_JP_FORMAT) : ''}
                </div>
              </div>
              <div className="col-2">
                <select
                  name="visitDateFrom"
                  className="content__select--custom content__select--w100"
                  value={dataDetail?.visitDateFrom}
                  onChange={onChangeTime}
                >
                  {time.map((item, index) => (
                    <option value={item} key={index}>
                      {item}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-4">
                {/* <DateTimePicker
                  date={!_.isEmpty(visitDateTo) ? new Date(visitDateTo) : ''}
                  onChangeDate={ChangeDateVisitDate}
                /> */}
                <div className="content__date--modify">
                  {!_.isEmpty(visitDateTo) ? moment(new Date(visitDateTo)).format(DATE_JP_FORMAT) : ''}
                </div>
              </div>
              <div className="col-2">
                <select
                  name="visitDateTo"
                  disabled={_.isEmpty(visitDateTo)}
                  className="content__select--custom content__select--w100"
                  value={formatInputSelectTime(dataDetail?.visitDateTo)}
                  onChange={onChangeTime}
                >
                  {time.map((item, index) => (
                    <option value={item} key={index}>
                      {item}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          {/* <ButtonRequest data={dataDetail} state='statusBeforeVisitDateAndTime' confirmStatus={confirmStatus} role={role} action='visitDateAndTime' /> */}
        </div>
        <div className="row col-12 mb-2">
          <div className="row col-5">
            <label className="col-4 content__label">作業員</label>
            <div className="row col-8">
              <div className="col-4">
                <button
                  className="btn content__button--custom content__button--w100"
                  onClick={() => handleModalSelect()}
                >
                  登録
                </button>
              </div>
              <div className="col-8">
                <div className="content--border-cs content__button--pdtb5 fz-14">{dataDetail?.abbreviationName}</div>
              </div>
            </div>
          </div>
          <div className="col-7">
            <div className="row content--border-cs">
              <div className="content__button--margin4_10 w-10 fz-14">確定：{dataDetail?.workerName?.length || 0}名</div>
              {dataDetail?.workerName?.length > 0 &&
                dataDetail?.workerName?.map((item, index) => {
                  return (
                    <button
                      key={index}
                      className="content__button--w20 btn content__button--custom content__button--mg5 truncate mb-1"
                      disabled
                    >
                      {`${item?.slice(0, 10)}.`}
                    </button>
                  );
                })}
            </div>
          </div>
          {isOpenSelect && (
            <ModalSelect
              modal={isOpenSelect}
              updateConfirm={updateConfirm}
              toggle={toggleModalSelect}
              listStaff={dataPartner}
              orderId={dataDetail?.orderId}
              staffId={dataDetail?.staffId || []}
              partnerName={dataDetail?.partnerName}
              statusConditionInfo={dataDetail?.statusConditionInfo}
              visitDate={dataDetail?.visitDate}
              organizationId={data?.organizationId}
            />
          )}
          {/* <ButtonRequest data={dataDetail} state='statusBeforeOperator' confirmStatus={confirmStatus} role={role} classCustom="col-3" /> */}
        </div>
      </div>
    </div>
  );
};

export default BeforeConfirmVisit;
