import React, { useState, useEffect } from 'react';
import AutocompleteAddress from 'components/AutocompleteAddress';
import { USER_ENDPOINT } from '../../../../constants/endpoints';
import { pushToast } from '../../../../components/Toast';
import HeaderMasterSetting from '../../../../components/HeaderMasterSetting';
import { useSelector } from 'react-redux';
import { PARAMS_REQUIRED_USER, SCREEN_NAME, DEFAULT_ADDRESS, DATE_JP_FORMAT, PERMITTED_UPDATE_USER, ROLE_ENUM_SELECT, NAME_ROLE,  PERMITTED_PARAM_USER_MONITORING } from '../../../../helpers/constants';
import Loading from 'components/Loading/Loading';
import DateTimePicker from 'components/DateTimePicker';
import ModalConfirmUpdateStatusUserAndStaff from 'components/ModalConfirmUpdateStatusUserAndStaff';
import moment from 'moment';
import Select from 'react-select';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import AreaSelection from 'components/AreaSelection';
import 'assets/scss/page/center/Users/create-update-user.scss';
import {validateRequried} from 'helpers/helpers'
import { AREAS_PERMITTED } from 'helpers/areas';
import FooterMaintenance from "components/FooterMaintenance";
import MemoHistory from '../../../../components/MemoHistory';

const getQualifiedMock = {
  grade: "",
  licenseNumber: "",
  qualifications: "",
  validityPeriodFrom: "",
  validityPeriodTo: ""
}

const mockData = {
  firstNameKana: '',
  lastNameKana: '',
  firstName: '',
  lastName: '',
  phoneNumber: '',
  email: '',
  loginId: '',
  loginPassword: '',
  areas: {},
  organizationId: '',
  screenName : SCREEN_NAME.USER_MASTER,
  address: '',
  postNumberFirst: '',
  postNumberLast: '',
  addressKana: '',
  latitude: null,
  longitude: null,
  movingSpeed: null,
  distanceRange: '',
  getQualified: [{ ...getQualifiedMock }, { ...getQualifiedMock }, {...getQualifiedMock}]
};

const EditUsers = () => {
  let userId = (process.browser && new URL(window.location.href).searchParams.get('sid')) || null;
  const colorScreen = useSelector((store) => store.utils.colorMe);
  const [userData, setUserData] = useState(mockData);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  let organizationId = (process.browser && new URL(window.location.href).searchParams.get('organizationId')) || null;
  let role = (process.browser && new URL(window.location.href).searchParams.get('role')) || null;
  let permissionId = (process.browser && new URL(window.location.href).searchParams.get('permissionId')) || null;
  const [options, setOptions] = useState({primary: [], secondary: []});;
  const [defaultValues, setDefaultValues] = useState([]);
  const history = useHistory();
  const [isShowModalUpdateStatusUser, setIsShowModalUpdateStatusUser] = useState(false)
  const [isDisable, setIsDisable] = useState(false);
  const [prefectureAreas, setPrefectureAreas] = useState({});
  const [rolePrimary , setRolePrimary] = useState('');
  const prefectureNameKanji = _.groupBy(AREAS_PERMITTED, 'prefectureNameKanji');
  const idRole = localStorage.getItem('role');
  const [listBranchMaster, setListBranchMaster] = useState([]);
  const [fileName, setFileName] = useState('【stellaCloud】システム利用開始のご案内');
  const [dataConfirm, setDataConfirm] = useState({});

  const getUserInfo = async ({ userId }) => {
    try {
      setLoading(true);
      const data = await USER_ENDPOINT.GET_USER_INFO({ userId });
      if (data) {
        setData(data.data);
        if(data?.data?.partnerId || (role === NAME_ROLE.management && permissionId)) {
          getListBranchMaster({ partnerId: data?.data?.partnerId || permissionId });
        }
        const dataHandler = _.flatten(Object.values(data?.data?.areas || {})).map(e => {
          return !_.isEmpty(data?.data?.prefectureAreas[e]) ? {[e]: data?.data?.prefectureAreas[e]} : {[e]: _.compact(_.map(prefectureNameKanji[e] || [], 'cityNameKanji'))}
        });
        const newPrefectureAreas = _.isEmpty(dataHandler) ? {} : Object.assign(...dataHandler);
        setPrefectureAreas(newPrefectureAreas || {});
      }
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const onExportPdf = () => {
    const body = {
      abbreviationName: dataConfirm?.abbreviationName,
      codeConfirm: dataConfirm?.codeConfirm,
      email: dataConfirm?.email || data?.email || data?.emailTemp,
      isActive: dataConfirm?.isActive,
      isStaff: false,
      role,
      permissionId,
      idRole: data?.primaryRole?.idRole,
      userDynamo: {
        firstName: data?.firstName,
        surName: data?.firstName,
        lastName: data?.lastName,
        name: data?.lastName,
        loginId: data?.loginId,
        organizationId: data?.organizationId,
      }
    };
    exportPdfUser({ body });
  };

  const exportPdfUser = async ({ body = {}}) => {
    try {
      const data = await USER_ENDPOINT.EXPORT_USER_PDF({ body, fileName });
      if (data?.data) {
        setLoading(false);
      }else {
        setLoading(false);
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!_.isEmpty(dataConfirm)) {
      onExportPdf();
    }
  }, [dataConfirm]);

  const getConfirmCodeInfo = async ({ userId = '', staffId = '', loginId = '' }) => {
    try {
      setLoading(true);
      const data = await USER_ENDPOINT.USER_PDF({ userId, staffId });
      if (data?.data) {
        setFileName(`【stellaCloud】システム利用開始のご案内_${data?.data?.abbreviationName || ''}_${loginId}.pdf`)
        setDataConfirm(data?.data || {});
      }else {
        setLoading(false);
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const getListBranchMaster = async ({ partnerId }) => {
    try {
      setLoading(true);
      const data = await USER_ENDPOINT.GET_BRANCH_MASTER({ partnerId });
      if (data) {
        setLoading(false);
        setListBranchMaster(data?.data);
      }
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  const handleOptions = ({typeRole}) => {
    if(idRole === "1") return handleNeo({typeRole});

    switch(typeRole){
      case "2":
        return _.filter(ROLE_ENUM_SELECT, function(num) { return ["2", "3", "4", "8", "9", "10", "11", "12", "13", "14"].includes(num.value) });
      case "3":
        return _.filter(ROLE_ENUM_SELECT, function(num) { return ["3", "4", "9", "10", "12", "13", "14"].includes(num.value) });
      case "4":
        return _.filter(ROLE_ENUM_SELECT, function(num) { return ["4", "10", "13", "14"].includes(num.value) });
      case "5":
        return _.filter(ROLE_ENUM_SELECT, function(num) { return ["5", "6", "7", "8", "9", "10", "11", "12", "13", "14"].includes(num.value) });
      case "6":
        return _.filter(ROLE_ENUM_SELECT, function(num) { return ["6", "7", "9", "10", "12", "13", "14"].includes(num.value) });
      case "7":
        return _.filter(ROLE_ENUM_SELECT, function(num) { return ["7", "10", "13", "14"].includes(num.value) });
      case "8":
        return _.filter(ROLE_ENUM_SELECT, function(num) { return ["8", "9", "10", "14"].includes(num.value) });
      case "9":
        return _.filter(ROLE_ENUM_SELECT, function(num) { return ["9", "10", "14"].includes(num.value) });
      case "10":
        return _.filter(ROLE_ENUM_SELECT, function(num) { return ["10", "14"].includes(num.value) });
      case "11":
        return _.filter(ROLE_ENUM_SELECT, function(num) { return ["11", "12", "13"].includes(num.value) });
      case "12":
        return _.filter(ROLE_ENUM_SELECT, function(num) { return ["12", "13"].includes(num.value) });
      case "13":
        return _.filter(ROLE_ENUM_SELECT, function(num) { return ["13"].includes(num.value) });
      case "14":
        return _.filter(ROLE_ENUM_SELECT, function(num) { return ["14"].includes(num.value) });
      default:
        return []
    }
  }

  const handleNeo = ({typeRole}) => {
    switch (typeRole) {
      case "2":
        return _.filter(ROLE_ENUM_SELECT, function(num) { return ["2", "3", "4"].includes(num.value) });
      case "3":
        return _.filter(ROLE_ENUM_SELECT, function(num) { return ["3", "4"].includes(num.value) });
      case "4":
        return _.filter(ROLE_ENUM_SELECT, function(num) { return ["4"].includes(num.value) });
      case "5":
        return _.filter(ROLE_ENUM_SELECT, function(num) { return ["5", "6", "7", "14"].includes(num.value) });
      case "6":
        return _.filter(ROLE_ENUM_SELECT, function(num) { return ["6", "7", "14"].includes(num.value) });
      case "7":
        return _.filter(ROLE_ENUM_SELECT, function(num) { return ["7", "14"].includes(num.value) });
    }
  }

  const handlePrimaryOptions = () => {
    switch(role){
      case "ERP":
        return handleErp();
      case "ManagementGroup":
        return handleManagementGroup();
      case "Management":
        return handleManagement();
      case "Monitoring":
        return handleMonitoring();
      case "Worker":
        return _.filter(ROLE_ENUM_SELECT, function(num) { return ["14"].includes(num.value) });
      default:
        return []
    }
  };

  const handleErp = () => {
    switch(idRole){
      case "1":
      case "2":
        return _.filter(ROLE_ENUM_SELECT, function(num) {return ["2", "3", "4"].includes(num.value) });
      case "3":
        return _.filter(ROLE_ENUM_SELECT, function(num) { return ["3", "4"].includes(num.value) });
      case "4":
        return _.filter(ROLE_ENUM_SELECT, function(num) { return ["4"].includes(num.value) });
      default:
        return []
    }
  };

  const handleManagementGroup = () => {
    switch(idRole){
      case "1":
      case "5":
        return _.filter(ROLE_ENUM_SELECT, function(num) {return ["5", "6", "7"].includes(num.value) });
      case "6":
        return _.filter(ROLE_ENUM_SELECT, function(num) { return ["6", "7"].includes(num.value) });
      case "7":
        return _.filter(ROLE_ENUM_SELECT, function(num) { return ["7"].includes(num.value) });
      default:
        return []
    }
  };

  const handleManagement = () => {
    switch(idRole){
      case "2":
        return _.filter(ROLE_ENUM_SELECT, function(num) {return ["8", "9", "10", "14"].includes(num.value) });
      case "3":
        return _.filter(ROLE_ENUM_SELECT, function(num) { return ["9", "10", "14"].includes(num.value) });
      case "4":
        return _.filter(ROLE_ENUM_SELECT, function(num) { return ["10", "14"].includes(num.value) });
      case "5":
        return _.filter(ROLE_ENUM_SELECT, function(num) {return ["8", "9", "10", "14"].includes(num.value) });
      case "6":
        return _.filter(ROLE_ENUM_SELECT, function(num) { return ["9", "10", "14"].includes(num.value) });
      case "7":
        return _.filter(ROLE_ENUM_SELECT, function(num) { return ["10", "14"].includes(num.value) });
      case "8":
        return _.filter(ROLE_ENUM_SELECT, function(num) {return ["8", "9", "10", "14"].includes(num.value) });
      case "9":
        return _.filter(ROLE_ENUM_SELECT, function(num) { return ["9", "10", "14"].includes(num.value) });
      case "10":
        return _.filter(ROLE_ENUM_SELECT, function(num) { return ["10", "14"].includes(num.value) });
      default:
        return []
    }
  };

  const handleMonitoring = () => {
    switch(idRole){
      case "2":
        return _.filter(ROLE_ENUM_SELECT, function(num) {return ["11", "12", "13"].includes(num.value) });
      case "3":
        return _.filter(ROLE_ENUM_SELECT, function(num) { return ["12", "13"].includes(num.value) });
      case "4":
        return _.filter(ROLE_ENUM_SELECT, function(num) { return ["13"].includes(num.value) });
      case "5":
        return _.filter(ROLE_ENUM_SELECT, function(num) {return ["11", "12", "13"].includes(num.value) });
      case "6":
        return _.filter(ROLE_ENUM_SELECT, function(num) { return ["12", "13"].includes(num.value) });
      case "7":
        return _.filter(ROLE_ENUM_SELECT, function(num) { return ["13"].includes(num.value) });
      case "11":
        return _.filter(ROLE_ENUM_SELECT, function(num) {return ["11", "12", "13"].includes(num.value) });
      case "12":
        return _.filter(ROLE_ENUM_SELECT, function(num) { return ["12", "13"].includes(num.value) });
      case "13":
        return _.filter(ROLE_ENUM_SELECT, function(num) { return ["13"].includes(num.value) });
      default:
        return []
    }
  };

  useEffect(() => {
    if (userData) {
      validateRequried({
        data: userData,
        arrField:
          userData?.primaryRole?.idRole === '14' ?
            [...PARAMS_REQUIRED_USER, ...['movingSpeed', 'distanceRange']]
            : PARAMS_REQUIRED_USER,
        onDisabled,
        arrFieldRemove: userData?.primaryRole?.idRole === '14' ? [] : ['movingSpeed', 'distanceRange'],
      });
    }
  }, [userData]);

  const onDisabled = ({status}) => {
    setIsDisable(status);
  }

  useEffect(() => {
    if (data) {
      let dataOptions = handleOptions({typeRole: data?.primaryRole?.idRole});
      const dataPrimary = handlePrimaryOptions();
      let dataDefault = [];
      let dataRolePrimary = [];
      setOptions({primary: dataPrimary, secondary: dataOptions});
      if (!_.isEmpty(dataOptions) && !_.isEmpty(data.userRole)) {
        dataDefault = _.filter(dataOptions, function(num) {
          return !!_.find(data.organization, {idRole: num.value})
        });
        setDefaultValues(dataDefault);
      };
      if (!_.isEmpty(dataPrimary) && !_.isEmpty(data.primaryRole)) {
        dataRolePrimary = _.filter(dataPrimary, { 'value' : data?.primaryRole?.idRole})
        setRolePrimary(dataRolePrimary)
      }
      setUserData({
        ...userData,
        primaryRole : data?.primaryRole || '',
        firstNameKana: data?.firstNameKana || '',
        lastNameKana: data?.lastNameKana || '',
        firstName: data?.firstName || '',
        lastName: data?.lastName || '',
        email: data?.email || '',
        loginId: data?.loginId || '',
        passwordExpirationDate: data?.passwordExpirationDate || null,
        phoneNumber: data?.phoneNumber || '',
        movingSpeed: (data?.movingSpeedTemp) ? data?.movingSpeedTemp : data?.movingSpeed ? data?.movingSpeed : data?.movableDistance?.distance || null,
        movableDistance: (data?.movableDistanceTemp) ? data?.movableDistanceTemp : data?.movableDistance,
        distanceRange: (data?.distanceRangeTemp) ? data?.distanceRangeTemp : data?.distanceRange,
        address: (data?.addressTemp) ? data?.addressTemp : data?.address,
        areas: (data?.areasTemp) ? data?.areasTemp || {} : data?.areas || {},
        prefectures: (data?.prefecturesTemp) ? data?.prefecturesTemp : data?.prefectures || '',
        addressKana: (data?.addressKanaTemp) ? data?.addressKanaTemp:data?.addressKana,
        latitude: (data?.latitudeTemp) ? data?.latitudeTemp : data?.latitude,
        longitude: (data?.longitudeTemp) ? data?.longitudeTemp : data?.longitude,
        postalCode: (data?.postalCodeTemp) ? data?.postalCodeTemp : data?.postalCode,

        organizationId: (data?.organizationId || organizationId) || null,
        roles : _.map(dataDefault, function(num) { return num.value }) || [],
        permissionId,
        postNumberFirst: (data?.postalCodeTemp || data?.postalCode)?.split('-')[0] || '',
        postNumberLast: (data?.postalCodeTemp || data?.postalCode)?.split('-')[1] || '',
        partnerId : data?.partnerId || '',
        branchId : data?.branchId || '',
        isLock : false,
        getQualified: data?.getQualified || [{ ...getQualifiedMock }, { ...getQualifiedMock }, {...getQualifiedMock}],
        updateTitle: `更新 ${titleName()} ${userData?.loginId?.trim()}`
      });
    }
  }, [data]);

  useEffect(() => {
    if (userId) {
      getUserInfo({ userId });
    }
  }, [userId]);

  useEffect(() => {
    if(userData.primaryRole) {
      setOptions({...options, secondary: handleOptions({typeRole: userData?.primaryRole?.idRole})});
    }
  }, [userData.primaryRole]);

  const updateUserInfo = async ({ body = {}, userId = '' }) => {
    try {
      setLoading(true);
      if(_.isEmpty(body?.partnerId)) {
        delete body.partnerId;
      }
      body.postalCode = `${userData?.postNumberFirst}-${userData?.postNumberLast}`
      const newBody = {...body, prefectureAreas};
      const data = await USER_ENDPOINT.UPDATE_USER_INFO({ body: newBody, userId });
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
        setLoading(false);
      } else {
        setLoading(false);
        pushToast('success', 'Update info success');
        history.push(`/users`);
      }
    } catch (errorMessage) {
      setLoading(false);
      console.log('errorMessage: ', errorMessage);
    }
  };

  const titleName = () => {
    switch (role) {
      case NAME_ROLE.monitoring:
        return '発注企業利用者編集';
      case NAME_ROLE.rep:
        return '契約企業利用者編集';
      case NAME_ROLE.managementGroup:
        return 'ManagementGroup_User_編集';
      default:
        return '協力企業利用者編集';
    };
  }

  const onUpdate = () => {
    if (userData && userId) {
      let body = {};
      if (role === NAME_ROLE.monitoring) {
        body = _.pick(userData,  PERMITTED_PARAM_USER_MONITORING);
        updateUserInfo({ body: {...body, primaryKeyType: role}, userId });
      } if (role === NAME_ROLE.management) {
        body = _.pick(userData,  [...PERMITTED_UPDATE_USER , 'getQualified', 'branchId']);
        updateUserInfo({ body: {...body, primaryKeyType: role}, userId });
      }
      else {
        body = _.pick(userData,  PERMITTED_UPDATE_USER);
        updateUserInfo({ body: {...body, primaryKeyType: role}, userId });
      }
    }
  };

  const onChangeText = (e) => {
    const name = e.target.name;
    if(userData?.haveToEmail && name === 'email') return;

    const value = e.target.value;
    const newData = {...userData, [name]: value};
    setUserData(newData);
  };

  const onChangeDate = (date) => {
    setUserData({ ...userData, passwordExpirationDate: date === null ? '' : moment(new Date(date)).format(DATE_JP_FORMAT) });
  };

  const handleChange = (e) => {
    setDefaultValues(e || []);
    const roles = e?.map(i => i['value']) || [];
    let newUserData = { ...userData };
    newUserData = { ...newUserData, roles: roles };
    setUserData(newUserData);
  };

  const handleChangeCheckBox = (e) => {
    const name = e.target.name;
    setUserData({ ...userData, [name]: e.target.checked});
  }

  const resendConfirmationCode = async () => {
    try {
      setLoading(true);
      const body = { screenName: SCREEN_NAME.USER_MASTER }
      const data = await USER_ENDPOINT.REGISTER_USER_COGNITO({ body, userId });
      if (!data.data) {
        pushToast('error', data?.message?.errorMessage || data?.message);
        setLoading(false);
      } else {
        pushToast('success', 'Resend confirmation success');
        setLoading(false);
      }
    } catch (errorMessage) {
      console.log('errorMessage: ', errorMessage);
      setLoading(false);
    }
  }

  const onResend = () => {
    resendConfirmationCode();
  }

  const handleUpdateStatusUser = async ({ onUpdateSuccess, status }) => {
    try {
      const data = await USER_ENDPOINT.UPDATE_STATUS({
        body: { usageStatus: status, screenName: SCREEN_NAME.USER_MASTER, updateTitle: `利用停止 ${userData?.loginId || ''}` },
        userId
      });
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
      } else {
        if (status === 2) {
          return history.push(`/users`)
        }
        pushToast('success', 'Update status success');
        getUserInfo({ userId });
        onUpdateSuccess()
      }
    } catch (errorMessage) {
      console.log('errorMessage: ', errorMessage);
    }
  }


  const onChangePrefectureAreas = (data) => {
    setPrefectureAreas(data);
  }

  const onChangeAreas = (areas) => {
    const newData = { ...userData, areas };
    setUserData(newData);
    const values = _.flatten(Object.values(newData?.areas || {}));
    const dataHandler = values.map(e => {
      return Object.keys(prefectureAreas || {}).includes(e) ? {[e]: prefectureAreas[e]} : {[e]: _.compact(_.map(prefectureNameKanji[e] || [], 'cityNameKanji'))}
    })
    const newPrefectureAreas = _.isEmpty(dataHandler) ? {} : Object.assign(...dataHandler);
    setPrefectureAreas(newPrefectureAreas);
  }

  const handleRolePrimary = (e) => {
    const roles = e?.value ;
    setRolePrimary(e);
    setDefaultValues([e]);
    let newUserData = { ...userData };
    newUserData = { ...newUserData, roles: [roles],  primaryRole: { idOrganization :  userData?.organizationId, idRole: !_.isEmpty(roles) ? roles : '' } };
    setUserData(newUserData);
  };

  const handleChangeQualificationText = ({ e, item, index, name, value }) => {
    const fieldName = (e && e.target.name) || name
    item[fieldName] = (e && e.target.value) || value
    const getQualified = userData?.getQualified
    getQualified[index] = item
    setUserData({ ...userData, getQualified })
  };

  const handleRemote  = (index) => {
    const getQualified = [...userData?.getQualified];
    getQualified.splice(index, 1);
    setUserData({ ...userData, getQualified });
  }

  return (
    <div className="main-branch-master user-master-form-create-update-screen">
      <Loading loading={loading} />
      <HeaderMasterSetting color={colorScreen?.code} colorText={colorScreen?.colorText} nameRole={role}/>
      <div className="body-wrapper mb-1">
        <div align="center" style={{ border: `3px solid ${colorScreen?.code}`}} className="table__user">
          <div className="grid--pd15" >
            <table className="user-master-table">
              <tbody>
              <tr>
                <th>利用者ID</th>
                <td>
                  <input name="loginId" className={`${userData?.loginId === '' ? 'input-err' : ''}`} value={userData?.loginId} disabled />
                </td>
                <th>パスワード有効期限日</th>
                <td>
                  <DateTimePicker
                    isRequired={true}
                    id="passwordExpirationDate"
                    name="passwordExpirationDate"
                    onChangeDate={onChangeDate}
                    date={userData?.passwordExpirationDate ? new Date(userData?.passwordExpirationDate) : null }
                  />
                </td>
              </tr>
              {
                ((role === NAME_ROLE.management && permissionId)) && (
                  <tr>
                    <th>拠点名</th>
                    <td>
                      <select
                        name="branchId"
                        onChange={onChangeText}
                        value={userData?.branchId}
                        id="branchId"
                      >
                        <option value="" selected>----</option>
                        {
                          listBranchMaster ?.map((item, index) => {
                            return (
                              <option value={item.branchId} key={index}>{item.branchName}</option>
                            )
                          })
                        }
                      </select>
                    </td>
                  </tr>
                )
              }
              <tr className="group-name-input">
                <th>利用者名</th>
                <td>
                  <div className="inline">
                    <label>姓</label>
                    <input className={`${userData?.firstName === '' ? 'input-err' : ''}`} name="firstName" value={userData?.firstName} onChange={onChangeText} />
                  </div>
                </td>
                <td>
                  <div className="inline">
                    <label>名</label>
                    <input className={`${userData?.lastName === '' ? 'input-err' : ''}`} name="lastName" value={userData?.lastName} onChange={onChangeText} />
                  </div>
                </td>
              </tr>

              <tr className="group-name-input">
                <th>フリガナ</th>
                <td>
                  <div className="inline">
                    <label>セイ</label>
                    <input className={`${userData?.firstNameKana === '' ? 'input-err' : ''}`} name="firstNameKana" value={userData?.firstNameKana} onChange={onChangeText} />
                  </div>
                </td>
                <td>
                  <div className="inline">
                    <label>メイ</label>
                    <input className={`${userData?.lastNameKana === '' ? 'input-err' : ''}`} name="lastNameKana" value={userData?.lastNameKana} onChange={onChangeText} />
                  </div>
                </td>
              </tr>

              <tr>
                <th>携帯電話番号</th>
                <td>
                  <input name="phoneNumber" value={userData?.phoneNumber} onChange={onChangeText} />
                </td>
                <td colSpan="2"></td>
              </tr>
              <tr>
                <th>メールアドレス</th>
                <td colSpan="3">
                  <div className="group-email-input">
                    <div className="input__box">
                      <label className="email-label input-email-checkbox--isLock" style={{ flex: 8 }}>メールアドレス編集</label>
                      <input checked={userData?.isLock} name="isLock" className="input-email-checkbox input-email-checkbox--isLock" style={{ flex: 2 }} type="checkbox" onChange={handleChangeCheckBox} />
                    </div>
                    <div style={{ display: 'block' }}>
                      <input disabled={!userData?.isLock} name="email" className={`input__email--w90 ${userData?.email === '' ? 'input-err' : ''}`} value={userData?.email} onChange={onChangeText} />
                      <p className="small-text">
                      メールアドレスを設定すると、ログインIDとして利用できます。<br />
                      送信ボタンでアカウント情報の通知をお願いします。<br />
                      メールアドレスを登録しない方は、PDF出力からアカウント情報を取得してください。
                      </p>
                    </div>
                    <div>
                      <button className="btn btn-resend-confirm-code" onClick={onResend}>送信</button>
                      <button className="btn btn-resend-confirm-code btn-pdf-125" onClick={() => getConfirmCodeInfo({ userId, loginId: data?.loginId })}>PDF出力</button>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <th>権限選択</th>
                <td colSpan="3" className="">
                  <Select
                    value={rolePrimary}
                    options={options?.primary}
                    name="primaryRole"
                    id="primaryRole"
                    className="basic-multi-select mb-1"
                    classNamePrefix="select"
                    onChange={(e) => handleRolePrimary(e)}
                  />
                  <Select
                    value={defaultValues}
                    isMulti
                    name="roles"
                    id="roles"
                    options={options?.secondary}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={(e) => handleChange(e)}
                  />
                </td>
              </tr>
              {role === NAME_ROLE.monitoring ? null : (
                <>
                  <tr>
                    <th>郵便番号</th>
                    <td className="inline">
                      <input
                        type="number"
                        value={userData?.postNumberFirst}
                        onChange={onChangeText}
                        name="postNumberFirst"
                      />
                      <input
                        type="number"
                        value={userData?.postNumberLast}
                        onChange={onChangeText}
                        name="postNumberLast"
                      />
                    </td>
                    <th>都道府県</th>
                    <td>
                      <select
                        name="prefectures"
                        value={userData?.prefectures}
                        onChange={onChangeText}
                      >
                        {['', ...DEFAULT_ADDRESS].map((item, index) => {
                          return (
                            <option value={item} key={index}>
                              {item}
                            </option>
                          );
                        })}
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <th>住所</th>
                    <td colSpan="3">
                      <AutocompleteAddress
                        name="address"
                        address={userData?.address}
                        onChange={(address) => { setUserData({ ...userData, address }) }}
                        onSelectAddress={({ address, latitude, longitude }) => { setUserData({ ...userData, address, latitude, longitude }) }}
                      />
                    </td>
                  </tr>

                  <tr>
                    <th>フリガナ</th>
                    <td colSpan="3">
                      <input name="addressKana" value={userData?.addressKana} onChange={onChangeText} />
                    </td>
                  </tr>

                  <tr>
                    <th>緯度</th>
                    <td>
                      <input
                        value={userData?.latitude}
                        onChange={onChangeText}
                        name="latitude"
                        id="latitude"
                      />
                    </td>
                    <th>経度</th>
                    <td>
                      <input
                        value={userData?.longitude}
                        onChange={onChangeText}
                        name="longitude"
                        id="longitude"
                      />
                    </td>
                  </tr>

                  <tr>
                    <th>移動速度</th>
                    <td className="inline">
                      <label>1時間</label>
                      <input
                      value={userData?.movableDistance?.distance}
                      onChange={(e) => {
                        const distance = e.target.value?.toString();
                        setUserData({
                          ...userData,
                          movingSpeed: distance,
                          movableDistance: {
                            distance,
                            movingDistance: '60'
                          }
                        })
                      }}
                      type="number"
                    />
                      <label>Km</label>
                    </td>
                    <th>移動距離範囲</th>
                    <td className="inline">
                      <input type="number" id='distanceRange' name="distanceRange" value={userData?.distanceRange} onChange={onChangeText} />
                      <label>Km</label>
                    </td>
                  </tr>

                  <tr>
                    <th>対応エリア設定</th>
                    <td colSpan="3">
                      <AreaSelection
                        activeArea={{ ...userData?.areas }}
                        prefectureAreas={prefectureAreas}
                        onChangePrefectureAreas={onChangePrefectureAreas}
                        onChange={(areas) => { onChangeAreas(areas) }}
                      />
                    </td>
                  </tr>
                </>
              )}
              </tbody>
            </table>

            <div className="group-action-user">
              <div className="inline-action">
                <button
                  className="btn btn-submit-user"
                  onClick={() => { history.push(`/center_staff_master/select-table-organization?userId=${userId}`) }}
                >
                  参照権限設定
                </button>
                <button className="btn btn-submit-user" disabled={isDisable} onClick={onUpdate} > 保存 </button>
              </div>
              <button className="btn btn-submit-user" onClick={() => setIsShowModalUpdateStatusUser(true)} > 停止／再開／削除 </button>
              <ModalConfirmUpdateStatusUserAndStaff
                isOpen={isShowModalUpdateStatusUser}
                handleUpdateStatus={handleUpdateStatusUser}
                status={data?.usageStatus}
                toggle={() => setIsShowModalUpdateStatusUser(false)}
              />
            </div>
          </div>
        </div>
      </div>
      <MemoHistory colors={colorScreen?.code} colorText={colorScreen?.colorText} screenName={SCREEN_NAME.USER_MASTER} logId={userId} />
      <FooterMaintenance />
    </div>
  );
};

export default EditUsers;
