import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';

const ButtonTrueFalse = ({ status = true, onChange = () => { }, }) => {
    const [active, setActive] = useState(status);

    const onClickButon = () => {
        onChange({ status: !active });
        setActive(!active);
    };
    return (
        <div className="group-button-wrapper">
            <div className="btn-group btn-group-toggle" data-toggle="buttons">
                <label className={`fz-14 btn process-button ${active ? 'btn-primary' : 'btn-light'}`} onClick={() => onClickButon()}>
                  必要
                </label>
                <label className={`fz-14 btn process-button ${active ? 'btn-light' : 'btn-primary'}`} onClick={() => onClickButon()}>
                    不要
                </label>
            </div>
        </div>
    );
};

export default compose(withRouter)(ButtonTrueFalse);
