import { IconButton, InputAdornment, OutlinedInput } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import 'assets/scss/page/Login/style.scss';
import Toast from 'components/Toast';
import React, { useEffect, useState } from 'react';
import { translate } from 'react-i18next';
import { connect, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { login } from '../../../actions/authentication';
import LoginIcon from './loginIcon';
import _ from 'lodash';
import { MASTER_SETTING } from '../../../constants/endpoints';
import LoadingBtn from './LoadingBtn';
import Loading from '../../../components/Loading/Loading';

const Login = (props) => {
  const { login, history } = props;
  const [showPassword, setShowPassword] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [usernameBlur, setUsernameBlur] = useState(false);
  const [passwordBlur, setPasswordBlur] = useState(false);
  const [htmlString, setHtmlString] = useState("");
  const loadingUI = useSelector((store) => store.utils.loading);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress, false);
    return () => {
      document.removeEventListener('keydown', handleKeyPress, false);
    }
  }, [username, password]);

  const handleLogin = () => {
      login({ username, password, history });
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleLogin();
    }
  };

  const getUiLogin = async () => {
    setLoading(true);
    try {
      const data = await MASTER_SETTING.GET_UI_LOGIN();
      if (data) {
        setHtmlString(data?.data?.loginHtml);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  useEffect(() => {
    getUiLogin();
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    if (username && password) {
      login({ username, password, history });
    }
  };
  

  return (
    <div className='loginPage'>
      {!_.isEmpty(htmlString) ? (
        <div dangerouslySetInnerHTML={{ __html: htmlString }} />
      ): (
        <>
          <Loading loading={loading || loadingUI} />
          <Toast />
          <div className="loginContainer">
            <img src="/img/login.png" alt="Description of image" className="img-fluid mt-3" />
              <div className={`row justify-content-center loginFormContainer`}>
                <form className="w-full" onSubmit={onSubmit} >
                  <div className="col-lg-12 p-0">
                    <div className="inputField">
                      <div className="loginLogo"><LoginIcon width={220} height={28} /></div>
                        <div >
                          <label className="mb-2" htmlFor="email">利用者ID</label>
                          <TextField
                            variant="outlined"
                            error={!username && usernameBlur}
                            id="username"
                            placeholder="ユーザーIDを入力"
                            className="w-full input-username"
                            onChange={(e) => setUsername(e.target.value)}
                            onBlur={() => setUsernameBlur(true)}
                          />
                          {!username && usernameBlur && <span className="errorMessage">ユーザーIDを入力してください</span>}
                        </div>
                        <div className="relative">
                          <label className="mb-2" htmlFor="password">パスワード</label>
                          <OutlinedInput
                            id="outlined-adornment-password"
                            error={!password && passwordBlur}
                            type={showPassword ? "text" : "password"}
                            placeholder="パスワードを入力"
                            className="w-full input-password"
                            onChange={(e) => setPassword(e.target.value)}
                            onBlur={() => setPasswordBlur(true)}
                            endAdornment={
                              <InputAdornment position="start">
                                <IconButton
                                  onClick={() => setShowPassword(cur => !cur)}
                                  onMouseDown={(e) => e.preventDefault()}
                                  edge="start"
                                  className='show-hide-pass'
                                >
                                  {showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                              </InputAdornment>
                            }
                          />
                          {!password && passwordBlur && <span className="errorMessage">パスワードを入力してください</span>}
                        </div>
                        {!loading && !!error && <span className="errorMessage">{error}</span>}
                        { loading && <div className="flex justify-center"><LoadingBtn /></div> }
                        {
                          !loading ? (<button type="submit" className={` loginSubmitBtn justify-center`} disabled={!username || !password}>ログイン</button>) : null
                        }
                    </div>
                  </div>
                </form>
              </div>
            <div className="row justify-content-center absolute bottom-0">
              <div className="col col-md-6 col-lg-12 flex justify-center items-center">
                <span className="copyRightTextSymbol">©︎</span>
                <span className={`ml-2 copyRightText login_copyRightText`}>NEOMARS CO.,LTD.All Rights Reserved.</span>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const mapActionToProps = {
  login,
};

export default compose(translate('translations'), withRouter, connect(null, mapActionToProps))(Login);
