import React, { useState, useEffect } from 'react';
import { Collapse } from 'reactstrap';
import TableDetailStaffSkill from '../TableDetailStaffSkill';
import PaginationCustom from 'components/PaginationCustom';
import { DISPATCH } from 'constants/endpoints';
import Loading from 'components/Loading/Loading';
import _ from 'lodash';

const StaffSkill = (props) => {
  let {
    item,
    t,
    index,
    p,
    color,
    role,
    roleDetail,
    idRole,
    handleHide,
    hideshow,
    colorText,
    reload,
  } = props;
  const [idStaff, setIdStaff] = useState();
  const [hideCollap, setHideCollap] = useState('');
  const [activeProductPages, setActiveProductPages] = useState(1);
  const [listProduct, setListProduct] = useState([]);
  const [loading, setLoading] = useState(false);

  const toggleHide = (id) => {
    handleHide(id);
    setIdStaff(id);
    if (hideshow !== id) {
      getProductDispatch({ page: activeProductPages });
    }
  };

  const handleCollap = (id) => {
    if (hideCollap === id) {
      setHideCollap('');
    } else {
      setHideCollap(id);
    }
  };

  const handleProductPage = (pageNumber) => {
    setActiveProductPages(pageNumber);
  };

  useEffect(() => {
    if (hideshow === item.staffId) {
      getProductDispatch({ page: activeProductPages });
    }
  }, [activeProductPages]);

  const getProductDispatch = async ({ page = 1, limit = 10 }) => {
    try {
      setLoading(true);
      const data = await DISPATCH.GET_PRODUCT_DISPATCH({ page, limit });
      if (data) {
        setListProduct(data?.data);
        setLoading(false);
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  return (
    <>
      <Loading loading={loading} />
      <div className="wrapper-btn-colappse" onClick={() => toggleHide(item.staffId)}>
        <div
          data-id={index}
          className="mb-1 searchbar d-flex justify-content-between align-items-center "
          style={{ background: `${color}` }}
        >
          <div className="ml-2" style={{ color: `${colorText}`, fontSize: '16px'}}>
            {item?.surName} {item?.name}
          </div>
          <div className="mr-2 d-flex" style={{ color: `${colorText}` }}>
            {hideshow === item.staffId ? (
              <i className="icon-up-open icon-searchbar" />
            ) : (
              <i className="icon-down-open icon-searchbar" />
            )}
          </div>
        </div>
      </div>
      {
        (hideshow === item.staffId) &&
        <Collapse isOpen={hideshow === item.staffId} className="collap--style">
          {
            !_.isEmpty(listProduct) && listProduct.products?.map((it, index) => {
              return (<TableDetailStaffSkill
                listStaffServices={item?.staffServices}
                staffId={idStaff}
                p={p}
                t={t}
                color={color}
                colorText={colorText}
                roleDetail={roleDetail}
                idRole={idRole}
                role={role}
                handleCollap={handleCollap}
                hideCollap={hideCollap}
                it={it}
                key={index}
                reload={() => reload()}
              />)
            })
          }
          {!_.isEmpty(listProduct) && listProduct?.count > 10 && (
            <PaginationCustom
              activePage={activeProductPages}
              itemsCountPerPage={10}
              totalItemsCount={listProduct?.count}
              pageRangeDisplayed={5}
              handlePageChange={handleProductPage}
            />
          )}

        </Collapse>
      }
    </>
  );
};

export default StaffSkill;
