import React, { useState, useEffect } from 'react';
import { SCREEN_NAME, ROLE } from '../../../../../helpers/constants';
import { getRole } from '../../../../../helpers/helpers';

const AboutUs = ({ dataService, onHandleSubmit }) => {
  const organizationId = (process.browser && new URL(window.location.href).searchParams.get('organizationId')) || '';
  const idProvider = localStorage.getItem('idProvide');
  const [dataServiceContract, setDataServiceContract] = useState([]);
  const checkRole = getRole({ idRole: localStorage.getItem('role') });
  const updateTitle = '保存 会社情報'
  useEffect(() => {
    if (dataService) {
      setDataServiceContract(dataService?.serviceByProduct);
    }
  }, [dataService]);
  const handleCheckbox = (e, indexs) => {
    const dataCheckbox = dataServiceContract?.map((item, index) => {
      if (indexs === index) {
        return { ...item, status: e.target.checked };
      } else {
        return item;
      }
    });
    setDataServiceContract(dataCheckbox);
  };

  const onCreateAndUpdate = (textSubmit, updateTitle) => {
    onHandleSubmit({
      services: dataServiceContract,
      screenName: SCREEN_NAME.CONTRACT_MANAGEMENT,
    }, textSubmit, updateTitle);
  };

  return (
    <div className="detail-registration-contract">
      <div className="detail-basic-table-wrapper">
        <div className="title-absolute">会社情報</div>
        <div className="row">
          {/* <div className="col-3 text-center">
            <label className="label-contract">PartnerID</label>
          </div>
          <div className="col-3">
            <input type="text" disabled className="input-contract" name="state" value={dataService?.partnerId} />
          </div> */}
          <div className="col-3 text-center">
            <label className="label-contract">協力企業名</label>
          </div>
          <div className="col-3">
            <input type="text" disabled className="input-contract" name="state" value={dataService?.partnerName} />
          </div>
          <div className="col-12">
            <label className="label-contract">Service</label>
          </div>
          <div className="col-12 mb-1 d-contents">
            {dataServiceContract?.map((ele, eleI) => {
              return (
                <div key={eleI} className="px-3">
                  <input
                    id={`check-${eleI}`}
                    type="checkbox"
                    checked={ele?.status}
                    disabled={checkRole === ROLE.PARTNER || idProvider !== organizationId}
                    onChange={(e) => handleCheckbox(e, eleI)}
                  />
                  <label htmlFor={`check-${eleI}`} className="mb-1 cursor-pointer">
                    {ele?.serviceName}
                  </label>
                </div>
              );
            })}
          </div>
        </div>
        {((idProvider === organizationId || (idProvider !== organizationId && !dataService?.isChildCompany)) && checkRole !== ROLE.PARTNER) && (
          <div className="justify-content-between align-items-center d-flex mb-2">
            <div></div>
            <div>
              <button className="button-submit-contract" onClick={() => onCreateAndUpdate('serviceContract', updateTitle)}>
              保存
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AboutUs;
