import React, { useEffect, useState } from 'react';
import { PRODUCT_ENDPOINT, PRODUCT_SERVICE, USER_ENDPOINT, ACCESS_SETTING } from '../../../../constants/endpoints';
import { pushToast } from '../../../../components/Toast';
import './style.scss';
import _ from 'lodash';
import { SCREEN_NAME } from '../../../../helpers/constants';
import Loading from 'components/Loading/Loading';
import { getDataHistoryMasterSetting } from 'actions/authentication';
import { useDispatch } from 'react-redux';

const TableListDetailStaff = ({ t, role, p, color, roleDetail, idStaff }) => {
  const dispatch = useDispatch();
  let userId = process.browser && new URL(window.location.href).searchParams.get('userId');
  const [loading, setLoading] = useState(false);
  const [listAccessData, setListAccessData] = useState([]);

  const getListAccessSetting = async (userId) => {
    setLoading(true);
    try {
      const data = await ACCESS_SETTING.GET_LIST_ACCESS_SETTING({
        userId,
      });
      if (data) {
        setListAccessData(data?.data);
        setLoading(false);
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  useEffect(() => {
    getListAccessSetting(userId);
  }, []);

  const checkAllAccessList = (e) => {
    let newListAccessData = [...listAccessData];
    newListAccessData.map((item) => (item.used = e.target.checked));
    setListAccessData(newListAccessData);
  };

  const checkOneAccess = (e, index) => {
    let newListAccessData = [...listAccessData];
    let data = { ...listAccessData[index], used: e.target.checked ? true : false };
    newListAccessData[index] = data;
    setListAccessData(newListAccessData);
  };

  const updateAccessList = async () => {
    let usedAccessList = listAccessData?.filter((item) => item.used === true);
    usedAccessList = usedAccessList?.map((item) =>
      _.pick(item, ['idProject', 'name', 'productId', 'projectName', 'used'])
    );

    const body = {
      providerSchema: usedAccessList,
      screenName: SCREEN_NAME.USER_MASTER,
      updateTitle: '保存 ユーザー',
    };
    try {
      setLoading(true);
      const data = await ACCESS_SETTING.UPDATE_ACCESS_SETTING({ body, userId });
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
        setLoading(false);
      } else {
        getListAccessSetting(userId);
        pushToast('success', t(`Update Access Setting Successful`));
        dispatch(getDataHistoryMasterSetting({ logId: '', screenName:SCREEN_NAME.USER_MASTER , idProvide: '' }));
        setLoading(false);
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const renderListDetailStaff = (item, index) => {
    return (
      <tr key={index}>
        <td>
          <input type="checkbox" checked={item?.used} onChange={(e) => checkOneAccess(e, index)} />
        </td>
        <td>{item?.abbreviationName}</td>
        <td>{item?.name}</td>
        <td>{item?.projectName}</td>
      </tr>
    );
  };

  return (
    <>
      <Loading loading={loading} />
      <div className="d-flex flex-column p-2 mb-3 table-container">
        <div className="d-flex flex-column justify-content-between mb-3 header-container">
          <h2 className="py-1 px-2 fz-16">工事名一覧</h2>
        </div>
        <div>
          <table className="text-center scroll">
            <thead>
              <tr>
                <th >
                  <input
                    className="check-all"
                    type="checkbox"
                    checked={listAccessData?.every((item) => item.used === true)}
                    onChange={(e) => checkAllAccessList(e)}
                  />{' '}
                  All
                </th>
                <th className='fz-14'>発注企業名</th>
                <th className='fz-14'>工事名</th>
                <th className='fz-14'>プロジェクト名</th>
              </tr>
            </thead>
            <tbody>{listAccessData?.map((item, index) => renderListDetailStaff(item, index))}</tbody>
          </table>
        </div>

        <button className="align-self-end mt-3" onClick={updateAccessList}>
          保存
        </button>
      </div>
    </>
  );
};

export default TableListDetailStaff;
