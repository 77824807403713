import React, { useState } from 'react';
import { Collapse } from 'reactstrap';
import TableListDetailStaff from './TableListDetailStaff';

const CollapseTableStaff = (props) => {
  let { item, t, index, p, color, role, roleDetail, idRole, handleHide, hideshow } = props;
  const [idStaff, setIdStaff] = useState();

  const toggleHide = (id) => {
    handleHide(id);
    setIdStaff(id);
  };
  return (
    <>
      <div className="wrapper-btn-colappse" onClick={() => toggleHide(item.staffId)}>
        <div
          data-id={index}
          className="mb-1 searchbar d-flex justify-content-between align-items-center "
          style={{ background: `${color}` }}
        >
          <div className="ml-2" style={{ color: '#555555', fontSize: '16px' }}>
            {item.name}
            {item.nameKana}
          </div>
          <div className="mr-2 d-flex" style={{ color: '#555555', fontSize: '16px'}}>
            {hideshow === item.staffId ? (
              <i className="icon-up-open icon-searchbar" />
            ) : (
              <i className="icon-down-open icon-searchbar" />
            )}
          </div>
        </div>
      </div>
      <Collapse isOpen={hideshow === item.staffId ? false : true}>
        <TableListDetailStaff
          idStaff={idStaff}
          p={p}
          t={t}
          color={color}
          roleDetail={roleDetail}
          idRole={idRole}
          role={role}
        />
      </Collapse>
    </>
  );
};

export default CollapseTableStaff;
