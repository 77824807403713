import { Collapse } from 'reactstrap';
import React, { useEffect, useState } from 'react';
import { ROLE, SCREEN_NAME } from 'helpers/constants';
import { getRoleNew } from 'helpers/helpers';
import './style.scss';
import { getRole } from '../../helpers/helpers';

const MattersToBeSent = ({ onChange, dataOrder, idRole, screenName }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [nameRole, setNameRole] = useState('');

  useEffect(() => {
    const role  = getRole({ idRole });
    setNameRole(role);
  }, [idRole]);

  return (
    <div className="detail-data-wrapper matters-submit">
      <div className="detail-cancel-table-wrapper">
        <div className="title-absolute" onClick={() => setIsOpen(!isOpen)}>
          申し送り事項
          {isOpen ? <i className="fas fa-arrow-circle-up ml-2"></i> : <i className="fas fa-arrow-circle-down ml-2"></i>}
        </div>
        <Collapse isOpen={isOpen}>
          <div className="inline">
            {screenName !== SCREEN_NAME.RECEIVE_CONFIRM_LIST && (
              <div className="matters-submit__item">
                <div className="fz-14 label">
                  <span> 発注企業 </span>
                  <span> 申し送り事項 </span>
                </div>
                <textarea
                  name="notificationItems"
                  onChange={onChange}
                  value={dataOrder ?.notificationItems}
                  disabled={getRoleNew({ idRole }) !== ROLE.CLIENT}
                  className={`${getRoleNew({ idRole }) !== ROLE.CLIENT && 'disabled-textarea'}`}
                ></textarea>
              </div>
            )}
            <div className="matters-submit__item">
              <div className="fz-14 label">
                <span>{nameRole ===  ROLE.PARTNERGROUP ? 'ManagementGroup' : '契約企業'}</span>
                <span> 申し送り事項 </span>
              </div>
              <textarea
                name="erpMattersToBeSent"
                onChange={onChange}
                value={dataOrder ?.erpMattersToBeSent}
                rows='4'
                disabled={![ROLE.CENTER, ROLE.PARTNERGROUP].includes(getRoleNew({ idRole }))}
                className={`${![ROLE.CENTER, ROLE.PARTNERGROUP].includes(getRoleNew({ idRole })) && 'disabled-textarea'}`}
              ></textarea>
            </div>
            {screenName !== SCREEN_NAME.RECEIVE_INFO && (
              <div className="matters-submit__item">
                <div className="fz-14 label">
                  <span> Management </span>
                  <span> 申し送り事項 </span>
                </div>
                <textarea
                  name="managementMattersToBeSent"
                  onChange={onChange}
                  rows='4'
                  value={dataOrder ?.managementMattersToBeSent}
                  disabled={![ROLE.PARTNER].includes(getRoleNew({ idRole }))}
                  className={`${![ROLE.PARTNER].includes(getRoleNew({ idRole })) && 'disabled-textarea'}`}
                ></textarea>
              </div>
            )}
          </div>
        </Collapse>
      </div>
    </div>
  )
}

export default MattersToBeSent
