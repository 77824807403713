import React, { useState, useEffect } from 'react';
import 'assets/scss/components/TableList/style.scss';
import {
  HEADER_MENU,
  ROLE,
  SCREEN_NAME,
  STATUS_CONFIRM_PARTNER,
  STATUS_CONDITION_CSV,
  STATUS_ORDER_CONDITION,
} from '../../helpers/constants';
import { Link } from 'react-router-dom';
import { ORDER, STAFF_ENDPOINT } from '../../constants/endpoints';
import { pushToast } from '../../components/Toast';
import Button from '../../components/ButtonDetail';
import ModalSelect from '../ModalSelect';
import _ from 'lodash';
import { getNoOrder } from '../../helpers/helpers';
import 'assets/scss/page/DetailErp/style.scss';

const mockData = {
  screenName: SCREEN_NAME.PREPARATION_INFO,
};
const SelectTable = ({
  item,
  role,
  partnerBranch = [],
  getListPreparationInfo = () => {},
  idProduct,
  activePageTable,
  activePageSearch,
}) => {
  const [isOpenSelect, setIsOpenSelect] = useState(false);
  const [dataUpdate, setDataUpdate] = useState(mockData);
  const [dataStaff, setDataStaff] = useState([]);

  useEffect(() => {
    setDataStaff(item?.staffId || []);
  }, [item]);

  const toggleModalSelect = () => {
    setIsOpenSelect(!isOpenSelect);
  };
  const handleModalSelect = () => {
    setIsOpenSelect(!isOpenSelect);
  };

  const orderId = item?.orderId;
  const organizationId = item?.organizationId;

  const changeBranch = (e) => {
    let id = e.target.value;
    if (id === '') {
      setDataUpdate(mockData);
    } else {
      let newData = { ...dataUpdate, branchId: id };
      setDataUpdate(newData);
    }
  };

  const updateConfirmCondition = async ({ id_order = '', body = '' }) => {
    try {
      const data = await ORDER.UPDATE_WORKER_NAME({ id_order, body, organizationId });
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
      } else {
        pushToast('success', 'Active order success');
        const dataSearch = {
          page: activePageTable,
          limit: 10,
          pageProduct: activePageSearch,
          limitProduct: 10,
          productId: idProduct,
        };
        getListPreparationInfo({
          dataSearch,
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const updatePreparationInfo = async ({ id_order = '', body = '' }) => {
    try {
      const data = await STAFF_ENDPOINT.UPDATE_STAFF_BRANCH_PARTNER({
        id_order,
        body,
        organizationId: organizationId || '',
      });
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
      } else {
        pushToast('success', 'Active order success');
        const dataSearch = {
          page: activePageTable,
          limit: 10,
          pageProduct: activePageSearch,
          limitProduct: 10,
          productId: idProduct,
        };
        getListPreparationInfo({
          dataSearch,
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const updateConfirm = async (updateStaff, leader) => {
    const dataCompany = { companyName: localStorage.getItem('companyName'), updateTitle: '作業員選択' }
    if (role === ROLE.PARTNER) {
      await updatePreparationInfo({ id_order: orderId, body: { ...dataUpdate, staffId: updateStaff, leader, ...dataCompany } });
    } else {
      await updateConfirmCondition({
        id_order: orderId,
        body: { staffId: updateStaff, screenName: SCREEN_NAME.PREPARATION_INFO, leader, ...dataCompany },
      });
    }
  };

  return (
    <div className="select-button">
      {role === ROLE.PARTNER ? (
        <>
          {/* <div>
            <select
              onChange={changeBranch}
              defaultValue={item?.branchId}
              disabled={item?.statusWorkerAllocation ? false : true}
            >
              <option value="">---</option>
              {partnerBranch?.map((item, index) => (
                <option value={item.branchId} key={index}>
                  {item.branchName}
                </option>
              ))}
            </select>
          </div> */}
          {isOpenSelect && (
            <ModalSelect
              modal={isOpenSelect}
              updateConfirm={updateConfirm}
              toggle={toggleModalSelect}
              orderId={item?.orderId}
              listStaff={item?.listStaff}
              staffId={dataStaff || []}
              partnerName={item?.partnerName}
              statusConditionInfo={item?.statusConditionInfo}
              visitDate={item?.visitDate}
              organizationId={item?.organizationId}
            />
          )}
          {Array.isArray(item && item?.workerName) ? (
            <div className=" d-flex detail__table--color">
              <label className="detail__table--text w-30">{`確定：${item?.workerName?.length || 0}名`}</label>
              <div className="w-70 form-btn-staff">
                {item?.workerName?.map((elm, index) => {
                  return (
                    <button key={index} disabled className="detail__table--submit detail__table--button" title={elm}>
                      {elm && elm.length > 3 ? `${elm.slice(0, 3)}...` : elm}
                    </button>
                  );
                })}
              </div>
            </div>
          ) : (
            // <input
            //   type="text"
            //   value={`${item?.workerName?.join(' , ').slice(0, 20)}...`}
            //   disabled={item?.statusWorkerAllocation ? false : true}
            // />
            <input
              type="text"
              style={{ textTransform: 'none' }}
              disabled={!item?.statusWorkerAllocation}
              value={`${item?.listStaff
                ?.filter((ele) => dataStaff.includes(ele.loginId))
                ?.map((els) => els.name)
                ?.join(' , ')
                .slice(0, 20)}...`}
            />
          )}
          <div>
            <Button
              text="登録"
              className="worker_submit1 mt-1"
              onUpdateOrder={handleModalSelect}
              // disabled={!item?.statusWorkerAllocation}
            />
          </div>
        </>
      ) : (
        <>
          {isOpenSelect && (
            <ModalSelect
              modal={isOpenSelect}
              updateConfirm={updateConfirm}
              toggle={toggleModalSelect}
              listStaff={item?.listStaff}
              orderId={item?.orderId}
              staffId={item?.staffId}
              partnerName={item?.partnerName}
              statusConditionInfo={item?.statusConditionInfo}
              visitDate={item?.visitDate}
              organizationId={item?.organizationId}
            />
          )}
          {Array.isArray(item && item?.workerName) ? (
            <div className=" d-flex detail__table--color">
              <label className="detail__table--text w-30">{`確定：${item?.workerName?.length || 0}名`}</label>
              <div className="w-70 form-btn-staff">
                {item?.workerName?.map((elm, index) => {
                  return (
                    <button key={index} disabled className="detail__table--submit detail__table--button" title={elm}>
                      {elm && elm.length > 3 ? `${elm.slice(0, 3)}...` : elm}
                    </button>
                  );
                })}
              </div>
            </div>
          ) : (
            <input type="text" value="" disabled={!item?.statusWorkerAllocation} />
          )}
          <Button
            text="登録"
            className="worker_submit1 mt-1"
            onUpdateOrder={handleModalSelect}
            // disabled={!item?.statusWorkerAllocation}
          />
        </>
      )}
    </div>
  );
};

const SettingLinkUrlWorker = ({ p, orderId, organizationId, role, menuName, workerReport }) => {
  const [dataWorker, setDataWorker] = useState([]);
  useEffect(() => {
    if (workerReport?.[menuName]?.data) {
      let sortNum = +workerReport?.[menuName]?.data?.[0]?.sortNum || 1;
      let data =
        !_.isEmpty(workerReport) &&
        _.sortBy(workerReport?.[menuName]?.data, ['sortNum']).map((e) => {
          if (sortNum === e.sortNum && !e.isConfirm) {
            e.isConfirm = false;
          }
          if (e.sortNum === e.sortNum && e.isConfirm) {
            sortNum++;
          }
          return e;
        });
      setDataWorker(data);
    }
  }, [workerReport?.[menuName]?.data]);

  return (
    <>
      {(['site_info'].includes(p) && (
        <Link
          to={`${
            !_.isEmpty(dataWorker?.filter((item) => item?.isConfirm === false)?.[0]?.menuSettingId)
              ? `/report-order?onBack=${'site_info'}&orderId=${orderId}&organizationId=${organizationId}&menuName=${menuName}&menuSettingId=${
                  dataWorker?.filter((item) => item?.isConfirm === false)?.[0]?.menuSettingId
                }`
              : `/report-order?onBack=${'site_info'}&orderId=${orderId}&organizationId=${organizationId}&menuName=${menuName}`
          }`}
        >
          <div className="btn-worker-report"></div>
        </Link>
      )) ||
        (p === 'tomorrow_info' && (
          <Link
            to={`${
              !_.isEmpty(dataWorker?.filter((item) => item?.isConfirm === false)?.[0]?.menuSettingId)
                ? `/report-order?onBack=${'tomorrow_info'}&orderId=${orderId}&organizationId=${organizationId}&menuName=${menuName}&menuSettingId=${
                    dataWorker?.filter((item) => item?.isConfirm === false)?.[0]?.menuSettingId
                  }`
                : `/report-order?onBack=${'tomorrow_info'}&orderId=${orderId}&organizationId=${organizationId}&menuName=${menuName}`
            }`}
          >
            <div className="btn-worker-report"></div>
          </Link>
        )) ||
        (p === 'report_upload_info' && role === ROLE.PARTNER && (
          <Link
            to={`${
              !_.isEmpty(dataWorker?.filter((item) => item?.isConfirm === false)?.[0]?.menuSettingId)
                ? `/report-order?onBack=${'report_upload_info'}&orderId=${orderId}&organizationId=${organizationId}&menuName=${menuName}&menuSettingId=${
                    dataWorker?.filter((item) => item?.isConfirm === false)?.[0]?.menuSettingId
                  }`
                : `/report-order?onBack=${'report_upload_info'}&orderId=${orderId}&organizationId=${organizationId}&menuName=${menuName}`
            }`}
          >
            <div className="btn-worker-report"></div>
          </Link>
        ))}
    </>
  );
};

const renderItem = (
  item,
  detailCheck,
  index,
  data,
  role,
  p,
  link,
  partnerId,
  partnerBranch,
  getListPreparationInfo,
  idProduct,
  activePageTable,
  activePageSearch,
  t,
  idProvider
) => {
  let statusConfirmed;
  let statusRejectTmp;
  let statusWaiting;
  let statusReject;

  if(item?.installationSiteName){
    item.visitedSiteName = item.installationSiteName
  }
  if(item?.personInChargeOfApplication){
    item.contactName = item.personInChargeOfApplication
  }
  if (item?.partners) {
    if (Array.isArray(item?.partners)) {
      statusConfirmed = item?.partners?.some((element) => element.statusOrderCondition === 'confirmed');
      statusRejectTmp = item?.partners?.filter((element) => element.statusOrderCondition === 'reject');
      statusWaiting = item?.partners?.some((element) => element.statusOrderCondition === 'waiting');
      statusReject = !_.isEmpty(item?.partners) && statusRejectTmp?.length === item?.partners?.length;
    } else {
      var arrTmp = Object.keys(item?.partners).map((key) => {
        return item?.partners[key];
      });
      statusConfirmed = arrTmp.some((element) => element.statusOrderCondition === 'confirmed');
      statusRejectTmp = arrTmp.filter((element) => element.statusOrderCondition === 'reject');
      statusWaiting = arrTmp.some((element) => element.statusOrderCondition === 'waiting');
      statusReject = !_.isEmpty(item?.partners) && statusRejectTmp?.length === arrTmp.length;
    }
  } else {
    statusConfirmed = false;
    statusRejectTmp = false;
    statusWaiting = false;
    statusReject = false;
  }

  const colorStatus = (status) => {
    switch (status) {
      case '受注処理期限切れ':
      case 'アポイント期限切れ':
      case '稼働調整期限切れ':
      case '作業員登録期限切れ':
      case '報告書回収期限切れ':
      case '入館連絡確認':
      case '稼働回答期限切れ':
      case '報告書提出期限切れ':
      case '事前確認期限切れ':
        return { background: '#ff0000', color: 'white', width: 'unset', animation: 'status-pulse-red 1s infinite' };
      case '入館待ち':
        return { background: '#92d050', color: 'black', width: 'unset', animation: 'status-pulse-green 1s infinite' };
      case '入館中':
        return { background: '#00b0f0', color: 'black', width: 'unset', animation: 'status-pulse-blue 1s infinite' };
      case '作業連絡確認':
        return { background: '#0070c0', color: 'white', width: 'unset', animation: 'status-pulse-blue-d 1s infinite' };
      case '作業終了':
        return { background: '#7f7f7f', color: 'white', width: 'unset', animation: 'status-pulse-gray 1s infinite' };
      case '当日CAN':
      case 'yellow' :
        return { background: '#ffff00', color: 'black', width: 'unset', animation: 'status-pulse-yellow 1s infinite' };
      default:
        return { border: 'none' };
    }
  };

  const handleStatus = ({ item }) => {
    let statusCondition = '';
    if (item?.partnerId) {
      statusCondition = STATUS_CONDITION_CSV.securingOperation;
    } else {
      if (!item.partners || _.isEmpty(item.partners)) {
        statusCondition = STATUS_CONDITION_CSV.unConsulted;
      }

      if (!_.isEmpty(item.partners)) {
        if (
          item.partners.find((e) => e.statusOrderCondition === STATUS_ORDER_CONDITION.WAITING) &&
          !item.partners.find((e) => e.statusOrderCondition === STATUS_ORDER_CONDITION.CONFIRMED)
        ) {
          statusCondition = STATUS_CONDITION_CSV.waitingForOperationReply;
        }

        if (item.partners.find((e) => e.statusOrderCondition === STATUS_ORDER_CONDITION.CONFIRMED)) {
          statusCondition = STATUS_CONDITION_CSV.operable;
        }

        if (
          item.partners.filter((e) => e.statusOrderCondition === STATUS_ORDER_CONDITION.REJECT).length ===
          item.partners.length
        ) {
          statusCondition = STATUS_CONDITION_CSV.notOperational;
        }
      }
    }
    return statusCondition;
  };

  const newList = role === ROLE.PARTNER && item?.partners?.filter((elm) => elm.partnerId === partnerId)?.[0];
  return (
    <tr key={index}>
      <td>{getNoOrder({ index, limit: 10, activePage: activePageTable })}</td>
      {data.header.map((element, key) => {
        if (element === 'partnerName' && role === ROLE.CENTER && item?.partnerNameChild) {
            item.partnerName = item?.partnerNameChild;
        }
        return (
          <td key={key}>
            {element === 'workerReport' ? (
              <SettingLinkUrlWorker
                p={p}
                orderId={item?.orderId}
                organizationId={item?.organizationId}
                role={role}
                menuName={p === 'tomorrow_info' ? 'beforeConfirm' : 'workOnTheDay'}
                workerReport={item?.workerReport}
              />
            ) : ( element === "status" && p === "site_info" && role === ROLE.CLIENT ) ? (
              <div className="status_process" style={colorStatus(item?.statusAlert)}>
                {item?.statusAlert}
              </div>
            ) : element === 'resultRemarks' && p === 'report_upload_info' ? (
              `${!_.isEmpty(item?.resultCancel) ? item?.resultCancel : ''}`
            ) : p === 'tomorrow_info' && ['statusMonitoring', 'statusWorker'].includes(element) ? (
              t(`statusBeforeConfirm.${item[element]}`)
            ) : element === 'workerResponseRequired' &&
              p === 'condition_info' &&
              (role === ROLE.CENTER || role === ROLE.PARTNERGROUP) ? (
              `${item?.workerResponseRequired === undefined ? '' : item?.workerResponseRequired ? '必要' : '不要'}`
            ) : element === 'newCallDate' &&
              p === 'appoint_info' &&
              (role === ROLE.CENTER || role === ROLE.PARTNERGROUP || role === ROLE.PARTNER) ? (
              `${
                !_.isEmpty(item?.newCallDate)
                  ? `${item?.newCallDate} ${!_.isEmpty(item?.newCallDateFrom) ? item?.newCallDateFrom : ''}～${
                      !_.isEmpty(item?.newCallDateTo) ? item?.newCallDateTo : ''
                    }`
                  : ''
              }`
            ) : element === 'adjustmentStatus' &&
              p === 'condition_info' &&
              (role === ROLE.CENTER || role === ROLE.PARTNERGROUP) ? (
              <>{handleStatus({ item })}</>
            ) : element === 'workerName' &&
              (p === 'receive_all' || p === 'site_info' || p === 'tomorrow_info') &&
              (role === ROLE.CENTER || role === ROLE.PARTNERGROUP) ? (
              <p style={{ border: 'none', marginBottom: 0 }}>{item?.workerName?.[0]}</p>
            ) : element === 'workerName' &&
              p === 'preparation_info' &&
              (role === ROLE.CENTER || role === ROLE.PARTNERGROUP) ? (
              <>
                <SelectTable
                  item={item}
                  role={role}
                  getListPreparationInfo={getListPreparationInfo}
                  idProduct={idProduct}
                  activePageTable={activePageTable}
                  activePageSearch={activePageSearch}
                />
              </>
            ) : (element === 'workerName') & (p === 'preparation_info') && role === ROLE.PARTNER ? (
              <>
                <SelectTable
                  item={item}
                  role={role}
                  partnerBranch={partnerBranch}
                  getListPreparationInfo={getListPreparationInfo}
                  idProduct={idProduct}
                  activePageTable={activePageTable}
                  activePageSearch={activePageSearch}
                />
              </>
            ) : element === 'workerName' && Array.isArray(item[element]) ? (
              item[element].join(' ,').slice(0, 20)
            ) : element === 'visitDate' && (role === ROLE.CENTER || role === ROLE.PARTNERGROUP) ? (
              _.isEmpty(item?.visitDate) ? (
                _.isEmpty(item?.preferredDate) ? null : (
                  _.map(_.sortBy(_.omitBy(item?.preferredDate, _.isEmpty)), (value, index) => {
                    return (
                      <>
                        <label className="label__visitDate">
                          {index === 0 && (
                            <>
                              <b>希望日</b>
                              <br />
                            </>
                          )}
                          <i className="fa fa-calendar-o" aria-hidden="true" />
                          {value?.visitDate}
                        </label>
                        <label className="label__visitDate">
                          <i className="fa fa-clock-o" aria-hidden="true" />
                          {`${value?.visitDate} ${value?.visitDateFrom}`}～{value?.visitDateTo}
                        </label>
                        <br />
                      </>
                    );
                  })
                )
              ) : (
                `${item?.visitDate ? item?.visitDate : ''} ${item?.visitDateFrom ? item?.visitDateFrom : ''} ~ ${
                  item?.visitDateTo ? item?.visitDateTo : ''
                }`
              )
            ) : element === 'visitDate' && (role === ROLE.PARTNER || role === ROLE.CLIENT) ? (
              `${item?.visitDate ? item?.visitDate : ''} ${item?.visitDateFrom ? item?.visitDateFrom : ''} ~ ${
                item?.visitDateTo ? item?.visitDateTo : ''
              }`
            ) : (
              item[element]
            )}
          </td>
        );
      })}
      {((role === ROLE.CENTER || role === ROLE.PARTNER || role === ROLE.PARTNERGROUP) &&
        [
          'receive_info',
          'appoint_info',
          'condition_info',
          'preparation_info',
          'report_upload_info',
          'site_info',
          'tomorrow_info'
        ].includes(p)) ||
      (role === ROLE.PARTNER && p === 'receive_all') ? (
        <td className="table--nowrap">
          <div className="status_process" style={colorStatus(item?.statusAlert)}>
              {item?.statusAlert}
          </div>

          { (p === 'receive_info' && item?.receiveInfoApproval &&
            item?.statusReceiveInfoRequest === true && (item?.statusReceiveInfoConfirmed == null)) &&
            <div className="status_process" style={colorStatus('yellow')}>
              承認待ち
            </div>
          }

          { (p === 'report_upload_info' && item?.reportUploadApproval &&
            item?.statusReportUploadRequest === true) &&
            <div className="status_process" style={colorStatus('yellow')}>
              承認待ち
            </div>
            }

            { (p === 'tomorrow_info' && item?.tomorrowInfoApproval &&
            item?.statusBeforeConfirmRequest === true && item?.statusBeforeConfirmConfirmed == null) &&
            <div className="status_process" style={colorStatus('yellow')}>
              承認待ち
            </div>
            }
        </td>
      ) : null}

      {(role === ROLE.CENTER || role === ROLE.PARTNERGROUP || role === ROLE.PARTNER || role === ROLE.CLIENT) &&
        (p === 'receive_all' && (role === ROLE.CENTER || role === ROLE.PARTNERGROUP) ? (
          <td>
            <Link
              to={`/detail-list-order?orderId=${item?.orderId}&organizationId=${item.organizationId}`}
            >
              <button className="link_button d-flex">
                <i className="icon-export" />
                閲覧
              </button>
            </Link>
          </td>
        ) : (p === 'unoperation_list' || p === 'cancel_menu') && role === ROLE.PARTNER ? null : (p === 'receive_all' ||
            p === 'site_info' ||
            p === 'report_upload_info' ||
            p === 'cancel_info') &&
          role === ROLE.CLIENT ? null : p === 'preparation_info' && role === ROLE.CLIENT ? (
          <td>
            {/* <Link to={`/detail-sport-accept?orderId=${item?.orderId}`}>
              <input type="submit" className="button-sport-accept" value="" />
            </Link> */}
            <Link to={`/sport-accept-list?orderId=${item?.orderId}`}>
              <button className="link_button d-flex">
                <i className="icon-export" />
                閲覧
              </button>
            </Link>
          </td>
        ) : p === 'appoint_info' && role === ROLE.PARTNER ? (
          <td>
            {item?.appointmentCategory !== 'センター' ? (
              <Link to={`/appoint-info-detail?orderId=${item?.orderId}&organizationId=${item.organizationId}`}>
              <button className="link_button d-flex">
                <i className="icon-export" />
                閲覧
              </button>
              </Link>
            ) : (
              'アポイント対象外'
            )}
          </td>
        ) : p === 'preparation_info' && role === ROLE.PARTNER ? (
          <td>
            {_.isEmpty(item?.preparationFile?.[0]?.url) ? (
              ''
            ) : (
              <a onClick={() => window.open(`${item?.preparationFile?.[0]?.url}`)} type="submit">
                <button type="submit" className="link_button mb-1 ">
                  資料
                </button>
              </a>
            )}
            <Link to={`${link}?orderId=${item?.orderId}&organizationId=${item.organizationId}`}>
              <button className="link_button d-flex">
                <i className="icon-export" />
                閲覧
              </button>
            </Link>
          </td>
        ) : p === 'receive_all' && role === ROLE.PARTNER ? (
          <td>
            {newList?.statusOrderCondition === 'waiting' ? (
              <Link to={`${link}?orderId=${item?.orderId}&organizationId=${item.organizationId}`}>
                <button className="link_button d-flex">
                  <i className="icon-export" />
                  閲覧
                </button>
              </Link>
            ) : (
              <div>稼働可</div>
            )}
          </td>
        ) : p === 'condition_info' && (role === ROLE.CENTER || role === ROLE.PARTNERGROUP) ? (
          <td>
            <Link to={`${link}?orderId=${item?.orderId}&organizationId=${item.organizationId}`}>
              <button className="link_button d-flex">
                <i className="icon-export" />
                閲覧
              </button>
            </Link>
            {/* {
                    detailCheck?.dispatch && (
                      <Link to={`dispatch?orderId=${item?.orderId}&visitDate=${item?.visitDate}`}>
                        <button className="link_button d-flex">
                          Dispatch
                        </button>
                      </Link>
                    )
                  } */}
          </td>
        ) : p === 'appoint_info' && (role === ROLE.CENTER || role === ROLE.PARTNERGROUP) ? (
          <td>
            {item?.appointmentCategory === 'パートナー' && item.callStatus !== 'キャンセル依頼' && item?.organizationId === idProvider ? (
              'アポイント対象外'
            ) : item?.organizationId !== idProvider && item?.appointmentCategory !== 'センター' ? 'アポイント対象外' : (
              <Link
                to={`${link}?orderId=${item?.orderId}&organizationId=${item.organizationId}&partnerId=${item.partnerId}`}
              >
                <button className="link_button d-flex">
                  <i className="icon-export" />
                  閲覧
                </button>
              </Link>
            )}
          </td>
        ) : (
          <>
            <td>
              {p === 'tomorrow_info' && (item?.statusBeforeConfirmConfirmed !== true || item?.statusMonitoring !== "correspondenceCompleted" ) && (
                <Link to={`${link}?orderId=${item?.orderId}&organizationId=${item.organizationId}`}>
                  <button className="link_button d-flex">
                    <i className="icon-export" />
                    閲覧
                  </button>
                </Link>
              )}
              {!(p === 'tomorrow_info') && (
                <Link
                  to={`${link}?orderId=${item?.orderId}&organizationId=${item.organizationId}&partnerId=${item.partnerId}`}
                >
                  <button className="link_button d-flex">
                    <i className="icon-export" />
                    閲覧
                  </button>
                </Link>
              )}
              {p === 'report_upload_info' && (role === ROLE.CENTER || role === ROLE.PARTNERGROUP) && (
                <>
                  {item?.isConfirmReportPartner === true ? (
                    <div className="status_process redanime" style={{ background: 'red', color: 'white' }}>
                      返却中
                    </div>
                  ) : item?.isConfirmReportPartner === false ? (
                    <div className="status_process redanimes" style={{ background: 'blue', color: 'white' }}>
                      納品待ち
                    </div>
                  ) : (
                    <span />
                  )}
                </>
              )}
              {p === 'report_upload_info' && role === ROLE.PARTNER && (
                <>
                  {item?.isConfirmReportPartner === true ? (
                    <div className="status_process redanime" style={{ background: 'red', color: 'white' }}>
                      不備有り
                    </div>
                  ) : (
                    <span />
                  )}
                </>
              )}
            </td>
          </>
        ))}
    </tr>
  );
};

const TableList = ({
  sortOrderBy,
  data = [],
  t,
  role,
  p,
  color,
  roleDetail,
  partnerId,
  partnerBranch,
  colorText,
  detailCheck,
  getListPreparationInfo,
  idProduct,
  activePageTable,
  activePageSearch,
}) => {
  const idProvider = localStorage.getItem('idProvide')
  const [sortOrder, setSortOrder] = useState([]);
  useEffect(() => {
    setSortOrder(data?.body);
  }, [data]);

  const sortBy = (item, sortWith) => {
    sortWith = sortWith ? 'asc' : 'desc';
      sortOrderBy({ sortBy: item, sortWith, sortProductId: data.productId });
  };

  const getLinkDetail = (p) => {
    if (p && (roleDetail === ROLE.CENTER || roleDetail === ROLE.PARTNERGROUP)) {
      switch (p) {
        case HEADER_MENU.RECEIVE_INFO:
          return '/describe-receive-info';
        case HEADER_MENU.CONDITION_INFO:
          return '/detail-condition-info';
        case HEADER_MENU.APPOINT_INFO:
          return '/appoint-info-detail';
        case HEADER_MENU.SITE_INFO:
          return '/detail-site-info';
        case HEADER_MENU.CANCEL_INFO:
          return '/detail-cancel-info';
        case HEADER_MENU.CANCEL_MENU:
          return '/detail-cancel-menu';
        case HEADER_MENU.REPORT_UPLOAD_INFO:
          return '/detail-report-upload-info';
        case HEADER_MENU.PREPARATION_INFO:
          return '/detail-preparation-info';
        case HEADER_MENU.DELIVERED_INFO:
          return '/detail-delivered-info';
        case HEADER_MENU.RECEIVE_ALL:
          return '/detail-list-order';
        case HEADER_MENU.TOMORROW_INFO:
          return '/detail-before-confirm-erp';
        case 'summary':
          return '/detail-list-order';
        default:
          return '';
      }
    } else if (p && roleDetail === ROLE.PARTNER) {
      switch (p) {
        case HEADER_MENU.RECEIVE_ALL:
          return '/detail-receive-confirm';
        case HEADER_MENU.REPORT_UPLOAD_INFO:
          return '/detail-report-list';
        case HEADER_MENU.APPOINT_INFO:
          return '/appoint-info-detail';
        case HEADER_MENU.SITE_INFO:
          return '/detail-honkado-list';
        case HEADER_MENU.DELIVERED_INFO:
          return '/detail-report-compledted';
        case HEADER_MENU.CANCEL_MENU:
          return '/detail-report-compledted';
        case HEADER_MENU.UNOPERATION_LIST:
          return '/detail-report-compledted';
        case HEADER_MENU.PREPARATION_INFO:
          return '/detail-preparation-info-partner';
        case HEADER_MENU.TOMORROW_INFO:
          return '/detail-before-confirm-partner';
        default:
          return '';
      }
    } else if (p && roleDetail === ROLE.CLIENT) {
      switch (p) {
        case HEADER_MENU.CANCEL_MENU:
          return '/cancel-menu-detail';
        case HEADER_MENU.CANCEL_INFO:
          return '/detail-sport-accept';
        case HEADER_MENU.DELIVERED_INFO:
          return '/detail-delivery-client';
        case HEADER_MENU.TOMORROW_INFO:
          return '/detail-before-confirm-client';
        default:
          return '';
      }
    }
  };

  return (
    <div className="table-list-wrapper table-responsive">
      <table className="table table-striped">
        <thead>
          <tr style={{ backgroundColor: `${color}` }}>
            <th scope="col" style={{ color: `${colorText}` }}>
              No
            </th>
            {data?.header?.map((item, index) => (
              <th scope="col" key={index} style={{ color: `${colorText}` }}>
                {role === ROLE.CENTER || role === ROLE.PARTNERGROUP ? (
                  <>{t(`table_order_list_center.${item}`)}</>
                ) : role === ROLE.PARTNER ? (
                  <>{t(`table_order_list_partner.${item}`)}</>
                ) : (
                  <>{t(`table_order_list_client.${item}`)}</>
                )}
                <br />
                <a onClick={() => sortBy(item, true)}>
                  ▲
                </a>
                <a onClick={() => sortBy(item, false)}>
                  ▼
                </a>
              </th>
            ))}
            {((role === ROLE.CENTER || role === ROLE.PARTNER || role === ROLE.PARTNERGROUP) &&
              [
                'receive_info',
                'appoint_info',
                'condition_info',
                'preparation_info',
                'report_upload_info',
                'site_info',
                'tomorrow_info'
              ].includes(p)) ||
            (role === ROLE.PARTNER && p === 'receive_all') ? (
              <th scope="col" style={{ color: `${colorText}` }}>
                状況
              </th>
            ) : null}
            {(role === ROLE.CENTER || role === ROLE.PARTNER || role === ROLE.PARTNERGROUP || role === ROLE.CLIENT) &&
              ((p === 'unoperation_list' || p === 'cancel_menu') && role === ROLE.PARTNER ? null : (p ===
                  'receive_all' ||
                  p === 'site_info' ||
                  p === 'report_upload_info' ||
                  p === 'cancel_info') &&
                role === ROLE.CLIENT ? null : (
                <th scope="col" style={{ color: `${colorText}` }}>
                  操作
                </th>
              ))}
          </tr>
        </thead>
        <tbody>
          {sortOrder &&
            sortOrder?.map((listData, index) =>
              renderItem(
                listData,
                detailCheck,
                index,
                data,
                role,
                p,
                getLinkDetail(p),
                partnerId,
                partnerBranch,
                getListPreparationInfo,
                idProduct,
                activePageTable,
                activePageSearch,
                t,
                idProvider
              )
            )}
        </tbody>
      </table>
    </div>
  );
};

export default TableList;
