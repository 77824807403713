import React, { useState, useEffect } from 'react';
import DateTimePicker from 'components/DateTimePicker';
import { DATE_JP_FORMAT } from 'helpers/constants';
import { ROLE } from 'helpers/constants';
import { getDate } from 'helpers/helpers';
import moment from 'moment';

const BeforeConfirmDetail = ({ data = {}, changeData, changeDate,role }) => {
  const [dataDetail, setDataDetail] = useState({});

  useEffect(() => {
    const admissionApplicationCompletionDate = data?.admissionApplicationDeadline && data?.visitDate ? moment(new Date(data?.visitDate)).subtract(parseInt(data?.admissionApplicationDeadline), 'days').format(DATE_JP_FORMAT) : data?.admissionApplicationCompletionDate ? moment(new Date(data?.admissionApplicationCompletionDate)).format(DATE_JP_FORMAT) :  '';
    setDataDetail({...data, admissionApplicationCompletionDate});
  }, [data]);
  const options = ([ROLE.CENTER, ROLE.PARTNERGROUP].includes(role)) ?
    [
      {
        label: '対応待ち',
        value: 'waitingForResponse',
        disableFlag:'',
      },
      {
        label: '対応中',
        value: 'underAdmissionApplication',
        disableFlag:'',
      },
      {
        label: '対応完了',
        value: 'correspondenceCompleted',
        disableFlag:'',
      },
      {
        label: 'キャンセル',
        value: 'cancel',
        disableFlag:'',
      },

      {
        label: '対応保留',
        value: 'withdrawal',
        disableFlag:'',
      },
      {
        label: 'キャンセル依頼',
        value: 'cancelRequest',
        disableFlag:true,
      },
    ]:
    [
      {
        label: '対応待ち',
        value: 'waitingForResponse',
        disableFlag:'',
      },
      {
        label: '対応中',
        value: 'underAdmissionApplication',
        disableFlag:'',
      },
      {
        label: '対応完了',
        value: 'correspondenceCompleted',
        disableFlag:'',
      },
      {
        label: 'キャンセル依頼',
        value: 'cancelRequest',
        disableFlag:'',
      },
      {
        label: '対応保留',
        value: 'withdrawal',
        disableFlag:'',
      },
    ];

  const changeVal = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if(name === 'admissionApplicationDeadline'){
      const admissionApplicationCompletionDate = value && dataDetail?.visitDate ? moment(new Date(dataDetail?.visitDate)).subtract(parseInt(value), 'days').format(DATE_JP_FORMAT) :  '';
      const dataValue = {admissionApplicationDeadline: value, admissionApplicationCompletionDate};
      changeDate({ data: dataValue });
    } else {
      changeData({ name, value });
    }
  };

  return (
    <div className="wrapper-comfirm-status">
      <div className="content__wrapper">
        <div className="title-absolute">事前確認状況</div>
        <div className="row col-12">
          <div className="row col-4">
            <label className="col-4 content__label">事前確認状況</label>
            <div className="col-8">
              <select
                value={dataDetail?.statusMonitoring}
                onChange={changeVal}
                className="content__select--custom"
                name="statusMonitoring"
              >
                <option value=""></option>
                {options.map((option, index) => (
                  <option value={option.value} key={index} disabled={option.disableFlag}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
          {/* <div className="row col-4">
            <label className="col-4 content__label">事前確認期限</label>
            <div className="col-8">
              <DateTimePicker
                date={
                  dataDetail?.admissionApplicationCompletionDate?.length > 0
                    ? new Date(dataDetail?.admissionApplicationCompletionDate)
                    : null
                }
                onChangeDate={changeDate}
              />
            </div>
          </div> */}
          <div className="row col-8">
            <label className="col-4 content__label">事前確認期限</label>
            <div className="col-3">
              <input
                type="number"
                name="admissionApplicationDeadline"
                className='fz-14'
                value={dataDetail?.admissionApplicationDeadline}
                onChange={changeVal}
              />
            </div>
            <div>　日前</div>
            <div className="col-3 fz-14">
              {
                dataDetail?.admissionApplicationCompletionDate ? moment(new Date(dataDetail?.admissionApplicationCompletionDate)).format(DATE_JP_FORMAT)
                + '（' + getDate(dataDetail?.admissionApplicationCompletionDate) + '）' : ''
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BeforeConfirmDetail;
