import React, { useState , useEffect } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { translate } from 'react-i18next';
import { useHistory, Link } from 'react-router-dom';
import { Collapse } from 'reactstrap';
import { compose } from 'redux';
import '../../../../../assets/scss/page/CsvMaster/index.scss';
import PaginationCustom from '../../../../../components/PaginationCustom';
import _ from 'lodash';

const CollapItem = ({ colors, item, handleHide, hideshow, colorText , getListEstimate , activePageContract }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [dataContracts, setDataContracts] = useState(item);
  const [loading, setLoading] = useState(false);
  const [activePage, setActivePage] = useState(1);

  const toggle = () => {
    setIsOpen(!isOpen);
    handleHide(dataContracts.productId);
  };

  useEffect(() => {
    setDataContracts(item);
  }, [item]);

  useEffect(() => {
    setActivePage(1)
  },[activePageContract])

  const handleChangePageContract = (pageNumber) => {
    setActivePage(pageNumber);
    getListEstimate({ pageContract: pageNumber, sortProductId: dataContracts.productId });
  };

  return (
    <div className="setting-master-menu-wrapper">
      <div className="wrapper-btn-colappse">
        <div
          onClick={toggle}
          className="mb-1 searchbar d-flex justify-content-between align-items-center"
          style={{ background: `${colors}` }}
        >
          <div className="ml-2" style={{ color: `${colorText}`, fontSize: '16px'}}>
            {`${
              !_.isEmpty(item.projectName)
                ? `${dataContracts?.clientName}-${dataContracts.productName} - ${dataContracts.projectName} `
                : `${dataContracts?.clientName}-${dataContracts.productName} `
            }`}{' '}
          </div>
          <div className="mr-2" style={{ color: `${colorText}` }}>
            {isOpen ? <i className="icon-up-open icon-searchbar" /> : <i className="icon-down-open icon-searchbar" />}
          </div>
        </div>
      </div>
      <Collapse isOpen={hideshow === item.productId}>
        <div>
          <table className="table table-bordered">
            <tbody>
              <tr>
                <td>応募会社</td>
                <td>協力会社</td>
                <td>有資格作業員</td>
                <td>見積金額</td>
                <td>契約状態</td>
                <td>操作</td>
              </tr>
              {dataContracts?.contractEstimates?.data?.map((itemIn, index) => {
                return (
                  <tr key={index}>
                    <td>{itemIn.abbreviationName}</td>
                    <td>{dataContracts?.totalPartnerByOrganization}</td>
                    <td>{dataContracts?.staffsByOrganization}</td>
                    <td>{itemIn?.estimate?.estimate}</td>
                    <td>{itemIn?.contractStatus?.state}</td>
                    <td>
                      <Link
                        to={`/contract/registration?recruitmentContractId=${itemIn.recruitmentId}&recruitmentEstimateId=${itemIn.contractEstimateId}&onBack=`}
                        className="btn-edit"
                      >
                        編集
                      </Link>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          {!_.isEmpty(dataContracts?.contractEstimates?.data) && dataContracts?.contractEstimates?.count > 10 && (
            <PaginationCustom
            activePage={activePage}
            itemsCountPerPage={10}
            totalItemsCount={dataContracts?.contractEstimates?.count}
            pageRangeDisplayed={5}
            handlePageChange={handleChangePageContract}
          />
          )}
        </div>
      </Collapse>
    </div>
  );
};

export default compose(translate('translations'))(CollapItem);
