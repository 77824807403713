import React from "react";

const LoginIcon = ({ width = 24, height = 24, fill = "currentColor" }) => (
  <svg width={width} height={height} viewBox="0 0 220 28" fill={fill} xmlns="http://www.w3.org/2000/svg">
    <path d="M47.3889 26.774V22.3408C50.0304 23.5887 52.2895 24.2126 54.1664 24.2126C56.356 24.2126 57.4451 23.3698 57.4451 21.6841C57.4451 20.6004 56.5414 19.6481 54.7457 18.8272L52.9383 18.0062C50.9804 17.1086 49.5786 16.1454 48.7328 15.1055C47.8871 14.0766 47.47 12.7959 47.47 11.2635C47.47 9.13992 48.1883 7.48707 49.6133 6.31584C51.0383 5.13367 53.0426 4.55353 55.6146 4.55353C57.2249 4.55353 59.1365 4.81623 61.3609 5.35259V9.59965C59.2176 8.75681 57.4451 8.32991 56.0432 8.32991C53.8304 8.32991 52.7298 9.10708 52.7298 10.6614C52.7298 11.6794 53.5524 12.5441 55.1859 13.2556L56.7383 13.9343C59.0554 14.9304 60.6774 15.9374 61.581 16.9554C62.4847 17.9734 62.9481 19.2869 62.9481 20.896C62.9481 23.0086 62.1719 24.7161 60.631 26.0297C59.0902 27.3432 57.0743 27.9999 54.6066 27.9999C52.1389 27.9999 49.8219 27.5949 47.4005 26.774" fill="#01A2CE"/>
    <path d="M77.1402 27.4637C75.8311 27.8249 74.8 28 74.0585 28C69.3201 28 66.9451 25.9093 66.9451 21.717V4.5755H72.4134V8.39567H76.7579V11.6248H72.4134V21.0383C72.4134 23.3917 73.5835 25.6247 75.4603 26.3581C75.9353 26.5442 76.6884 26.7084 77.1402 26.6646V27.4746V27.4637Z" fill="#01A2CE"/>
    <path d="M106.834 0H102.814V27.5621H106.834V0Z" fill="#01A2CE"/>
    <path d="M114.677 0H110.657V27.5621H114.677V0Z" fill="#01A2CE"/>
    <path d="M159.003 27.3541C157.288 27.781 155.712 27.9999 154.253 27.9999C151.808 27.9999 149.885 27.3212 148.46 25.9639C147.047 24.6066 146.34 22.7677 146.34 20.4252C146.34 18.0828 147.07 16.222 148.529 14.8865C149.989 13.5511 152.005 12.8834 154.577 12.8834C155.817 12.8834 157.253 13.0695 158.887 13.4526V16.2657C157.195 15.7513 155.84 15.4886 154.832 15.4886C153.592 15.4886 152.608 15.9374 151.855 16.8349C151.101 17.7325 150.731 18.9147 150.731 20.4034C150.731 21.892 151.136 23.1399 151.947 24.0484C152.758 24.9569 153.847 25.4166 155.203 25.4166C156.442 25.4166 157.705 25.1649 159.014 24.6504V27.3431L159.003 27.3541Z" fill="#071945"/>
    <path d="M166.649 6.896H162.525V27.6825H166.649V6.896Z" fill="#071945"/>
    <path d="M177.725 28.0001C175.338 28.0001 173.438 27.3214 172.037 25.9532C170.635 24.5849 169.928 22.7569 169.928 20.4473C169.928 18.1377 170.635 16.2659 172.048 14.9195C173.462 13.5732 175.385 12.8945 177.818 12.8945C180.251 12.8945 182.185 13.5732 183.61 14.9195C185.024 16.2659 185.73 18.1048 185.73 20.4254C185.73 22.746 185.024 24.6506 183.599 25.986C182.174 27.3324 180.216 28.0001 177.713 28.0001M177.794 25.57C180.158 25.57 181.351 23.8515 181.351 20.4254C181.351 18.8601 181.038 17.6123 180.413 16.7038C179.787 15.7952 178.918 15.3355 177.829 15.3355C176.74 15.3355 175.883 15.7952 175.257 16.7038C174.632 17.6123 174.319 18.8711 174.319 20.4582C174.319 22.0454 174.632 23.2714 175.257 24.2018C175.883 25.1213 176.729 25.581 177.806 25.581" fill="#071945"/>
    <path d="M198.011 27.6716V24.9461C196.678 26.982 194.941 28 192.797 28C191.43 28 190.353 27.595 189.565 26.7741C188.777 25.9531 188.383 24.8476 188.383 23.4246V13.2119H192.508V22.4613C192.508 24.1032 193.411 24.7053 194.246 24.9242C195.555 25.2635 196.968 25.1759 198.011 24.1142V13.2119H202.135V27.6607H198.011V27.6716Z" fill="#071945"/>
    <path d="M215.876 27.6716V24.946C214.821 26.982 213.153 28 210.871 28C209.029 28 207.592 27.3651 206.538 26.0954C205.495 24.8256 204.962 23.0742 204.962 20.8413C204.962 18.4222 205.553 16.4847 206.735 15.0508C207.916 13.6059 209.515 12.8944 211.508 12.8944C213.107 12.8944 214.555 13.4965 215.864 14.6896V6.896H220V27.6825H215.864L215.876 27.6716ZM215.876 16.9992C214.879 15.9484 213.825 15.423 212.69 15.423C211.682 15.423 210.871 15.8827 210.268 16.8022C209.666 17.7216 209.365 18.9585 209.365 20.5129C209.365 23.4683 211.218 25.7451 212.632 25.7451C214.277 25.7451 214.96 24.9898 215.887 23.9171V16.9992H215.876Z" fill="#071945"/>
    <path d="M85.0994 15.9485C85.2616 12.7632 86.7793 11.187 89.0153 11.187C91.2513 11.187 92.3635 12.8179 92.3635 16.0908H88.0421V19.0025H97.7391C97.7391 15.1713 97.0556 12.3692 95.6885 10.6068C94.3214 8.83359 92.155 7.94696 89.1891 7.94696C86.2232 7.94696 83.883 8.85548 82.0872 10.6616C80.2915 12.4677 79.3994 14.8649 79.3994 17.8312C79.3994 20.9727 80.3842 23.4466 82.3421 25.2636C84.3 27.0806 86.9763 27.9892 90.3592 27.9892C92.6879 27.9892 94.947 27.6608 97.5538 26.9383V23.4028C95.1903 24.2347 93.1397 24.8148 90.4403 24.5083C86.5824 24.0814 85.5513 22.6475 85.1805 18.9915C85.1805 18.9915 85.0879 16.6928 85.1342 15.9266" fill="#01A2CE"/>
    <path d="M138.207 24.6176C137.906 24.6614 137.674 24.6833 137.489 24.6833C136.469 24.6833 135.959 23.928 135.959 22.4284V13.9452C135.959 9.94988 133.306 7.9577 128 7.9577C125.567 7.9577 123.123 8.39554 120.643 9.28217V12.8396C122.821 11.7012 124.976 11.1321 127.108 11.1321C129.506 11.1321 130.711 12.1281 130.711 14.1203V15.8717H130.676V18.8381H130.711V24.3439C129.564 25.2962 128.093 25.1758 126.83 25.0335C126.378 24.9788 125.521 24.6176 124.93 23.9718C124.455 23.4573 124.119 22.6911 124.119 21.9796C124.119 20.1078 125.532 19.0898 128.336 18.8819V15.8936C125.44 15.9921 123.181 16.5503 121.559 17.5902C119.763 18.7396 118.871 20.3705 118.871 22.4831C118.871 24.1141 119.427 25.4385 120.528 26.4675C121.64 27.4964 123.076 27.9999 124.849 27.9999C126.934 27.9999 128.881 27.168 130.7 25.5042H130.943C131.58 27.168 133.028 27.9999 135.264 27.9999C136.145 27.9999 137.176 27.8357 138.334 27.5073L138.207 24.6066V24.6176Z" fill="#01A2CE"/>
    <path d="M2.48382 13.365C4.30273 13.9342 6.57347 12.9709 7.1064 11.1648C7.65092 9.35875 6.72409 7.39941 4.77774 6.79737C2.95883 6.22818 0.688084 7.19143 0.155155 8.99753C-0.38936 10.8036 0.525888 12.763 2.48382 13.365Z" fill="#071945"/>
    <path d="M5.79721 15.1166C6.87465 15.0947 7.84783 15.7734 8.09112 16.7585C8.55454 18.7288 9.44661 20.9837 10.9064 21.2573C13.293 21.7061 15.0076 19.2323 16.282 18.1268C16.4095 18.0173 16.6064 18.0173 16.7338 18.1268L18.8424 20.1518C18.9698 20.2722 18.9698 20.4802 18.8424 20.6006C15.2857 23.7421 13.3046 25.8875 9.40027 24.9352C6.49233 24.2237 4.44172 21.0494 3.65391 17.6889C3.3411 16.3754 4.39537 15.1276 5.82038 15.0947" fill="#01A2CE"/>
    <path d="M34.0425 22.8553C33.1967 22.6583 32.212 23.1618 32.0266 23.9937C31.8412 24.8256 32.3278 25.6794 33.2315 25.8983C34.0772 26.0953 35.062 25.5918 35.2473 24.7599C35.4211 23.928 34.9461 23.0742 34.0425 22.8553Z" fill="#01A2CE"/>
    <path d="M31.8413 20.2282C30.7755 20.3267 29.7444 19.7137 29.4316 18.7395C28.806 16.8021 27.7517 15.2149 26.2803 15.0398C23.859 14.7552 22.4572 17.0867 21.2754 18.2798C21.1596 18.4002 20.9626 18.4111 20.8352 18.3017L18.576 16.2985C18.437 16.1781 18.4254 15.9811 18.5529 15.8498C21.8547 12.4674 23.5578 10.5738 27.52 11.2524C30.4743 11.7559 32.7566 14.186 33.8109 17.4807C34.2279 18.7724 33.2779 20.0859 31.8529 20.2172" fill="#01A2CE"/>
  </svg>
);

export default LoginIcon;
