import React, { useEffect, useState } from 'react';
import { DATE_JP_FORMAT, DATE_MONTH_YEAR } from '../../../helpers/constants';
import moment from 'moment';
import { getDate } from '../../../helpers/helpers';
import Loading from 'components/Loading/Loading';
import { CALENDAR_MASTER_ENDPOINTS } from '../../../constants/endpoints';
import DatePicker from 'react-datepicker';
import { MONTH } from 'helpers/constants';
import { Carousel, CarouselItem, CarouselControl, Tooltip } from 'reactstrap';
import _ from 'lodash';

const ScheduledOperation = () => {
  const [listDate, setListDate] = useState([]);
  const [loading, setLoading] = useState(false);
  const [activeIndex, setActiveIndex] = useState(new Date().getMonth());
  const [animating, setAnimating] = useState(false);
  const [listResult, setListResult] = useState([]);
  const [dataSearch, setDataSearch] = useState('');
  const [listSearch, setListSearch] = useState([]);
  const [listCalendarCheck, setListCalendarCheck] = useState([]);
  const [changeDatePicker, setChangeDatePicker] = useState(new Date(new Date().getFullYear(), new Date().getMonth()));

  const getRequestCalendar = async ({ date = '' }) => {
    try {
      setLoading(true);
      const data = await CALENDAR_MASTER_ENDPOINTS.GET_REQUEST_CALENDAR({
        date: date,
      });
      if (data) {
        setLoading(false);
        if (!_.isEmpty(data?.data?.calendarMaster)) {
          setListDate(data?.data?.calendarMaster);
        }
        setListCalendarCheck(data?.data?.calendarMaster)
        setListResult(data?.data?.result);
        setListSearch(data?.data?.result)
      } else {
        setLoading(false);
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (activeIndex) {
      let date = new Date(new Date().getFullYear(), activeIndex);
      getRequestCalendar({ date: `${moment(date).format(DATE_MONTH_YEAR)}` });
    }
  }, []);

  const getDaysInMonth = (month, year) => {
    let date = new Date(year, month, 1);
    let days = [];
    while (date.getMonth() === month) {
      let dateCheck = new Date(moment(date));
      let currentDay = dateCheck.getDay();
      let type = [0, 6].includes(currentDay) ? 2 : 1;
      days.push({ date: moment(dateCheck).format(DATE_JP_FORMAT), type });
      date.setDate(date.getDate() + 1);
    }
    return days;
  };

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === MONTH.length - 1 ? 0 : activeIndex + 1;
    let date = new Date(new Date(`${moment(changeDatePicker).add(1, 'M').format(DATE_MONTH_YEAR)}`).getFullYear(), nextIndex);
    setChangeDatePicker(date);
    getRequestCalendar({ date: `${moment(date).format(DATE_MONTH_YEAR)}` });
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? MONTH.length - 1 : activeIndex - 1;
    let date = new Date(new Date(`${moment(changeDatePicker).subtract(1, 'M').format(DATE_MONTH_YEAR)}`).getFullYear(), nextIndex);
    setActiveIndex(nextIndex);
    setChangeDatePicker(date);
    getRequestCalendar({ date: `${moment(date).format(DATE_MONTH_YEAR)}` });
  };

  useEffect(() => {
    const year = new Date(changeDatePicker).getFullYear();
    let date = getDaysInMonth(activeIndex, year).map((ele) => {
      return {
        date: ele?.date,
        type: ele?.type,
        remarks: '',
      };
    });
    setListDate(date);
  }, [activeIndex]);

  const slides = MONTH.map((item, i) => {
    return (
      <CarouselItem onExiting={() => setAnimating(true)} onExited={() => setAnimating(false)} key={i}>
        <div className="item-active">{item.month}月カレンダー</div>
      </CarouselItem>
    );
  });

  const ShowTable = ({ item, index }) => {
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggle = () => setTooltipOpen(!tooltipOpen);
    const [tooltipOpenAll, setTooltipOpenAll] = useState(false);
    const toggleAll = () => setTooltipOpenAll(!tooltipOpenAll);
    return (
      <tr key={index} >
        <td>{index + 1}</td>
        <td className="fz-14 truncate" title={item?.partnerName}>{item?.partnerName}</td>
        <td id={'Tooltip-partner-' + index}>
          <a className="fz-14 truncate" href={`working-schedule/staff-request?staffId=${item?.staffId}&startDate=${moment(changeDatePicker).format(DATE_MONTH_YEAR)}`}>{(item?.staffName).slice(0, 13)}</a>
          <Tooltip placement="right" isOpen={tooltipOpen} target={'Tooltip-partner-' + index} toggle={toggle}>
            {item?.staffName}
          </Tooltip>
        </td>
        <td className="p-0" id={'Tooltip-partner-s' + index}>
          <button className={`${(!!item?.allRequest?.find(e => ('type' in e) && !(e?.reject || e?.approval))) ? "form--btCheck" : "form--buttonS"} `}>{item?.isSettingWorkingSchedule ? `未作成` : `${(!!item?.allRequest?.find(e =>  ('type' in e) && !(e?.reject || e?.approval))) ? '承認依頼' : '作成済'}`}</button>
        </td>
        {item?.allRequest.length > 0 && (
          <Tooltip placement="right" isOpen={tooltipOpenAll} target={'Tooltip-partner-s' + index} toggle={toggleAll}>
            <table className="form--tooltip" >
              <thead>
                <th className="form--boder">
                </th>
                <th className="form--boder">
                  変更前
                </th>
                <th className="form--boder">
                  変更後
                </th>
              </thead>
              <tbody>
                {item?.allRequest?.map((item) => (
                  <tr>
                    <td className="form--boder">{item?.date}</td>
                    <td className="form--boder">{item?.type === 'dateOff' ? '出勤' : item?.type === 'workDate' ? '休み' : item?.type === 'shortDateOff' ? `${item?.workingStartTime} ~ ${item?.workingEndTime}` : ''}</td>
                    <td className="form--boder">{item?.type === 'dateOff' ? '休み' : item?.type === 'workDate' ? '出勤' : item?.type === 'shortDateOff' ? `${item?.startTime} ~ ${item?.endTime}` : ''}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Tooltip>
        )}
      </tr>
    )
  }

  const checkDateRequest = (typesWorkingSchedule , itemRequest, date, type) => {
    if (_.isEmpty(typesWorkingSchedule)) {
      return {
        color: 'white',
        text: '--',
      };
    }
    let tml = itemRequest?.find((ele) => ele?.date === date);
      let elmDate = typesWorkingSchedule?.find((e) => e.date === date);
      if (!elmDate) {
        elmDate = {date : date , type : type};
      }
      if (+elmDate.type === 1) {
        if (!tml) {
          return {
            color: 'white',
            text: '〇',
          };
        } else if (tml && !tml.type) {
          return {
            color: 'white',
            text: '〇',
          };
        } else if (tml && tml.startTime && (tml.type === 'shortDateOff' || tml.type === 'workDate')) {
          return {
            color: 'orange',
            text: '〇',
            data: tml,
          };
        } else if (tml && tml.type === 'dateOff') {
          return {
            color: 'white',
            text: '--',
            data: tml,
          };
        }
      } else {
        if (tml && tml.startTime && (tml.type === 'shortDateOff' || tml.type === 'workDate'))
          return {
            color: 'orange',
            text: '〇',
            data: tml,
          };
        else {
          return {
            color: 'white',
            text: '--',
          };
        }
      }
  };

  const getCoultDate = (item) => {
    let color = 'white';
    let totalcount = 0;
    let count = listResult?.map((ele) => {
      if (_.isEmpty(ele?.typesWorkingSchedule)) {
        return;
      }
      let elmDate = ele?.typesWorkingSchedule?.find((e) => e.date === item.date);
      if (!elmDate) {
        elmDate = item;
      }
      if (+elmDate.type === 1) {
        const dateOff = ele.allRequest.find((e) => e.date === item.date && e.type === 'dateOff');
        const shortDateOff = ele.allRequest.find((e) => e.date === item.date && e.type === 'shortDateOff');
        if(dateOff || shortDateOff) {
          color = 'orange';
        }
        if (!dateOff || !ele.allRequest ) {
          totalcount += 1;
        }
      } else {
        const workDate = ele.allRequest.find(
          (e) => e.date === item.date && (e.type === 'workDate' || e.type === 'shortDateOff')
        );
        if (workDate) {
          totalcount += 1;
          color = 'orange';
        }
      }
    });
    return {
      totalcount ,
      color
    };
  };

  const handleChange = (date) => {
    setActiveIndex(date === null ? '' : new Date(date).getMonth())
    let nextIndex = date === null ? '' : new Date(date).getMonth()
    let dateCall = new Date(new Date(date).getFullYear(), nextIndex);
    setChangeDatePicker(dateCall)
    getRequestCalendar({ date: `${moment(dateCall).format(DATE_MONTH_YEAR)}` });
  }


  const handleChangeSearch = (e) => {
    setDataSearch(e?.target?.value)
  }

  const submitSearch = () => {
    if (dataSearch !== '') {
      let data =
        listSearch &&
        listSearch.filter((ele) => {
          return ele?.staffName.toLowerCase().indexOf(dataSearch.toLowerCase()) !== -1;
        });
      _.isEmpty(data) && _.isEmpty(dataSearch) ? setListResult(listSearch) : setListResult(data)
    } else {
      setListResult(listSearch)
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      submitSearch();
    }
  }

  return (
    <div>
      <Loading loading={loading} />
      <div className="form__flex form--calendar form__flex--mgt-10 form--mgl10" >
        <div className="form-search form__flex--start">
          <DatePicker
            selected={_.isEmpty(activeIndex) ? new Date(new Date(changeDatePicker).getFullYear(), activeIndex) : ''}
            locale="ja"
            dateFormat="yyyy/MM"
            dateFormatCalendar="yyyy年 MM月"
            showMonthYearPicker
            className="form-control label__schedule--border table__calendar--sl"
            onChange={(date) => handleChange(date)}
          />

          <input type="text" className="form-control label__schedule--border mx-2 form--w20" value={dataSearch} onChange={handleChangeSearch} onKeyPress={(e) => handleKeyPress(e)} />
          <button className="ml-1 button-submit-calendar form--w15" onClick={() => submitSearch()}>検索</button>
        </div>
      </div>

      <div className="form__flex form__flex--mgt-10 form--mgl10 form--w85">
        <Carousel activeIndex={activeIndex} next={next} previous={previous} interval={false} className="form--slide">
          {slides}
          <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} className="form__icon-slide" />
          <CarouselControl direction="next" directionText="Next" onClickHandler={next} className="form__icon-slide" />
        </Carousel>
      </div>

      <div >
        <div className="table__calendar w-100 d-flex form--overflow">
          <div className="form--w65 form--position form--z2" >
            <table className="table__calendar--tb table bg-white from--PoTb">
              <thead>
                <tr>
                  <th className="form--w5 form--sticky0">No</th>
                  <th className="w-45 form--sticky0">作業会社</th>
                  <th className="w-25 form--sticky0"> 日付</th>
                  <th className="w-15 form--sticky0">状態</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="form--sticky41">_</td>
                  <td className="form--sticky41"></td>
                  <td className="form--sticky41">曜日</td>
                  <td className="form--sticky41"></td>
                </tr>
                <tr>
                  <td className="form--sticky82">_</td>
                  <td className="form--sticky82"></td>
                  <td className="form--sticky82">稼働人数</td>
                  <td className="form--sticky82"></td>
                </tr>
                {listResult &&
                  listResult?.map((item, index) => (
                    <ShowTable item={item} index={index} />
                  )
                  )}
              </tbody>
            </table>
          </div>
          <div className="w-50 form--z1">
            <table className="table__calendar--tb table form--PoTb" >
              <thead>
                <tr>
                  {listDate?.map((item) => {
                    const date = new Date(item?.date).getDate();
                    return (
                      <th
                        style={{
                          color: `${[2, 3, '2', '3'].includes(item?.type) ? 'red' : 'black'}`,
                        }}
                        className="form--sticky0"
                      >
                        {date < 10 ? `0${date}`: date}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                <tr>
                  {listDate?.map((item) => (
                    <td
                      style={{
                        color: `${[2, 3, '2', '3'].includes(item?.type) ? 'red' : 'black'}`,
                      }}
                      className="form--sticky41"
                    >
                      {getDate(item?.date)}
                    </td>
                  ))}
                </tr>
                <tr>
                  {listDate?.map((item) => (
                    <td
                      style={{
                        color: `${[2, 3, '2', '3'].includes(item?.type) ? 'red' : 'black'}`,
                        background: `${getCoultDate(item).color}`
                      }}
                      className="form--sticky82"
                    >
                      {getCoultDate(item).totalcount}
                    </td>
                  ))}
                </tr>
                {listResult?.map((item, index) => {
                  return (
                    <tr>
                      {listDate?.map((ele) => {
                        let element = checkDateRequest(item.typesWorkingSchedule,item.allRequest, ele?.date, ele?.type)?.data
                        return (
                          <td
                            style={{
                              color: `${[2, 3, '2', '3'].includes(ele?.type) ? 'red' : 'black'}`,
                              backgroundColor: `${checkDateRequest(item.typesWorkingSchedule,item.allRequest, ele?.date, ele?.type).color}`
                            }}
                          >
                            <div className={`${item?.allRequest?.map(item => item?.date)?.includes(ele?.date) ? "tooltipSS" : ''}`}>
                              {checkDateRequest(item.typesWorkingSchedule,item.allRequest, ele?.date, ele?.type).text}
                              {!_.isEmpty(element) && (<div className="tooltiptextSS">
                                <table className="form--tbTool w-100">
                                  <thead>
                                    <th className="form--boder form--pd0 form--clW">
                                    </th>
                                    <th className="form--boder form--pd0 form--clW">
                                      変更前
                                    </th>
                                    <th className="form--boder form--pd0 form--clW">
                                      変更後
                                    </th>
                                  </thead>
                                  <tbody>
                                    <td className="form--boder form--pd0 form--clW">{element?.date}</td>
                                    <td className="form--boder form--pd0 form--clW">{element?.type === 'dateOff' ? '出勤' : element?.type === 'workDate' ? '休み' : element?.type === 'shortDateOff' ? `${element?.workingStartTime} ~ ${element?.workingEndTime}` : ''}</td>
                                    <td className="form--boder form--pd0 form--clW">{element?.type === 'dateOff' ? '休み' : element?.type === 'workDate' ? '出勤' : element?.type === 'shortDateOff' ? `${element?.startTime} ~ ${element?.endTime}` : ''}</td>
                                  </tbody>
                                </table>
                              </div>)}
                            </div>
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScheduledOperation;
